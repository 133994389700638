
import { MgtPeoplePicker } from '@microsoft/mgt';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InputElementMgt extends Vue {
    @Prop({ required: true, default: '' }) inputId!: string;
    @Prop({ required: false, default: false }) disabled!: boolean;
    @Prop({ required: false }) label!: string;
    @Prop({ default: 'single' }) selectionMode!: string;
    @Prop({}) stateContext!: any;
    @Prop({ default: null }) defaultIds!: string | null;
    @Prop({ default: null }) groupId!: string | null;

    private moveLabel = false;

    mounted(): void {
        if (this.defaultIds && this.defaultIds.length > 0) {
            this.moveLabel = true;
        }
    }

    selectionChanged(ev: CustomEvent): void {
        if (ev.detail && ev.detail.length > 0) {
            this.moveLabel = true;
        } else {
            this.moveLabel = false;
        }
        this.$emit('selectionChanged', ev);
    }

    onInput = (value: string): void => {
        this.$emit('input', value);
    };

    onFocus(ev: FocusEvent): void {
        this.moveLabel = true;
    }

    onBlur(ev: FocusEvent): void {
        if (this.$refs.element) {
            const ppls = (this.$refs.element as MgtPeoplePicker).selectedPeople;
            if (ppls && ppls.length === 0) {
                this.moveLabel = false;
            }
        }
    }

    getValidationState(p: { dirty: boolean; validated: boolean; valid: boolean | null }): boolean | null {
        if (p == null) return null;
        return p.dirty || p.validated ? p.valid : null;
    }
}
