
import { IWorkflowSummary } from '@/entity/rh/workflow';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UserOverTimeToValidate extends Vue {
    @Prop({ required: true }) workflowOverTimeListToValidate!: IWorkflowSummary[];
    @Prop({ required: true }) isCurrentUserInformations!: boolean;

    isVisible: boolean = false;

    private overTimeToValidatefields: Array<IBootstrapTableColumn> = [
        {
            label: 'Employée',
            key: 'employeeId'
        },
        {
            label: 'Date de début',
            key: 'betweenMin',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '-'),            
        },
        {
            label: 'Date de fin',
            key: 'betweenMax',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '-'),            
        },
        {
            label: 'Total',
            key: 'total',
            formatter: (val: string, key?: string, item?) => val + ' heures'
        },
        {
            label: 'statut',
            key: 'status'
        }
    ];

    private openOverTime(item: IWorkflowSummary[], _index: number, _event: Event): void {
        this.$router.push({
            name: 'over-time-information-validation',
            params: { id: item[0].employeeId, overTimeId: item[0].id + '' }
        });
    }

    private transformToStatus(val: number): string {
        switch (val) {
            case -2:
                return 'Annulé';
            case -1:
                return 'Refusé';
            case 0:
                return 'En cours';
            case 1:
                return 'Soumis';
            case 2:
                return 'Validé';
            default:
                return 'inconnu';
        }
    }

    private getFormattedDate(date: Date) {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
}
