var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-row',{attrs:{"id":"reporting-page"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticStyle:{"margin-top":"15px","margin-bottom":"15px"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"options":_vm.reportlabelOrdered,"placeholder":_vm.reportsTypePlaceholder},on:{"input":_vm.updateDatesBasedOnContract},model:{value:(_vm.selectedReport),callback:function ($$v) {_vm.selectedReport=$$v},expression:"selectedReport"}})],1),_c('b-col',{attrs:{"cols":"4"}},[(_vm.isDataSurfer)?_c('b-form-checkbox',{model:{value:(_vm.isPdf),callback:function ($$v) {_vm.isPdf=$$v},expression:"isPdf"}},[_vm._v("Generation en PDF")]):_vm._e()],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(!_vm.promiseSave)?_c('b-button',{attrs:{"disabled":!_vm.formIsValid},on:{"click":function($event){(!_vm.isPdf || _vm.isPdf === false) && _vm.selectedReport.action
                            ? _vm.selectedReport.action()
                            : _vm.selectedReport.actionPdf()}}},[_vm._v("Créer un rapport "),_c('b-icon',{attrs:{"icon":"pencil-square","aria-hidden":"true"}})],1):_c('b-button',{attrs:{"variant":"all","disabled":""}},[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Génération en cours ")],1)],1)],1),(_vm.selectedReport !== null && _vm.selectedReport !== undefined)?_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[(_vm.displayPickerDate)?_c('b-form-datepicker',{attrs:{"value-as-date":true,"max":_vm.closingDate,"id":"opening-date-data","placeholder":"Date d'ouverture","trim":""},model:{value:(_vm.openingDate),callback:function ($$v) {_vm.openingDate=$$v},expression:"openingDate"}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"4"}},[(_vm.displayPickerDate)?_c('b-form-datepicker',{attrs:{"value-as-date":true,"min":_vm.openingDate,"id":"closing-date-data","placeholder":"Date de fermeture","trim":""},model:{value:(_vm.closingDate),callback:function ($$v) {_vm.closingDate=$$v},expression:"closingDate"}}):_vm._e()],1),(
                    _vm.selectedReport != null &&
                    _vm.selectedReport.id != 7 &&
                    _vm.selectedReport.id != 13 &&
                    _vm.selectedReport.id != 14 &&
                    _vm.selectedReport.id != 16

                )?_c('b-col',{attrs:{"cols":"4"}},[(_vm.displayAgencySelect)?_c('v-select',{attrs:{"options":_vm.agencyOptions,"placeholder":_vm.agencyPlaceholder},model:{value:(_vm.agency),callback:function ($$v) {_vm.agency=$$v},expression:"agency"}}):_vm._e()],1):_vm._e()],1):_vm._e(),_c('b-row',[(_vm.selectedReport != null && _vm.selectedReport.id == 7)?_c('b-col',{attrs:{"cols":"4"}},[_c('v-select',{staticStyle:{"margin-top":"20px"},attrs:{"options":_vm.ProjectOptions,"reduce":(ProjectOptions) => ProjectOptions.id,"placeholder":_vm.trigramPlaceholder,"multiple":""},model:{value:(_vm.projectsIds),callback:function ($$v) {_vm.projectsIds=$$v},expression:"projectsIds"}})],1):_vm._e(),(_vm.selectedReport != null && _vm.selectedReport.id == 5)?_c('b-col',{attrs:{"cols":"4"}},[_c('v-select',{staticStyle:{"margin-top":"20px"},attrs:{"options":_vm.studioOptions,"reduce":(studios) => studios.id,"placeholder":_vm.studioPlaceholder,"multiple":""},model:{value:(_vm.studioIds),callback:function ($$v) {_vm.studioIds=$$v},expression:"studioIds"}})],1):_vm._e(),(_vm.selectedReport != null && _vm.selectedReport.id == 7)?_c('b-col',{attrs:{"cols":"4"}},[_c('v-select',{staticStyle:{"margin-top":"20px"},attrs:{"options":_vm.agencyOptionsCA,"placeholder":_vm.agencyPlaceholderCA,"reduce":(agencyOptionsCA) => agencyOptionsCA.id,"multiple":""},model:{value:(_vm.agenciesIds),callback:function ($$v) {_vm.agenciesIds=$$v},expression:"agenciesIds"}})],1):_vm._e()],1),_c('b-row')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }