import { render, staticRenderFns } from "./comment-form.vue?vue&type=template&id=094a36e8&"
import script from "./comment-form.vue?vue&type=script&lang=ts&"
export * from "./comment-form.vue?vue&type=script&lang=ts&"
import style0 from "./comment-form.vue?vue&type=style&index=0&id=094a36e8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports