import { IManagementPhase, IRegroupingContractLine } from '@/entity/management/management-phase';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';

class ManagementApi extends ApiBase<IManagementPhase> {
    /**
     *
     */
    constructor(urlBase: string = 'api/managements') {
        super(urlBase);
    }

    public async getAllPhasesByProjectId(projectId: number): Promise<ICancellableResult<IManagementPhase[]>> {
        return await ajaxCall.baseAjaxCall<IManagementPhase[]>('get', this.url, {
            urlParameter: ['managementphases'],
            queryParameter: { projectId: projectId + '' }
        });
    }

    public async regroupingcontractlines(projectId: number): Promise<ICancellableResult<IManagementPhase[]>> {
        return await ajaxCall.baseAjaxCall<IManagementPhase[]>('get', this.url, {
            urlParameter: ['regroupingcontractlines'],
            queryParameter: { projectId: projectId + '' }
        });
    }

    public async patchPhases(data: IManagementPhase[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IManagementPhase[]>('patch', this.url, {
            urlParameter: ['patchphases'],
            payload: data
        });
    }

    public async getAllRegroupingContractLinesByProjectId(
        projectId: number
    ): Promise<ICancellableResult<IRegroupingContractLine[]>> {
        return await ajaxCall.baseAjaxCall<IRegroupingContractLine[]>('get', this.url, {
            urlParameter: ['regroupingcontractlines'],
            queryParameter: { projectId: projectId + '' }
        });
    }

    public async patchRegroupingContractLines(
        projectId: number,
        data: IRegroupingContractLine[]
    ): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IRegroupingContractLine[]>('patch', this.url, {
            urlParameter: ['patchregroupingcontractlines', projectId + ''],
            payload: data
        });
    }

    public async exportManagementData(data: number): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, number>('post', this.url, {
            payload: data,
            urlParameter: ['exportManagementData'],
            responseType: 'blob'
        });
    }
}

export const managementApi: ManagementApi = new ManagementApi();
