
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { vxm } from '@/store';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { IFee, IFeeExtention } from '@/entity/fee/fee';
import { planificationApi } from '@/wapi/planification-api';
import { ICancellableResult } from '@t/ajax-wrapper';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';

@Component({
    components: {
        InputElement
    }
})
export default class FeeList extends Vue {
    @Prop({ required: true }) projectId!: string;

    private isBusy: boolean = true;
    private promiseExport: boolean = false;
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private filteredFees: IFeeExtention[] = [];
    private feesList: IFee[] = [];
    private filterData: string = '';
    private feesCurrentPage: number = 1;
    private feesPerPage: number = 10;

    private baseFields: Array<IBootstrapTableColumn | string> = [
        {
            label: 'Date',
            key: 'creationDate',
            sortable: true,
            thStyle: 'width:250px'
        },
        { label: 'Désignation/Mission', key: 'contractLineId', thStyle: 'width:230px' },
        { label: 'Libellé', key: 'label', sortable: true, thStyle: 'width:230px' },
        {
            label: 'Distance',
            key: 'distance',
            sortable: true,
            thStyle: 'width:170px',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', {
                    style: 'unit',
                    unit: 'kilometer'
                }).format(val)
        },
        // {
        //     label: 'Frais associés (HT)',
        //     key: 'fees',
        //     sortable: true,
        //     thStyle: 'width:150px',
        //     formatter: (val: number, key?: string, item?) =>
        //         new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)
        // },
        {
            label: 'TVA',
            key: 'tax',
            thStyle: 'width:120px',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(val / 100)
        },
        {
            label: 'Total TTC',
            key: 'fees',
            sortable: true,
            thStyle: 'width:100px',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)
            // return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(element.fees);
            // formatter: this.defaultTableTotalNumberFormatter
        }
    ];

    private onFiltered(filteredItems: IFeeExtention[]) {
        this.filteredFees = filteredItems;
    }

    get fees(): IFee[] | undefined {
        this.feesList = vxm.project.feesList?.filter(x => x.projectId === Number(this.projectId)) as IFee[];
        this.filteredFees = this.feesList as IFeeExtention[];
        return this.feesList;
    }

    get totalFees(): number {
        if (this.filteredFees && this.filteredFees.length > 0) {
            return this.filteredFees.length;
        }
        return 0;
    }

    get displayedFeesPage(): Array<IFeeExtention> {
        if (this.filteredFees != null) {
            return this.filteredFees?.slice((this.feesCurrentPage - 1) * 10, this.feesCurrentPage * 10);
        }
        return [];
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        return this.baseFields;
    }

    get distanceTotal(): number {
        let totalKm: number = 0;
        if (this.fees) {
            this.displayedFeesPage.forEach(line => {
                if (line.distance) {
                    totalKm += line.distance;
                }
            });
        }
        return totalKm;
    }

    get distanceTotalDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'unit', unit: 'kilometer' }).format(this.distanceTotal);
    }

    get feeAmount(): number {
        let totalFee: number = 0;
        if (this.fees) {
            this.displayedFeesPage.forEach(line => {
                if (line.fees) {
                    totalFee += line.fees * (1 - (line.tax as number) / 100);
                }
            });
        }
        return totalFee;
    }

    get feeAmountDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.feeAmount);
    }

    get taxAmount(): number {
        let totalTax: number = 0;
        if (this.fees) {
            this.displayedFeesPage.forEach(line => {
                if (line.tax) {
                    totalTax += line.fees * (line.tax / 100);
                }
            });
        }
        return totalTax;
    }

    get taxAmountDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.taxAmount);
    }

    get totalAmount(): number {
        let total: number = 0;
        if (this.fees) {
            this.displayedFeesPage.forEach(line => {
                if (this.feeAmount && this.taxAmount) {
                    total += line.fees;
                }
            });
        }
        return total;
    }

    get totalAmountDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.totalAmount);
    }

    private defaultTableTotalNumberFormatter(value: number, key: string | undefined, element: IFee) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(element.fees);
    }

    private defaultTableNumberFormatter(value: number, key: string | undefined, element: IFee) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
            element.fees * (1 - (element.tax as number) / 100)
        );
    }

    async setPhaseOptions(fee: IFee): Promise<void> {
        const call = await planificationApi.getAllPhasesByProjectId(fee.projectId ?? 0);
        fee.phaseOption = call.datas?.map((x, index) => {
            const phase: ISelectListOption = {
                id: x.contractLine.id,
                code: 'CO' + index,
                label: x.contractLine.designation ?? ''
            };
            return phase;
        });
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }
    
    async exportListFee(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const projId = this.feesList[0].projectId;
        const listFees =
            this.filteredFees && this.filteredFees.length !== this.feesList.length
                ? this.filteredFees.map((x) => x.id)
                : this.feesList.map((x) => x.id);
        await this.generateListFee(
            planificationApi.exportFeeData(listFees as any, projId as number, sortKeySelected as string, sortKeyDescSelected as any),
            `Liste_${this.formatDate(new Date())}.xlsx`
        );
    }

    private async generateListFee(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private async mounted(): Promise<void> {
        await vxm.project.fetchFees(this.projectId);
        this.feesList.forEach(async (elements, index) => {
            await this.setPhaseOptions(elements);
            this.feesList.splice(index, 1, elements);
        });
        this.isBusy = false;
    }
}
