
import { Component, Vue, Watch } from 'vue-property-decorator';

import { provisionalFeeApi } from '@/wapi/provisional-fee-api';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { IProject } from '@/entity/project/project';
import { NU } from '@t/type';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { vxm } from '@/store';
import { IProvisionalFee } from '@/entity/provisional-fee/provisional-fee';
import { IContractLine } from '@/entity/contract/contract-line';
import { contractApi } from '@/wapi/contract-api';
import CurrencyInput from '@c/shared/currency-input.vue';

@Component({
    components: {
        CurrencyInput
    }
})
export default class ProvisionalFeeingList extends Vue {
    provisionalFees: NU<IProvisionalFee[]> = [];
    contractLinesList: NU<ISelectListOption[]> = [];
    private isBusy: boolean = true;
    private promiseExport: boolean = false;

    get selectedProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    get formatedOpeningDate(): string {
        if (this.selectedProject?.openingDate) {
            return (
                format(new Date(String(this.selectedProject?.openingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    get formatedClosingDate(): string {
        if (this.selectedProject?.closingDate) {
            return (
                format(new Date(String(this.selectedProject?.closingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    get isValidForm(): boolean {
        let res = true;
        this.provisionalFees?.forEach((element) => {
            res = res && element.contractLineId !== undefined && element.contractLineId !== 0;
            res = res && element.description !== undefined && element.description !== '';
        });
        return res;
    }

    get sumAmountTaxFree(): number {
        let res: number = 0;
        this.provisionalFees?.forEach((element) => {
            res += element.amountTaxFree ?? 0;
        });
        return res;
    }

    get isReadonly(): boolean {
        return (vxm.user.isAssociate || vxm.user.isDaf) && !vxm.user.isAdmin && !vxm.user.isCP && !vxm.user.isStudioManager && !vxm.user.isRH
    }

    @Watch('selectedProjectId')
    async assigneProject(newVal: number, _oldVal: number): Promise<void> {
        if (newVal && newVal > 0) {
            await this.getProvisionalFees(newVal);
            const contractLineCallData = await contractApi.getAllContractLinesByProjectId(newVal + '');
            if (isCallValidAndNotCancelled<IContractLine[] | null>(contractLineCallData)) {
                if (contractLineCallData?.datas) {
                    this.contractLinesList = contractLineCallData.datas.map((x, index) => {
                        const contractLine: ISelectListOption = {
                            id: x.id,
                            code: 'CO' + index,
                            label: x.designation ?? ''
                        };
                        return contractLine;
                    });
                }
            }
        }
    }

    private updateTotal(fee: IProvisionalFee): void {
        fee.quantity = Number(fee.quantity);
        fee.amountTaxFree = fee.quantity * fee.unitPrice;
    }

    async getProvisionalFees(projectId: number): Promise<void> {
        const provisionalFeeCallData = await provisionalFeeApi.getAllProvisionalFeesByProjectId(projectId);
        if (isCallValidAndNotCancelled<IProvisionalFee[]>(provisionalFeeCallData)) {
            if (provisionalFeeCallData?.datas) {
                this.provisionalFees = provisionalFeeCallData.datas;
            }
        }
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Mission', key: 'contractLineId', thStyle: 'min-width:300px' },
        { label: 'Description', key: 'description' },
        { label: 'Quantité', key: 'quantity', thStyle: 'width:70px' },
        { label: 'PU (€)', key: 'unitPrice', thStyle: 'width:100px', formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Montant HT (€)', key: 'amountTaxFree', thStyle: 'width:150px', formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Commentaire', key: 'comment', thStyle: 'min-width:300px' },
        { label: '', key: 'delete', thStyle: 'width:80px' }
    ];

    async saveProvisionalFees(): Promise<void> {
        const callData = await provisionalFeeApi.patchProvisionalFees(
            this.provisionalFees?.map((x) => {
                x.quantity = Number(x.quantity);
                x.amountTaxFree = x.quantity * x.unitPrice;
                return x;
            }) ?? [],
            this.selectedProjectId + ''
        );
        if (isCallValidAndNotCancelled(callData)) {
            this.getProvisionalFees(this.selectedProject?.id ?? 0);
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Frais prévisionnels',
                variant: 'success',
                solid: true
            });
        }
    }

    addProvisionalFee(): void {
        const provisionalFee = {} as IProvisionalFee;
        provisionalFee.projectId = this.selectedProject?.id ?? 0;
        provisionalFee.quantity = 1;
        provisionalFee.unitPrice = 0;
        provisionalFee.amountTaxFree = 0;
        this.provisionalFees?.push(provisionalFee);
    }

    deleteProvisionalFeeLine(item: IProvisionalFee): void {
        const index = this.provisionalFees?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.provisionalFees?.splice(index, 1);
        }
    }

    private async mounted() {
        vxm.app.changeTitleMain('Frais prévisionnels');
        if (this.selectedProjectId && this.selectedProjectId > 0) {
            await this.getProvisionalFees(this.selectedProjectId);
            const contractLineCallData = await contractApi.getAllContractLinesByProjectId(this.selectedProjectId + '');
            if (isCallValidAndNotCancelled<IContractLine[] | null>(contractLineCallData)) {
                if (contractLineCallData?.datas) {
                    this.contractLinesList = contractLineCallData.datas.map((x, index) => {
                        const contractLine: ISelectListOption = {
                            id: x.id,
                            code: 'CO' + index,
                            label: x.designation ?? ''
                        };
                        return contractLine;
                    });
                }
            }
        }
        this.isBusy = false;

        vxm.user.checkIsAssociate();
        vxm.user.checkIsAdmin();
        vxm.user.checkIsDaf();
        vxm.user.checkIsStudioManager();
        vxm.user.checkIsRH();
        vxm.user.checkIsCP();

    }

    async exportListProvisionalFees(): Promise<void> {
        await this.generateReport(
            provisionalFeeApi.exportProvisionalFeesData({ data: this.selectedProjectId } as any),
            `Frais_prévisonnels_${this.selectedProject?.designation}_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = reportName;
            link.click();
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }
}
