
import { Component, Watch } from 'vue-property-decorator';
import ImputationList from './imputation-list.vue';
import ImputationCalendarSummary from './imputation-calendar-summary.vue';
import ImputationContractLineItem from './imputation-contract-line-item.vue';
import MonthPicker from '@/components/shared/month-picker.vue';
import { authModule } from '@t/session';
import { moduleApiGraph } from '@t/module-api-graph';
import RHBaseClass from '../rh-class-base.vue';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { contractLineApi, imputationApi, leavesCurrentMonthApi } from '@/wapi/imputation-api';
import { IImputationWorkflow, IImputationWorkflowItem } from '@/entity/rh/workflow';
import { IReferential } from '@/entity/shared/referential';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IProject } from '@/entity/project/project';
import { ILeaveEmployee } from '@/entity/leave/leaveEmployee';
import { IContractLine, IContractLineWithProjectInfos } from '@/entity/contract/contract-line';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import ImputationGrid from './imputation-grid.vue';
import { ITeleworkingSelection } from './imputation-interfaces';

@Component({
    components: {
        ImputationList,
        ImputationCalendarSummary,
        MonthPicker,
        ImputationContractLineItem,
        ImputationGrid
    }
})
export default class UserImputation extends RHBaseClass {
    private selectedMonth: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    private employeeIdentifier!: string;
    private currentUserData: NU<any> = null;
    private daysOfSelectedMonth: string[] = [];
    private currentWorkflow: NU<IImputationWorkflow> = null;
    private loading = true;
    private request: NU<Promise<any>> = null;
    private loadingSave: boolean = false;
    private filterData: string = '';

    private projects: IProject[] = [];
    private projectsIterration: Array<IProject[]> = [];
    private leavesCurrentMonth: ILeaveEmployee[] = [];
    private selectedProjectId: NU<number> = null;
    private selectedContractLineId: NU<number> = null;
    private contractLineInWorkflow: NU<IContractLineWithProjectInfos[]> = [];
    private linesSubmitable: boolean[] = [];
    private daySums: number[] = [];
    private errors: boolean[] = [];
    private showCalendar: boolean = false;

    private updatedTeleworking: { [key: string]: { [key: string]: { morning: boolean; afternoon: boolean } } } = {};

    get currentUpdatedTeleworking() {
        return this.updatedTeleworking[this.getDateForSave(this.selectedMonth)];
    }

    async changeMonth(newDate: Date): Promise<void> {
        if (this.request !== null) {
            await this.request;
            this.request = null;
        }
        this.selectedMonth = newDate;
    }

    async created(): Promise<void> {
        this.employeeIdentifier = await authModule.getAccount()!.localAccountId!;
        this.currentUserData = await moduleApiGraph.Client.api(`/users/${this.id}`).get();
        vxm.app.changeTitleMain(this.employeeIdentifier === this.id ? 'Vos Imputations' : 'Imputations');

        vxm.app.changeTitleExt(
            this.employeeIdentifier !== this.id ? `${this.currentUserData ? this.currentUserData.displayName : ''}` : ''
        );

        await vxm.referential.fetchDayPartTypes();
        this.loading = true
        var o = await imputationApi.getTeleworkingList(this.id);
        if (isCallValidAndNotCancelled(o)) {
            this.loading = false
            this.initTeleworking(o.datas!);
        }

        this.daysOfSelectedMonth = this.setDaysOfSelectedMonth();
        this.setCurrentDaysInMonth(this.selectedMonth);
        await this.fetchInfosCurrentMonth();
    }

    updateCalendarValue(): boolean {
        this.showCalendar = !this.showCalendar;
        return this.showCalendar;
    }

    setDaysOfSelectedMonth(): string[] {
        const date = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1);
        const result: string[] = [];
        while (date.getMonth() === this.selectedMonth.getMonth()) {
            result.push(date.getDate() + '');
            date.setDate(date.getDate() + 1);
        }
        return result;
    }

    private getDateForSave(date: Date) {
        return ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
    }

    private hasUpdated: Array<{ key: string; value: boolean }> = [];

    private updatedTeleworkingDay(parameters: { month: Date; day: number; part: number; added: boolean }) {
        const data = `${('0' + parameters.month.getMonth()).slice(-2)}-${parameters.month.getFullYear()}-${
            parameters.day
        }-${parameters.part}`;
        const o = this.hasUpdated.findIndex((x) => x.key === data);
        if (o > -1) {
            if (this.hasUpdated[o].value !== parameters.added) {
                this.hasUpdated.splice(o, 1);
            }
        } else {
            this.hasUpdated.push({ key: data, value: parameters.added });
        }
    }

    private initTeleworking(datas: Array<ITeleworkingSelection>) {
        const upd = {};
        const morPartDay = this.dayPartTypes.find((x) => x.code === 'MOR')!;
        const aftPartDay = this.dayPartTypes.find((x) => x.code === 'AFT')!;
        datas.forEach((x) => {
            const processingDate = new Date(x.date);
            const dateval = this.getDateForSave(processingDate);
            if (upd[dateval] == null) {
                upd[dateval] = {};
                upd[dateval][processingDate.getDate()] = {
                    morning: x.dayPartTypeId == morPartDay.id ? true : false,
                    afternoon: x.dayPartTypeId == aftPartDay.id ? true : false
                };
            } else {
                if (upd[dateval][processingDate.getDate()] != null) {
                    upd[dateval][processingDate.getDate()] = {
                        morning:
                            x.dayPartTypeId == morPartDay.id ? true : upd[dateval][processingDate.getDate()].morning,
                        afternoon:
                            x.dayPartTypeId == aftPartDay.id ? true : upd[dateval][processingDate.getDate()].afternoon
                    };
                } else {
                    upd[dateval][processingDate.getDate()] = {
                        morning: x.dayPartTypeId == morPartDay.id ? true : false,
                        afternoon: x.dayPartTypeId == aftPartDay.id ? true : false
                    };
                }
            }
        });

        this.updatedTeleworking = upd;
    }

    private setCurrentDaysInMonth(date: Date) {
        const dateval = this.getDateForSave(date);
        if (this.updatedTeleworking[dateval] == null) {
            this.updatedTeleworking[dateval] = {};
            this.daysOfSelectedMonth.forEach((x) => {
                if (x != '-1') {
                    this.updatedTeleworking[dateval][x] = {
                        morning: false,
                        afternoon: false
                    };
                }
            });
        } else {
            this.daysOfSelectedMonth.forEach((x) => {
                if (this.updatedTeleworking[dateval][x] == null) {
                    if (x != '-1') {
                        this.updatedTeleworking[dateval][x] = {
                            morning: false,
                            afternoon: false
                        };
                    }
                }
            });
        }
        let upd = this.updatedTeleworking;
        this.updatedTeleworking = {};
        this.$nextTick(() => {
            this.updatedTeleworking = upd;
        });
    }

    @Watch('selectedMonth')
    async updateDaysOfMonth(newVal: Date): Promise<void> {
        this.selectedProjectId = null;
        this.selectedContractLineId = null;
        this.daysOfSelectedMonth = this.setDaysOfSelectedMonth();
        this.setCurrentDaysInMonth(newVal);
        await this.fetchInfosCurrentMonth();
    }

    async fetchInfosCurrentMonth(): Promise<void> {
        await this.fetchContractLines();
        await this.fetchWorkflow();
        await this.fetchLeavesCurrentMonth();
    }

    @Watch('selectedProjectId')
    resetContractLineId(newVal: number | null): void {
        if (newVal === null) {
            return;
        }
        const contractLines = this.projects
            .find((x) => x.id === newVal)!
            .contracts!.map((x) => x.contractLines)
            .flat();
        if (contractLines.length === 1 && contractLines[0] !== undefined) {
            this.selectedContractLineId = contractLines[0].id;
        }
    }

    get projectOptions(): ISelectListOption[] {
        return this.projects.map((x) => {
            return {
                id: x.id,
                code: x.trigram,
                label: x.trigram + ' - ' + x.designation
            };
        });
    }

    get allContractsLines(): IContractLineWithProjectInfos[] {
        return this.projectsIterration
            .flat().map(project => ({ contracts: project.contracts ?? [], projectDesignation: project.designation })) // On récupère tous les contrat et la désignation de leur projet
            .flat().map(project => project?.contracts.map(contract => contract.contractLines?.map(cl => ({ ...cl, projectDesignation: project.projectDesignation })))) // On récupère toutes les lignes de contrat et leur désignation de projet
            .flat().filter(x => x)
            .flat().map(x => x!);
    }

    addNewContractLine(): void {
        const contractLine = this.allContractsLines.find(cl => cl.id === this.selectedContractLineId);

        if (!contractLine) return;

        this.contractLineInWorkflow?.push(contractLine);
        this.selectedProjectId = null;
        this.selectedContractLineId = null;
    }

    get contractLineOptions(): ISelectListOption[] {
        const result: ISelectListOption[] = [];
        if (this.selectedProjectId === null) {
            return result;
        }
        const project = this.projects.find((x) => x.id === this.selectedProjectId);
        const contractAndLineAlreadySelected = this.contractLineInWorkflow?.map(cl => cl.id) || [];
        this.allContractsLines
            .filter(cl => cl.projectDesignation === project?.designation && contractAndLineAlreadySelected.includes(cl.id) === false)
            .forEach(cl => {
                result.push({
                    id: cl.id,
                    code: cl.designation ?? 'No code',
                    label: cl.designation ?? 'No label'
                });
            });
        return result;
    }

    get fieldsDisabled(): boolean {
        return (
            this.loading ||
            this.loadingSave ||
            (this.currentWorkflow !== null && this.currentWorkflow !== undefined 
                //&& this.currentWorkflow.isSubmitted
            )
        );
    }

    get filteredContractLine(): IContractLine[] {
        const result: IContractLine[] = [];
        this.projects
            .filter((x) => x.designation.includes(this.filterData))
            .map((x) => x.contracts!.map((y) => y.contractLines!.map((z) => result.push(z))));
        return result;
    }

    async fetchContractLines(): Promise<void> {
        let year = this.selectedMonth.getFullYear();
        let month = this.selectedMonth.getMonth();
        if (month === 0) {
            year--;
            month = 12;
        }
        this.projects = [];
        this.loading = true
        const projectsData = await contractLineApi.GetProjectsWithContractLines();
        if (isCallValidAndNotCancelled(projectsData)) {
            this.loading = false
            const projects = projectsData.datas!;
            this.getCurrentLines(projects);
            this.projects = projects;
        }      
        this.projectsIterration = [];
        this.projectsIterration.push(this.projects);
    }

    async fetchLeavesCurrentMonth(): Promise<void> {
        let year = this.selectedMonth.getFullYear();
        let month = this.selectedMonth.getMonth();
        if (month === 0) {
            year--;
            month = 12;
        }
        this.loading = true
        const leavesData = await leavesCurrentMonthApi.getLeavesCurrentMonth(
            this.id + '',
            this.selectedMonth.getFullYear(),
            this.selectedMonth.getMonth() + 1
        );

        if (isCallValidAndNotCancelled(leavesData)) {
            this.leavesCurrentMonth = leavesData.datas!;
            this.loading = false
        }
    }

    getCurrentLines(projects: IProject[]) {
        const morPartDay = this.dayPartTypes.find((x) => x.code === 'MOR')!;
        const aftPartDay = this.dayPartTypes.find((x) => x.code === 'AFT')!;
        const chaPartDay = this.dayPartTypes.find((x) => x.code === 'CHA')!;
        for (let i = 0; i < projects.length; i++) {
            const curProject = projects[i];
            for (let j = 0; j < curProject.contracts!.length; j++) {
                const curContract = curProject.contracts![j];
                for (let k = 0; k < curContract.contractLines!.length; k++) {
                    const curLine = curContract.contractLines![k];
                    curLine.imputations = [];
                    for (let j = 0; j < this.daysOfSelectedMonth.length; j++) {
                        const day = Number(this.daysOfSelectedMonth[j]);
                        curLine.imputations[day - 1] = [
                            {
                                workflowId: 0,
                                dayPartTypeId: morPartDay.id,
                                dayPartType: morPartDay,
                                value: 0,
                                date: new Date(
                                    this.selectedMonth.getFullYear(),
                                    this.selectedMonth.getMonth(),
                                    day,
                                    3,
                                    0,
                                    0
                                ),
                                contractLineId: curLine.id,
                                id: 0
                            },
                            {
                                workflowId: 0,
                                dayPartTypeId: aftPartDay.id,
                                dayPartType: aftPartDay,
                                value: 0,
                                date: new Date(
                                    this.selectedMonth.getFullYear(),
                                    this.selectedMonth.getMonth(),
                                    day,
                                    3,
                                    0,
                                    0
                                ),
                                contractLineId: curLine.id,
                                id: 0
                            },
                            {
                                workflowId: 0,
                                dayPartTypeId: chaPartDay.id,
                                dayPartType: chaPartDay,
                                value: 0,
                                date: new Date(
                                    this.selectedMonth.getFullYear(),
                                    this.selectedMonth.getMonth(),
                                    day,
                                    3,
                                    0,
                                    0
                                ),
                                contractLineId: curLine.id,
                                id: 0
                            }
                        ];
                    }
                }
            }
        }
    }

    async fetchWorkflow(): Promise<void> {
        this.loading = true;
        const result = await imputationApi.getImputationsByEmployeeIdentifierAndPeriod(
            this.id + '',
            this.selectedMonth.getFullYear(),
            this.selectedMonth.getMonth() + 1
        );

        if (!isCallValidAndNotCancelled(result)) return;

        this.currentWorkflow = result.datas!;
        this.contractLineInWorkflow = [];
        this.loading = false;

        if (this.currentWorkflow.elements?.length !== 0) {
            this.currentWorkflow.elements.forEach(workflow => {
                const contractLine = this.allContractsLines.find(curContractLine => curContractLine.id === workflow.contractLineId);
                if (contractLine) {
                    const dayToUpdate = contractLine!.imputations![new Date(workflow.date).getDate() - 1];
                    const dayPartIndex = dayToUpdate.findIndex(
                        (x) => x.dayPartType.code === workflow.dayPartType.code
                    );
                    dayToUpdate[dayPartIndex].value = workflow.value;
                    if (this.contractLineInWorkflow!.find((x) => x === contractLine) === undefined) {
                        this.contractLineInWorkflow!.push(contractLine);
                    }
                }
            });
        } else {
            let year = this.selectedMonth.getFullYear();
            let month = this.selectedMonth.getMonth();
            if (month === 0) {
                year--;
                month = 12;
            }
            this.loading = true;
            const resultLastMonth = await imputationApi.getImputationsByEmployeeIdentifierAndPeriod(
                this.id + '',
                year,
                month
            );
            this.loading = false;
            if (!isCallValidAndNotCancelled(resultLastMonth)) return;

            const workflowLastMonth = resultLastMonth.datas!;
            workflowLastMonth.elements.forEach(workflow => {
                const contractLine = this.allContractsLines.find(curContractLine => curContractLine.id === workflow.contractLineId);
                if (
                    contractLine &&
                    workflow.contractLineId === contractLine.id &&
                    this.contractLineInWorkflow &&
                    this.contractLineInWorkflow.indexOf(contractLine) === -1 && 
                    this.projects.find(p => p.contracts?.some(c => c.contractLines?.some(cl => cl.id === workflow.contractLineId)))
                ) {
                    this.contractLineInWorkflow.push(contractLine);
                }
            });
        }
    }

    async fetchLastMonthWorkFlow(): Promise<IImputationWorkflow> {
        let workFlowLastMonth!: IImputationWorkflow;
        let year = this.selectedMonth.getFullYear();
        let month = this.selectedMonth.getMonth();
        if (month === 0) {
            year--;
            month = 12;
        }
        const result = await imputationApi.getImputationsByEmployeeIdentifierAndPeriod(this.id + '', year, month);
        if (isCallValidAndNotCancelled(result)) {
            workFlowLastMonth = result.datas!;
        }
        return workFlowLastMonth;
    }

    get dayPartTypes(): IReferential[] {
        return vxm.referential.dayPartTypes!;
    }

    formatTeleworking(): Array<ITeleworkingSelection> {
        let ar: Array<ITeleworkingSelection> = [];

        let morningId = vxm.referential.dayPartTypes?.find((x) => x.code == 'MOR')?.id;
        let afternoonId = vxm.referential.dayPartTypes?.find((x) => x.code == 'AFT')?.id;

        for (const [key, value] of Object.entries(this.updatedTeleworking)) {
            for (const [k, val] of Object.entries(value)) {
                if (val.morning === true && morningId != null) {
                    ar.push({
                        date: key.split('-').reverse().join('-') + '-' + ('0' + k).slice(-2) + 'T03:00:00.000Z',
                        dayPartTypeId: morningId
                    });
                }
                if (val.afternoon === true && afternoonId != null) {
                    ar.push({
                        date: key.split('-').reverse().join('-') + '-' + ('0' + k).slice(-2) + 'T03:00:00.000Z',
                        dayPartTypeId: afternoonId
                    });
                }
            }
        }

        return ar;
    }

    get imputationChanged() {
        return !(
            this.currentWorkflow == null ||
            // this.currentWorkflow.isSubmitted ||
            this.linesSubmitable.indexOf(true) !== -1 ||
            this.loadingSave ||
            this.contractLineInWorkflow === undefined ||
            this.contractLineInWorkflow === null
        );
    }

    async sendSaveRequest(submit: boolean = true): Promise<void> {
        const imputations: IImputationWorkflowItem[] = [];
        for (let contractLineIndex = 0; contractLineIndex < this.contractLineInWorkflow!.length; contractLineIndex++) {
            const contractLine = this.contractLineInWorkflow![contractLineIndex];
            for (let dayIndex = 0; dayIndex < this.daysOfSelectedMonth.length; dayIndex++) {
                const imputationDay = contractLine.imputations![dayIndex];
                for (let dayPartIndex = 0; dayPartIndex < imputationDay.length; dayPartIndex++) {
                    const dayPart = imputationDay[dayPartIndex];
                    if (
                        this.dayPartTypes!.map((x) => x.code).findIndex((x) => x === dayPart.dayPartType.code) !== -1 &&
                        dayPart.value > 0 &&
                        dayPart.value <= 3 
                        && dayPart.dayPartTypeId !== 3
                    ) {
                        dayPart.value = Number(dayPart.value);
                        dayPart.dayPartTypeId = dayPart.dayPartType.id;
                        imputations.push(dayPart);
                    }
                }
            }
        }
        if (this.imputationChanged) {
            this.loadingSave = true;

            const workflowId: number | null = await imputationApi
                .insertImputations({
                    elements: imputations as IImputationWorkflowItem[],
                    id: this.currentWorkflow!.id,
                    employee: {
                        id: this.id
                    } as any,
                    triggeredBy: {
                        id: this.employeeIdentifier
                    } as any,
                    validators: this.currentWorkflow!.validators,
                    isSubmitted: !submit
                })
                .then((response) => response.datas);
            if (workflowId !== null && workflowId !== 0) {
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: `Imputations: Mois de ${this.selectedMonth.toLocaleString('fr-FR', {
                        month: 'long',
                        year: 'numeric'
                    })}`,
                    variant: 'success',
                    solid: true
                });
                this.currentWorkflow!.isSubmitted = !submit;
                this.currentWorkflow!.id = workflowId;
            } else {
                this.$bvToast.toast("Une erreur s'est produite.", {
                    title: `Imputations: Mois de ${this.selectedMonth.toLocaleString('fr-FR', {
                        month: 'long',
                        year: 'numeric'
                    })}`,
                    variant: 'error',
                    solid: true
                });
            }
        }
        if (this.hasUpdated.length != 0) {
            this.loadingSave = true;

            if (
                isCallValidAndNotCancelled(await imputationApi.insertTeleworkingList(this.id, this.formatTeleworking()))
            ) {
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: `Imputations: Mois de ${this.selectedMonth.toLocaleString('fr-FR', {
                        month: 'long',
                        year: 'numeric'
                    })}`,
                    variant: 'success',
                    solid: true
                });
            } else {
                this.$bvToast.toast("Une erreur s'est produite.", {
                    title: `Imputations: Mois de ${this.selectedMonth.toLocaleString('fr-FR', {
                        month: 'long',
                        year: 'numeric'
                    })}`,
                    variant: 'error',
                    solid: true
                });
            }
        }
        this.loadingSave = false;
    }

    updateSubmitable(isSubmitable: boolean[]) {
        this.linesSubmitable = isSubmitable;
    }

    updateSums(sums: number[]) {
        this.daySums = sums;
    }

    updateErrors(error: boolean[]) {
        this.errors = error;
    }

    get isSubmittable(): boolean {
        return (this.imputationChanged || this.hasUpdated.length > 0) && this.errors.indexOf(true) === -1;
    }

    save(): void {
        this.sendSaveRequest();
    }
}
