
import { deadlines, IContract } from '@/entity/contract/contract';
import { IAgency } from '@/entity/project/agency';
import { IClient } from '@/entity/shared/client';
import { contractApi } from '@/wapi/contract-api';
import InputElement from '@c/shared/input-element.vue';
import CurrencyInput from '@c/shared/currency-input.vue';
import PercentageInput from '@c/shared/percentage-input.vue';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IContractLine } from '@/entity/contract/contract-line';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { IBank, IReferential } from '@/entity/shared/referential';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { ISalesAccount } from '@/entity/project/sales-account';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { referentialApi } from '@/wapi/referential-api';

@Component({
    components: {
        InputElement,
        CurrencyInput,
        PercentageInput
    }
})
export default class ContractInformation extends Vue {
    @Prop({ required: true }) id!: number;
    @Prop({ required: true }) projectId!: string;
    @Prop({ required: true, default: false }) isReadOnly!: boolean;

    savePending: boolean = false;
    banks: NU<IBank[]> = [];
    salesAccount: NU<ISalesAccount[]> = [];
    selectedSaleAccount: NU<number> = null;
    defaultAgency : NU<IAgency>;
    promiseExport: boolean = false;

    paymentDeadline: string = '';

    @Watch('paymentDeadline')
    onPaymentDeadlineChange(newVal: string, _oldVal: string) {
        if (this.currentContract != null) {
            this.currentContract.terme = Number(newVal.slice(0, 2));
            this.currentContract.termeEndOfMonth = newVal.slice(-1) === '+';
        }
    }

    get paymentDeadlines(): Array<{ code: string; label: string }> {
        return deadlines;
    }

    get insurancePercentages(): NU<IReferential[]> {
        return vxm.referential.insurancePercentages;
    }

    get insuranceTypes(): NU<IReferential[]> {
        return vxm.referential.insuranceTypes;
    }

    get typos(): NU<IReferential[]> {
        return vxm.referential.typos;
    }

    get phases(): NU<IReferential[]> {
        return vxm.referential.phases;
    }

    get billingTypes(): NU<IReferential[]> {
        return vxm.referential.billingTypes;
    }

    get agencies(): NU<IAgency[]> {
        return vxm.project.projectData?.agencies;
    }

    async getDefaultAgency(): Promise<void> {
        const agenciesCallData = await referentialApi.getAllAgencies();
        if (isCallValidAndNotCancelled<IAgency[]>(agenciesCallData)) {
            if (agenciesCallData?.datas) {
                this.defaultAgency = agenciesCallData?.datas.find(agency => agency.label?.toUpperCase() === 'RICHEZ_ASSOCIES');
            }
        }
    }

    get clients(): NU<IClient[]> {
        return vxm.project.projectData?.clients;
    }

    get formatedCreationDate(): string {
        return (
            format(new Date(String(this.currentContract?.creationDate)), 'EEEE dd MMMM yyyy à HH:mm:ss', {
                locale: frenchLocale
            }) ?? ''
        );
    }

    get formatedModificationDate(): string {
        return (
            format(new Date(String(this.currentContract?.modificationDate)), 'EEEE dd MMMM yyyy à HH:mm:ss', {
                locale: frenchLocale
            }) ?? ''
        );
    }

    get isValidForm(): boolean {
        let res = true;
        res = res && this.currentContract?.nbMarket != null && this.currentContract.nbMarket.length > 0;
        this.currentContract?.contractLines?.forEach((element) => {
            res = res && element.insuranceTypeId !== undefined && element.insuranceTypeId > 0;
            res = res && element.phaseId !== undefined && element.phaseId > 0;
            res = res && element.designation !== undefined && element.designation !== '';
        });
        return res;
    }

    get saleAccountOptions() : ISelectListOption[] | undefined {
        return this.salesAccount?.map((x) => {
            const saleAccountList: ISelectListOption = {
                id: x.code ?? 0,
                code: x.code?.toString() ?? '',
                label: x.label ?? ''
            };
            return saleAccountList;
        });
    }

    @Watch('currentContract.agencyId')
    private assignagencyId(_newVal: number, _oldVal: number) {
        if (_newVal !== undefined) {
            const res = vxm.referential.banks;
            if (res && res.length > 0) {
                this.banks = res?.filter((x) => x.agencyId === _newVal);
            }
            if (this.currentContract && res && res.length > 0 && this.banks && this.banks.length === 0) {
                this.currentContract.bankId = undefined;
            }
        }
    }

    private currentContract: NU<IContract> = {} as IContract;

    private contractFields: IBootstrapTableColumn[] = [
        { key: 's', sortable: false, thStyle: 'max-width: 100px;', tdClass: 'td-s-width' },
        {
            label: "Type d'assurance",
            key: 'insuranceType',
            sortable: false,
            thStyle: 'max-width: 100px;',
            tdClass: 'td-insurance-percentage-width'
        },
        {
            label: 'Typo',
            key: 'typo',
            sortable: false,
            thStyle: 'max-width: 100px;',
            tdClass: 'td-typo-width'
        },
        { label: 'Désignation/Mission', key: 'designation', sortable: false, thStyle: 'min-width:200px' },
        { label: 'Provisoire', key: 'isProvisional', sortable: false, thStyle: 'min-width:30px' },
        { label: 'Phase', key: 'phase', sortable: false, thStyle: 'max-width: 100px;', tdClass: 'td-phase-width' },
        { label: 'Quantité', key: 'quantity', sortable: false, thStyle: 'width:80px' },
        { label: 'Prix HT(€)', thStyle: 'width: 120px;', key: 'priceExcTax', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Montant HT(€)', thStyle: 'width: 120px;', key: 'total', sortable: false, formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Commentaire', key: 'comment', sortable: false },
        // {
        //     label: 'Facturation',
        //     key: 'billingType',
        //     sortable: false,
        //     thStyle: 'max-width: 100px;',
        //     tdClass: 'td-billing-type-width'
        // },
        { key: 'delete', sortable: false, thStyle: 'max-width: 50px;', tdClass: 'td-delete-width' }
    ];

    private async mounted(): Promise<void> {
        this.getContract(this.id);
        await vxm.referential.fetchInsurancePercentages();
        await vxm.referential.fetchInsuranceTypes();
        await vxm.referential.fetchTypos();
        await vxm.referential.fetchPhases();
        await vxm.referential.fetchBillingTypes();
        await vxm.referential.fetchBanks();
        await vxm.referential.fetchSalesAccount();
        await vxm.user.checkIsAdmin();
        this.salesAccount = vxm.referential.salesAccounts;
        this.banks = vxm.referential.banks;
        const res = vxm.referential.banks;
        if (res && res.length > 0) {
            this.banks = res?.filter((x) => x.agencyId === this.currentContract?.agencyId);
        }
    }

    private async getContract(id: number): Promise<void> {
        if (id && id !== 0) {
            const datas = await contractApi.getBase(id + '');
            if (isCallValidAndNotCancelled<IContract | null>(datas)) {
                this.currentContract = datas?.datas;
                if (this.currentContract?.terme != null && this.currentContract.termeEndOfMonth != null) {
                    this.paymentDeadline =
                        '' +
                        (this.currentContract.terme === 0 ? '00' : this.currentContract.terme) +
                        (this.currentContract.termeEndOfMonth === true ? '+' : '=');
                }

                await this.getDefaultAgency();
                
                if (this.currentContract && !this.currentContract?.agencyId && this.defaultAgency) {
                    this.currentContract.agencyId = this.defaultAgency.id;
                    if (!this.agencies?.some((agency: IAgency) => agency.label?.toUpperCase() === 'RICHEZ_ASSOCIES')) {
                        this.agencies?.push(this.defaultAgency);
                    }
                }
            }
        } else if (this.currentContract) {
            this.currentContract.terme = 60;
        }
    }

    @Watch('id')
    switchContractById(newVal: number, _oldVal: number): void {
        if (newVal) {
            this.getContract(newVal);
        }
    }

    isDisabled() {
        if (!this.isAdmin) return true;
        const filteredInvoices = vxm.project.invoicesList
            ?.filter((invoice) => invoice.contractId === this.currentContract?.id)
            ?.map((invoice) => ({
                generatedValue: invoice.isGenerated,
                reference: invoice.reference,
            }));
        return filteredInvoices?.some((invoice) => invoice.generatedValue === true);
    }

    @Watch('currentContract.contractLines')
    private assigncontractLines(_newVal: IContractLine[], _oldVal: IContractLine[]) {
        this.calculatetotalExcTax();
    }

    private calculatetotalExcTax(): void {
        if (this.currentContract && this.currentContract.contractLines) {
            const totales = this.currentContract.contractLines.map((x) => x.total);
            if (totales && totales.length === 0) {
                this.currentContract.totalExcTax = 0;
            } else if (totales && totales.length === 1) {
                this.currentContract.totalExcTax = totales[0];
            } else {
                this.currentContract.totalExcTax = Number(
                    totales.reduce((a: number, b: number) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)).toFixed(2)
                );
            }
            this.currentContract.total = Number(
                (
                    this.currentContract.totalExcTax +
                    (this.currentContract.totalExcTax * this.currentContract.tax) / 100
                ).toFixed(2)
            );
        }
    }

    get totalExcTaxFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(
            this.currentContract?.totalExcTax ?? 0
        );
    }

    // get taxFormatter(): string {
    //     return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(
    //         Number(this.currentContract?.totalExcTax) * (Number(this.currentContract?.tax) / 100) ?? 0
    //     );
    // }

    // get totalFormatter(): string {
    //     return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(this.currentContract?.total ?? 0);
    // }

    onRecalculateLineTotal(item: IContractLine): void {
        if (
            item &&
            item.quantity !== null &&
            item.quantity !== undefined &&
            item.priceExcTax !== null &&
            item.priceExcTax !== undefined
        ) {
            item.total = Number((item.quantity * item.priceExcTax).toFixed(2));
            this.calculatetotalExcTax();
        }
    }

    @Watch('currentContract.tax')
    private assignTax(newVal: number, _oldVal: number) {
        if (this.currentContract) {
            this.currentContract.total = Number(
                (this.currentContract.totalExcTax + (this.currentContract.totalExcTax * newVal) / 100).toFixed(2)
            );
        }
    }

    async exportListContractLine(): Promise<void> {
        if (this.currentContract && this.currentContract.id) {
            await this.generateListContractLine(
                contractApi.exportContractLinesData(this.currentContract?.id),
                `Liste_lignes_contrat${this.formatDate(new Date())}.xlsx`
            );
        }
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd-HH:mm:ss', { locale: frenchLocale }) ?? '';
    }

    private async generateListContractLine(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    async saveContract(): Promise<void> {
        if (this.currentContract) {
            this.savePending = true;
            this.updateLinePositionNumber();
            this.currentContract.terme = Number(this.currentContract.terme) ?? null;
            this.currentContract.salesAccount = this.selectedSaleAccount ?? this.currentContract.salesAccount;

            const callData = await contractApi.patchBase(this.currentContract.id + '', this.currentContract);
            if (isCallValidAndNotCancelled(callData)) {
                this.getContract(this.id);
                await vxm.project.updateContracts(this.projectId);
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: `Contrat: ${this.currentContract.reference}`,
                    variant: 'success',
                    solid: true
                });
            }
            this.savePending = false;
        }
    }

    addContractLine(): void {
        const lineToAdd: IContractLine = {
            id: 0,
            contractId: this.currentContract?.id ?? 0,
            positionNumber: (this.currentContract?.contractLines?.length ?? 0) + 1,
            insuranceTypeId: 1,
            phaseId: 1,
            billingTypeId: 1,
            quantity: 1,
            priceExcTax: 0,
            total: 0,
            tax: 0,
            advancement: 0,
            marginPercentage: 0
        };
        this.currentContract?.contractLines?.push(lineToAdd);
    }

    deleteContractLine(item: IContractLine): void {
        const index = this.currentContract?.contractLines?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.currentContract?.contractLines?.splice(index, 1);
        }
    }

    sortDown(item: IContractLine): void {
        const index = this.currentContract?.contractLines?.indexOf(item);
        if (index !== undefined && index > -1 && this.currentContract && this.currentContract.contractLines) {
            this.currentContract?.contractLines?.splice(
                index + 1,
                0,
                this.currentContract?.contractLines.splice(index, 1)[0]
            );
        }
    }

    sortUp(item: IContractLine): void {
        const index = this.currentContract?.contractLines?.indexOf(item);
        if (index !== undefined && index > -1 && this.currentContract && this.currentContract.contractLines) {
            this.currentContract?.contractLines?.splice(
                index - 1,
                0,
                this.currentContract?.contractLines.splice(index, 1)[0]
            );
        }
    }

    updateLinePositionNumber(): void {
        if (
            this.currentContract &&
            this.currentContract.contractLines &&
            this.currentContract.contractLines !== undefined
        ) {
            this.currentContract.contractLines?.map((x: IContractLine, index: number) => {
                x.positionNumber = index + 1;
            });
        }
    }

    rowClass(item, type): any {
        if (!item || type !== 'row') return;
        if (item.isProvisional) {
            return 'table-danger';
        }
    }

    public get isAdmin() {
        return vxm.user.isAdmin;
    }
}
