
import { Component, Vue } from 'vue-property-decorator';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { referentialApi } from '@/wapi/referential-api';
import CurrencyInput from '@c/shared/currency-input.vue';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { ISalesAccount } from '@/entity/project/sales-account';
import ReferentialBase from './referential-base.vue';

@Component({
    components: {
        CurrencyInput
    }
})
export default class ReferentialSalesAccount extends ReferentialBase {
    salesAccount: NU<ISalesAccount[]> = [];
    private promiseExport: boolean = false;
    private isBusy: boolean = true;

    get isValidForm(): boolean {
        let res = true;
        this.salesAccount?.forEach((element) => {
            res = res && element.code !== undefined;
            res = res && element.label !== undefined && element.label !== '';
        });
        return res;
    }

    async getSalesAccount(): Promise<void> {
        const salesCallData = await referentialApi.getAllSalesAccount();
        if (isCallValidAndNotCancelled<ISalesAccount[]>(salesCallData)) {
            if (salesCallData?.datas) {
                this.salesAccount = salesCallData.datas;
            }
        }
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Code', key: 'code', thStyle: 'width:200px' },
        { label: 'Description', key: 'label' },
        { label: '', key: 'delete', thStyle: 'width:80px' }
    ];

    async saveSalesAccount(): Promise<void> {

        this.salesAccount = this.salesAccount?.map(_ => ({ ..._, id: _.id ? _.id : 0, code: parseInt(_.code! + '') }));
        const callData = await referentialApi.patchSalesAccount(this.salesAccount ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.getSalesAccount;
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Liste des comptes de ventes',
                variant: 'success',
                solid: true
            });
        }

        await this.getSalesAccount();

    }

    addSalesAccount(): void {
        const salesAccount = {} as ISalesAccount;
        this.salesAccount?.push(salesAccount);
    }

    deleteSalesAccountLine(item: ISalesAccount): void {
        const index = this.salesAccount?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.salesAccount?.splice(index, 1);
        }
    }

    formatterCode(val: string): string {
        if (!val) {
            return val;
        }
        return val.toUpperCase().trim();
    }

    async exportSalesAccount(): Promise<void> {
        await this.generateReport(
            referentialApi.exportSalesAccountData(),
            `Liste_des_comptes_ventes_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    mounted() {
        vxm.app.changeTitleMain('Liste des comptes de ventes');
        this.getSalesAccount();
        this.isBusy = false;
    }
}
