
import { IContractLine } from '@/entity/contract/contract-line';
import { IImputationWorkflowItem } from '@/entity/rh/workflow';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ImputationCalendar from './imputation-calendar.vue';
@Component({
    components: {
        ImputationCalendar
    }
})
export default class ImputationContractLineItem extends Vue {
    @Prop({ required: true })
    private contractLine!: IContractLine;

    @Prop({ required: true })
    private readonly!: boolean;

    @Prop({ required: true })
    private selectedDate!: Date;

    get isValid(): boolean {
        return Object.keys(this.contractLine.imputations!).findIndex(
            (key: string) => {
                const imputationSum: number = this.contractLine.imputations![Number(key)]
                    .map((x: IImputationWorkflowItem) => x.value >= 0 && x.value <= 1 ? x.value : 999)
                    .reduce((x: number, y: number) => x + y);
                return imputationSum < 0 || imputationSum > 3;
            }) === -1;
    }
}
