
import { getWeeksInMonth } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CalendarDayPickerHeader from '@c/shared/calendar-day-picker/calendar-day-picker-header.vue';
import ImputationCalendarSummaryDay from './imputation-calendar-summary-day.vue';
import { IImputationDate } from './imputation-interfaces';
import { IProject } from '@/entity/project/project';

@Component({
    components: {
        CalendarDayPickerHeader,
        ImputationCalendarSummaryDay
    }
})
export default class ImputationCalendarSummary extends Vue {
    @Prop({ required: true })
    private selectedDate!: Date;

    @Prop({ required: true })
    private projects!: IProject[];

    @Prop({ required: true })
    private sums!: number[];

    @Prop({ required: true })
    private errors!: boolean[];
    
    get getMonthDays(): IImputationDate[] {
        const result: IImputationDate[] = [];
        const date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);
        let weekDay = 1;
        
        while (weekDay !== date.getDay()) {
            result.push({
                date: new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + weekDay),
                isInWorkflow: false,
                imputationSum: 0,
                error: false
            });
            weekDay = (weekDay + 1) % 7;
        }
        const month = date.getMonth();
        while (date.getMonth() === month) {
            result.push({
                date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                isInWorkflow: true,
                imputationSum: this.sums[date.getDate() - 1],
                error: this.errors[date.getDate() - 1]
            });
            date.setDate(date.getDate() + 1);
        }
        while (date.getDay() !== 1) {
            result.push({
                date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                isInWorkflow: false,
                imputationSum: 0,
                error: false
            });
            date.setDate(date.getDate() + 1);
        }
        return result;
    }

    get getWeeksInMonth(): number {
        return getWeeksInMonth(this.selectedDate, { weekStartsOn: 1 });
    }

    get currentYear(): number {
        return this.selectedDate.getFullYear();
    }

    get currentMonth(): number {
        return this.selectedDate.getMonth();
    }
}
