import { IProvisionalFee } from '@/entity/provisional-fee/provisional-fee';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';

class ProvisionalFeeApi extends ApiBase<IProvisionalFee> {
    /**
     *
     */
    constructor(urlBase: string = 'api/provisionalfees') {
        super(urlBase);
    }

    public async getAllProvisionalFeesByProjectId(projectId: number): Promise<ICancellableResult<IProvisionalFee[]>> {
        return await ajaxCall.baseAjaxCall<IProvisionalFee[]>('get', this.url, {
            urlParameter: ['getprovisionalfees'],
            queryParameter: { projectId: projectId + '' }
        });
    }

    public async patchProvisionalFees(data: IProvisionalFee[], projectId: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IProvisionalFee[]>('patch', this.url, {
            urlParameter: ['saveprovisionalfees', projectId],
            payload: data
        });
    }

    public async exportProvisionalFeesData(data: number): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, number>('post', this.url, {
            payload: data,
            urlParameter: ['exportProvisionalFeesData'],
            responseType: 'blob'
        });
    }
}

export const provisionalFeeApi: ProvisionalFeeApi = new ProvisionalFeeApi();
