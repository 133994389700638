
import { getWeeksInMonth } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CalendarDayPickerHeader from '@c/shared/calendar-day-picker/calendar-day-picker-header.vue';
import { IContractLine } from '@/entity/contract/contract-line';
import ImputationCalendarDay from './imputation-calendar-day.vue';

@Component({
    components: {
        CalendarDayPickerHeader,
        ImputationCalendarDay
    }
})
export default class ImputationCalendar extends Vue {
    @Prop({ required: true })
    private contractLine!: IContractLine;

    @Prop({ required: true })
    private selectedDate!: Date;

    @Prop({ required: true })
    private readonly!: boolean;

    get getWeeksInMonth(): number {
        return getWeeksInMonth(this.selectedDate, { weekStartsOn: 1 });
    }

    get weekDaysBefore(): number[] {
        const result: number[] = [];

        let weekDay: number = 1;

        while (weekDay !== this.selectedDate.getDay()) {
            const newDate = new Date(
                this.selectedDate.getFullYear(),
                this.selectedDate.getMonth(),
                this.selectedDate.getDate() - weekDay
            );
            result.push(newDate.getDate());
            weekDay = (weekDay + 1) % 7;
        }
        return result.reverse();
    }

    get weekDaysAfter(): number[] {
        const result: number[] = [];
        const date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 1);
        while (date.getDay() !== 1) {
            result.push(date.getDate());
            date.setDate(date.getDate() + 1);
        }
        return result;
    }

    isWeekendDay(date: number): boolean {
        const curDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), date).getDay();
        return curDay === 6 || curDay === 0;
    }
}
