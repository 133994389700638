
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CalendarDayPickerHeader extends Vue {
    @Prop({ default: false }) onlyWeekDay!: boolean;

    dayLabelBase: string[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

    get dayLabel() {
        if (this.onlyWeekDay === true) {
            return this.dayLabelBase.filter((x) => x !== 'Samedi' && x !== 'Dimanche');
        }
        return this.dayLabelBase;
    }
}
