
import { MgtPerson } from '@microsoft/mgt';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { User } from '@microsoft/microsoft-graph-types/microsoft-graph';
import { moduleApiGraph } from '@t/module-api-graph';

@Component
export default class AffectationMgtPerson extends Vue {
    @Prop({ required: false, default: null }) userId!: string | null;
    @Prop({ required: true }) trigram!: string;
    @Prop({ required: false, default: false }) useTrigram!: boolean;

    public currentUser: User | null = null;
    public transformedUser: User | null = null;

    @Watch('currentUser') onCurrentUserAssigned(newval: User | null) {
        if (newval == null) {
            this.transformedUser = null;
            return;
        }
        const u = this.useTrigram
            ? { ...newval, displayName: this.currentUser?.givenName != null && this.currentUser?.surname != null ? `${this.trigram} - ${this.currentUser?.givenName} ${this.currentUser?.surname}` : `${this.trigram} - ${this.currentUser?.displayName}` }
            : { ...newval, displayName: this.currentUser?.givenName != null && this.currentUser?.surname != null ? `${this.currentUser?.givenName} ${this.currentUser?.surname}` : this.currentUser?.displayName };
        this.transformedUser = u;
    } 

    async created() {
        if (this.userId == null) {
            throw new Error('userid is null');
        }

        const gettedUser = await moduleApiGraph.Client.api(`/users/${this.userId}?$select=id,displayName,surname,givenName,userType`).get();
        if (gettedUser != null) {
            this.currentUser = gettedUser;
        }
    }
}
