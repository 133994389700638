
import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { IPlanificationPhaseProfile } from '@/entity/planification/planification-phase-profile';
import { IProject } from '@/entity/project/project';
import { planificationApi } from '@/wapi/planification-api';
import { projectApi } from '@/wapi/project-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { NU } from '@t/type';
import { format, isAfter, isBefore, isDate, isEqual, max, min } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { Component, Vue, Watch } from 'vue-property-decorator';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
import CalendarDayPicker from '@c/shared/calendar-day-picker.vue';
import CurrencyInput from '@c/shared/currency-input.vue';
import RecapCalendar from '@c/project-planification/recap-calendar.vue';
import { vxm } from '@/store';
import { IEmployeeRole } from '@/entity/shared/referential';
import { ICalendarHalfDay, ICalendarProp } from '@/entity/shared/calendar-day-picker';
import { IPlanificationPhaseProfileWorkDay } from '@/entity/planification/planification-phase-profile-work-day';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { appTokenMgr } from '@t/employee-app-role';
import { authModule } from '@t/session';
import InputElement from '@c/shared/input-element.vue';
import { moduleApiGraph } from '@t/module-api-graph';
import { studioApi } from '@/wapi/studio-api';
import { IStudio } from '@/entity/project/studio';
import { IEmployee } from '@/entity/shared/employee';

@Component({
    components: {
        InputElementMgt,
        InputElement,
        CalendarDayPicker,
        CurrencyInput,
        RecapCalendar
    }
})
export default class ProjectPlanification extends Vue {
    isStudioManagerProject: boolean = false;
    isAddProfileBouttonClicked : boolean = false;
    private defaultDate: Date = new Date();
    private selectedOpeningDates: Date[] = [];
    promiseSave: boolean = false;
    inputStartDate: string = '';
    inputEndDate: string = '';
    selectedPlanificationPhaseToUpdate: IPlanificationPhase = {} as IPlanificationPhase;
    private studios: NU<IStudio[]> = [];

    public get planificationPhaseProfiles() {
        return this.selectedPlanificationPhaseToUpdate.planificationPhaseProfiles;
    }

    private weekDays: ISelectListOption[] = [
        { id: 1, code: 'CO1', label: 'Lundi' },
        { id: 2, code: 'CO2', label: 'Mardi' },
        { id: 3, code: 'CO3', label: 'Mercredi' },
        { id: 4, code: 'CO4', label: 'Jeudi' },
        { id: 5, code: 'CO5', label: 'Vendredi' }
    ];

    private dayTypesOptions = [
        { text: 'Matin', value: 'MOR' },
        { text: 'Aprés-midi', value: 'AFT' }
    ];

    get groupPickerId() {
        if (process.env.NODE_ENV === 'production') {
            return 'e59b07b5-a6f9-4040-b817-bad6fe2f2f1b';
        }
        return null;
    }

    private currentMonth: number = 0;
    private currentYear: number = 0;

    planificationPhases: NU<IPlanificationPhase[]> = [];

    get employeeRoles(): NU<IEmployeeRole[]> {
        return vxm.referential.employeeRoles;
    }

    get selectedProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    private canEditOrDelete(): boolean {
        return vxm.user.isAdmin || vxm.user.isStudioManager;
    }

    private canAddNewProfile(): boolean {
        const acc = authModule.getAccount();
        return (
            appTokenMgr.isAdmin(acc) ||
            appTokenMgr.isDaf(acc) ||
            appTokenMgr.isStudioManager(acc) ||
            appTokenMgr.isCP(acc)
        );
    }

    get isReadonly(): boolean {
        return !vxm.user.isStudioManager && !vxm.user.isAdmin && !vxm.user.isCP;
    }

    get isCP(): boolean {
        return vxm.user.isCP;
    }

    private getPhaseTotalRate(item: IPlanificationPhase): number {
        let res = 0;
        item.planificationPhaseProfiles.forEach((element) => {
            res += element.totalRate;
        });
        return res;
    }

    private formattedPhaseTotalRate(item: IPlanificationPhase): string {
        return this.formattedCurrency(this.getPhaseTotalRate(item));
    }

    private formattedCurrency(item: number): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            item
        );
    }

    private changeOpeningDate: { oldDate: Date; newDate: Date } = {} as { oldDate: Date; newDate: Date };

    private async changeContextDate(context) {
        this.selectedOpeningDates.push(context.selectedDate);
        const planificationToUpdate = this.planificationPhases?.find(p => p.id === this.selectedPlanificationPhaseToUpdate.id);
        if (this.isCalendarOpen && this.selectedOpeningDates.length > 1 && 
            this.selectedOpeningDates[this.selectedOpeningDates.length - 1].setHours(0, 0, 0, 0) !== 
            this.selectedOpeningDates[this.selectedOpeningDates.length - 2].setHours(0, 0, 0, 0) && planificationToUpdate) {
            this.$nextTick(async () => {
                var dayNew = new Date(this.changeOpeningDate.newDate);
                dayNew.setHours(0, 0, 0, 0);

                var dayOld = new Date(this.changeOpeningDate.oldDate);
                dayOld.setHours(0, 0, 0, 0);

                this.promiseSave = true;
                var slided = await planificationApi.slidePhases(
                    planificationToUpdate.planificationPhaseProfiles,
                    dayOld,
                    dayNew
                );

                if (isCallValidAndNotCancelled<IPlanificationPhaseProfile[]>(slided)) {
                    this.setPhaseToUpdate(planificationToUpdate.id);
                    planificationToUpdate?.planificationPhaseProfiles?.forEach((item, index) => {
                        this.promiseSave = false;
                        const ppp = (slided.datas as IPlanificationPhaseProfile[])[index];

                        ppp.planificationPhaseProfileWorkDays.forEach(day => {
                            const currentDate = new Date(day.date);
                            currentDate.setHours(0, 0, 0, 0);
                            day.date = currentDate;
                        });

                        item.planificationPhaseProfileWorkDays = ppp.planificationPhaseProfileWorkDays;

                        this.moveWorkDatesForModal(item, index);
                    });                   
                }
            });
        }
    }

    private isCalendarOpen: boolean = false;

    @Watch('selectedPlanificationPhaseToUpdate.openingDate')
    public async updatedOpeningDate(newVal: Date, oldVal: Date) {
        this.changeOpeningDate.newDate = new Date(newVal);
        this.changeOpeningDate.oldDate = new Date(oldVal);
    }

    getTomorrowDate() {
        var result = new Date();
        result.setDate(result.getDate() + 1);
        return result;
    }

    addProfile(item: IPlanificationPhase): void {
        const profile = {} as IPlanificationPhaseProfile;
        this.isAddProfileBouttonClicked = true;
        profile.employeeId = null;
        profile.planificationPhaseId = item.id;
        profile.totalRate = 0;
        profile.employeeRoleLabel = '';
        profile.selectedDayTypes = ['MOR', 'AFT'];
        item.planificationPhaseProfiles.push(profile);
    }

    deleteProfileModal(index: number, phase: IPlanificationPhase): void {
        this.selectedPlanificationPhaseToUpdate?.planificationPhaseProfiles?.splice(index, 1);
        this.setOpeningAndClosingDateForPhase(phase);
        if (this.selectedPlanificationPhaseToUpdate?.planificationPhaseProfiles.length === 0) {
            this.isAddProfileBouttonClicked = false;
            this.selectedPlanificationPhaseToUpdate.openingDate = null;
            this.selectedPlanificationPhaseToUpdate.closingDate = null;
        }
    }

    isDisableAssigneWorkDates(profile: IPlanificationPhaseProfile): boolean {
        return !profile.startDate || !profile.endDate;
    }

    assigneWorkDatesForModal(profile: IPlanificationPhaseProfile, index: number, phase: IPlanificationPhase): void {
        const date = this.inputStartDate ? this.createDate(this.inputStartDate) : new Date(profile.startDate);
        const dateEnd = this.inputEndDate ? this.createDate(this.inputEndDate) : new Date(profile.endDate);
        const calendarPropWorkDays = {} as ICalendarProp;
        calendarPropWorkDays.defaultMonth = new Date(profile.startDate)?.getMonth() ?? 0;
        calendarPropWorkDays.defaultYear = new Date(profile.startDate)?.getFullYear() ?? 0;
        if (date && dateEnd) {
            calendarPropWorkDays.defaultSelected = [];
            const dayPartTypes = vxm.referential.dayPartTypes;
            do {
                const dayNum = date.getDay();
                if (
                    dayNum !== 0 &&
                    dayNum !== 6 &&
                    (profile.selectedWeekDays === undefined ||
                        profile.selectedWeekDays.length === 0 ||
                        profile.selectedWeekDays?.find((x) => x === dayNum) !== undefined)
                ) {
                    if (
                        profile.selectedDayTypes.find((x) => x === 'MOR') !== undefined ||
                        profile.selectedDayTypes.length === 0
                    ) {
                        calendarPropWorkDays.defaultSelected.push({
                            year: date.getFullYear(),
                            month: date.getMonth(),
                            day: date.getDate(),
                            dayPartId: dayPartTypes?.filter((x) => x.code === 'MOR')[0].id ?? 1
                        } as ICalendarHalfDay);
                    }
                    if (
                        profile.selectedDayTypes.find((x) => x === 'AFT') !== undefined ||
                        profile.selectedDayTypes.length === 0
                    ) {
                        calendarPropWorkDays.defaultSelected.push({
                            year: date.getFullYear(),
                            month: date.getMonth(),
                            day: date.getDate(),
                            dayPartId: dayPartTypes?.filter((x) => x.code === 'AFT')[0].id ?? 2
                        } as ICalendarHalfDay);
                    }
                }
                date.setDate(date.getDate() + 1);
            } while (date.setHours(0, 0, 0, 0) <= dateEnd.setHours(0, 0, 0, 0));
        }

        this.selectedPlanificationPhaseToUpdate?.planificationPhaseProfiles.forEach((x, i) => {
            if (i === index) {
                x.calendarPropWorkDays = calendarPropWorkDays;
                this.onCollectionChange(calendarPropWorkDays.defaultSelected, x, index);
            }
        });
    }

    moveWorkDatesForModal(
        profile: IPlanificationPhaseProfile,
        index: number
    ) {
        const dates = profile.planificationPhaseProfileWorkDays?.map((x) => new Date(x.date));
        profile.startDate = min(dates);
        profile.endDate = max(dates);
        const calendarPropWorkDays = {} as ICalendarProp;
        calendarPropWorkDays.defaultMonth = new Date(profile.startDate)?.getMonth() ?? 0;
        calendarPropWorkDays.defaultYear = new Date(profile.startDate)?.getFullYear() ?? 0;
        if (profile.endDate) {
            calendarPropWorkDays.defaultSelected = [];
            profile.planificationPhaseProfileWorkDays.forEach((item) => {
                const currentDate = new Date(item.date);
                currentDate.setHours(0, 0, 0, 0);
                item.date = currentDate;
                const dayNum = currentDate.getDay();
                if (
                    dayNum !== 0 &&
                    dayNum !== 6 &&
                    (profile.selectedWeekDays === undefined ||
                        profile.selectedWeekDays.length === 0 ||
                        profile.selectedWeekDays?.find((x) => x === dayNum) !== undefined)
                ) {
                    calendarPropWorkDays.defaultSelected.push({
                        year: currentDate.getFullYear(),
                        month: currentDate.getMonth(),
                        day: currentDate.getDate(),
                        dayPartId: item.dayPartTypeId
                    } as ICalendarHalfDay);
                }
            });
        }

        if (
            this.selectedPlanificationPhaseToUpdate != null &&
            this.selectedPlanificationPhaseToUpdate.planificationPhaseProfiles.length >= index
        ) {
            const x = this.selectedPlanificationPhaseToUpdate?.planificationPhaseProfiles[index];
            x.calendarPropWorkDays = calendarPropWorkDays;
            this.onCollectionChange(calendarPropWorkDays.defaultSelected, x, index);
        }
    }

    get formatedOpeningDate(): string {
        if (this.selectedProject?.openingDate) {
            return (
                format(new Date(String(this.selectedProject?.openingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    get formatedClosingDate(): string {
        if (this.selectedProject?.closingDate) {
            return (
                format(new Date(String(this.selectedProject?.closingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    @Watch('selectedProjectId')
    async assigneProject(newVal: number, _oldVal: number): Promise<void> {
        if (newVal && newVal > 0) {
            await this.getPlanificationPhases(newVal);
            await this.checkIsStudioManger(newVal);
        }
    }

    isInFutur(date: Date) {
        return isAfter(new Date(date), new Date());
    }

    setOpeningAndClosingDateForPhase(phase: IPlanificationPhase): void {
        const dates: Date[] = [];
        const phaseToUpdate = this.planificationPhases?.find(p => p.id === phase.id);
        if (phase && phase.planificationPhaseProfiles && phase.planificationPhaseProfiles.length > 0 && phaseToUpdate) {
            phase.planificationPhaseProfiles.forEach((profile) => {
                const itemDates = profile.planificationPhaseProfileWorkDays.map((x) => new Date(x.date));
                dates.push(...itemDates);
            });
            if (dates && dates.length > 0) {
                const minDate = min(dates);
                const maxDate = max(dates);
                minDate.setHours(3);
                maxDate.setHours(3);
                phase.openingDate = minDate.toISOString();
                phase.closingDate = maxDate.toISOString();

                phaseToUpdate.openingDate = phase.openingDate;
                phaseToUpdate.closingDate = phase.closingDate;
            }
            else {
                phase.openingDate = null;
                phase.closingDate = null;
                phaseToUpdate.openingDate = null;
                phaseToUpdate.closingDate = null;
            }
        }
        else if (phase.planificationPhaseProfiles.length === 0 && phaseToUpdate) {
            phase.openingDate = null;
            phase.closingDate = null;
            phaseToUpdate.openingDate = null;
            phaseToUpdate.closingDate = null;
        }
    }

    async getPlanificationPhases(projectId: number): Promise<void> {
        this.promiseSave = true;
        const phaseCallData = await planificationApi.getAllPhasesByProjectId(projectId);
        if (isCallValidAndNotCancelled<IPlanificationPhase[]>(phaseCallData)) {
            this.planificationPhases = phaseCallData?.datas;
            this.promiseSave = false;
            this.isAddProfileBouttonClicked = false;
            if (this.planificationPhases) {
                for (const phase of this.planificationPhases) {
                    if (phase.openingDate) {
                        const openingDate = new Date(phase.openingDate);
                        openingDate.setHours(0, 0, 0, 0);
                        phase.openingDate = openingDate;
                    }
                    if (phase.closingDate) {
                        const closingDate = new Date(phase.closingDate);
                        closingDate.setHours(0, 0, 0, 0);
                        phase.closingDate = closingDate;
                    }
                    for (let i = 0; i < phase.planificationPhaseProfiles.length; i++) {
                        const profile = phase.planificationPhaseProfiles[i];
                        profile.planificationPhaseProfileWorkDays.forEach(day => {
                            const currentDate = new Date(day.date);
                            currentDate.setHours(0, 0, 0, 0);
                            day.date = currentDate;
                        });
                        if (profile.employeeId) {
                            this.promiseSave = true;
                            const u = await planificationApi.getEmployeeHistorization(profile.employeeId as string);
                            if (isCallValidAndNotCancelled(u)) {
                                profile.employee = u.datas as IEmployee;
                                this.setDateforProfile(profile, i);
                                profile.selectedDayTypes = ['MOR', 'AFT'];
                                this.promiseSave = false;
                            }
                        } else {
                            this.setDateforProfile(profile, i);
                            profile.selectedDayTypes = ['MOR', 'AFT'];
                        }
                    }
                }
            }
        }
    }

    setDateforProfile(profile, index) {
        const phaseToUpdate = this.planificationPhases?.find(p => p.id === profile.planificationPhaseId || p.id === profile.parentPhaseId);
        if (phaseToUpdate) {
            const profileToUpdate = phaseToUpdate.planificationPhaseProfiles[index];
            if (profile.planificationPhaseProfileWorkDays.length === 0 && !this.isAddProfileBouttonClicked) {
                phaseToUpdate.planificationPhaseProfiles.splice(index, 1);
            } else {           
                if (profileToUpdate) {
                    profileToUpdate.planificationPhaseProfileWorkDays = profile.planificationPhaseProfileWorkDays;
                    const defaultSelected = profile.planificationPhaseProfileWorkDays.map((x) => {
                        const d: Date = new Date(x.date);
                        return {
                            year: d.getFullYear(),
                            month: d.getMonth(),
                            day: d.getDate(),
                            dayPartId: x.dayPartTypeId
                        } as ICalendarHalfDay;
                    });
                    if (profile.planificationPhaseProfileWorkDays[0]) {
                        const date = new Date(profile.planificationPhaseProfileWorkDays[0].date);
                        profileToUpdate.calendarPropWorkDays = {
                            defaultSelected: defaultSelected,
                            defaultMonth: date.getMonth(),
                            defaultYear: date.getFullYear()
                        };
                    }
                }
            }

            if (phaseToUpdate.planificationPhaseProfiles.length === 0) {
                phaseToUpdate.openingDate = null;
                phaseToUpdate.closingDate = null;
            }
        }
    }

    async checkIsStudioManger(projectId: number): Promise<void> {
        this.promiseSave = true;
        const checkCallData = await projectApi.checkStudioManagerUser(projectId + '');
        const acc = authModule.getAccount();
        if (isCallValidAndNotCancelled<boolean>(checkCallData)) {
            this.promiseSave = false;
            this.isStudioManagerProject = (checkCallData?.datas ?? false) || appTokenMgr.isAdmin(acc);
        }
    }

    async savePlanificationPhases(): Promise<void> {
        this.promiseSave = true;
        const callData = await planificationApi.patchPhases(this.planificationPhases ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.promiseSave = false;
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Planification',
                variant: 'success',
                solid: true
            });
            this.getPlanificationPhases(this.selectedProject?.id ?? 0);
        }
    }

    private async selectedUserEvent(
        event: CustomEvent,
        item: { profile: IPlanificationPhaseProfile; phase: IPlanificationPhase, index: number },
        index : number
    ) {
        if (event && event.detail && event.detail.length > 0 && event.detail[0]) {
            item.profile.employeeId = event.detail[0].id;
            const u = await planificationApi.getEmployeeHistorization(item.profile.employeeId as string);
            item.profile.employee = u?.datas as IEmployee;
            item.profile.employeeName = event.detail[0].displayName;
            const lastEmployeeRole = u?.datas?.histos?.slice(-1)[0];
            item.profile.employeeRoleId = lastEmployeeRole?.employeeRoleId;
            item.profile.employeeRoleLabel = lastEmployeeRole?.employeeRole;
            this.setDateforProfile(item.profile, index);
            this.setOpeningAndClosingDateForPhase(this.selectedPlanificationPhaseToUpdate);
            this.selectedPlanificationPhaseToUpdate.planificationPhaseProfiles[item.index].employeeName = item.profile.employeeName;
            this.selectedPlanificationPhaseToUpdate = { ...this.selectedPlanificationPhaseToUpdate };
        } else {
            item.profile.employeeId = null;
            item.profile.employee = null as any;
            item.profile.totalRate = 0;
        }
        this.setProfileTotalRate(item.profile, item.phase, index);
    }

    private assignEmployeeRole(profile: IPlanificationPhaseProfile, phase: IPlanificationPhase, index : number) {
        this.setProfileTotalRate(profile, phase, index);
        if (this.showValidation === true && this.validationObject.length > index && this.validationObject[index].profil === true) {
            this.validationObject[index].profil = false;
        }
    }

    private onCollectionChange(halfDayCollection: Array<ICalendarHalfDay>, profile: IPlanificationPhaseProfile, index : number) {
        const workDays: IPlanificationPhaseProfileWorkDay[] = Array.from(
            profile.planificationPhaseProfileWorkDays ?? []
        );
        profile.planificationPhaseProfileWorkDays = [];
        if (halfDayCollection && halfDayCollection.length > 0) {
            halfDayCollection.forEach((element) => {
                const oldVal = workDays.filter((x) => {
                    const d = new Date(x.date);
                    return (
                        x.dayPartTypeId === element.dayPartId &&
                        d.getDate() === element.day &&
                        d.getMonth() === element.month &&
                        d.getFullYear() === element.year
                    );
                });
                if (oldVal === undefined || (oldVal && oldVal.length === 0)) {
                    const newObject: IPlanificationPhaseProfileWorkDay = {
                        planificationPhaseProfileId: profile.id,
                        date: new Date(element.year, element.month, element.day),
                        dayPartTypeId: element.dayPartId,
                        id: 0
                    };
                    newObject.date.setHours(0, 0, 0, 0);
                    profile.planificationPhaseProfileWorkDays.push(newObject);
                } else {
                    profile.planificationPhaseProfileWorkDays.push(oldVal[0]);
                }
            });
        }
        this.setDateforProfile(profile, index);
        this.setProfileTotalRate(profile, this.selectedPlanificationPhaseToUpdate, index);
        this.setOpeningAndClosingDateForPhase(this.selectedPlanificationPhaseToUpdate);
    }

    setProfileTotalRate(profile, phase: IPlanificationPhase, index: number = -1): void {
        if (profile.employee != null) {
            if (this.employeeRoles != null) {
                const employeeRoleList = profile.employee?.histos;

                if (employeeRoleList != null) {
                    let totalRate = 0;
                    employeeRoleList?.forEach(employeeRole => {
                        const eRoleRate = (this.employeeRoles?.find(r => r.id == employeeRole?.employeeRoleId)?.rate ?? 0);

                        const planificationPhaseProfileWorkDaysByRole = profile.planificationPhaseProfileWorkDays.filter(day => {
                            return (isAfter(new Date(day.date).setHours(0, 0, 0, 0), new Date(employeeRole.startDate).setHours(0, 0, 0, 0)) || isEqual(new Date(day.date).setHours(0, 0, 0, 0), new Date(employeeRole.startDate).setHours(0, 0, 0, 0))) &&
                                (isBefore(new Date(day.date).setHours(0, 0, 0, 0), new Date(employeeRole.endDate).setHours(0, 0, 0, 0)) || isEqual(new Date(day.date).setHours(0, 0, 0, 0), new Date(employeeRole.endDate).setHours(0, 0, 0, 0)));
                        });

                        totalRate += eRoleRate * (planificationPhaseProfileWorkDaysByRole?.length ?? 0);
                    }
                    );
                    profile.totalRate = totalRate;
                }
            }
        } else {
            const eRole = this.employeeRoles?.find((x) => x.id === profile.employeeRole?.id || x.label === profile.profileRole);
            profile.employeeRoleId = profile.employeeRole?.id;
            profile.totalRate = profile.planificationPhaseProfileWorkDays.length * (eRole?.rate ?? 0);
        }
        const phaseToUpdate = this.planificationPhases?.find(p => p.id === phase.id);
        if (phaseToUpdate) {
            const profileToUpdate = phaseToUpdate.planificationPhaseProfiles[index];
            if (profileToUpdate) {
                profileToUpdate.totalRate = profile.totalRate;
            }
        }
    }

    private dateDisabled(ymd, date: Date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        const weekday = date.getDay();
        // Return `true` if the date should be disabled
        return weekday === 0 || weekday === 6;
    }

    private async mounted(): Promise<void> {
        vxm.app.changeTitleMain("Planification d'affaires");
        this.currentMonth = this.defaultDate.getMonth();
        this.currentYear = this.defaultDate.getFullYear();
        await vxm.referential.fetchEmployeeRoles();
        if (this.selectedProjectId && this.selectedProjectId > 0) {
            await this.getPlanificationPhases(this.selectedProjectId);
            await this.checkIsStudioManger(this.selectedProjectId);
        }
        const studioCallData = await studioApi.getAllBase();

        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studios = studioCallData?.datas;
        }

        vxm.user.checkIsAssociate();
        vxm.user.checkIsAdmin();
        vxm.user.checkIsDaf();
        vxm.user.checkIsStudioManager();
        vxm.user.checkIsRH();
        vxm.user.checkIsCP();
    }

    setPhaseToUpdate(phaseId: number): void {
        if (this.planificationPhases && this.planificationPhases.length > 0 && phaseId != null) {
            const phase = this.planificationPhases.find((x) => x.id === phaseId);
            if (phase) {
                this.selectedPlanificationPhaseToUpdate = phase;
                (this.$refs['plan-modal'] as any).show();
            }
        }
    }
    
    private showValidation: boolean = false;
    private validationObject: Array<{ profil: boolean, studio: boolean }> = [];
    private validatePhases() {
        this.showValidation = false;
        this.validationObject = [];
        if (this.selectedPlanificationPhaseToUpdate != null && 
            this.selectedPlanificationPhaseToUpdate.planificationPhaseProfiles && 
            this.selectedPlanificationPhaseToUpdate.planificationPhaseProfiles.length > 0 &&
            !this.selectedPlanificationPhaseToUpdate.planificationPhaseProfiles.some(p => p.planificationPhaseProfileWorkDays.length === 0)
        ) {
            const validation: Array<{ profil: boolean, studio: boolean }> = [];
            this.selectedPlanificationPhaseToUpdate.planificationPhaseProfiles.forEach((x) => {
                if (x.employeeId != null && x.employeeId.length > 16) {
                    validation.push({ profil: false, studio: false });
                } else {
                    const obj = { profil: x.employeeRoleId == null, studio: x.studioId == null };
                    validation.push(obj);
                    if (obj.profil === true || obj.studio === true) {
                        this.showValidation = true;
                    }
                }
            });
            this.validationObject = validation;
        }
    }

    async savePhase(item: IPlanificationPhase): Promise <void> {
        this.validatePhases();
        if (this.showValidation === false) {
            await this.savePlanificationPhases();
            (this.$refs['plan-modal'] as any).hide();   
        }
    }

    isDateValid(item: any): boolean {
        return isDate(item) || item == null;
    }

    async getEmployeeName(employeeId: NU<string>): Promise <string> {
        const result = await moduleApiGraph.Client.api('/users/' + employeeId)
            .select('displayName')
            .get()
            .then((value) => {
                return value.displayName;
            });
        return result;
    }

    createDate(dateStr): any {
        if (dateStr === null || dateStr === '') return null;
        const parts = dateStr.split('/');
        return new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
    }

    onContextEndDate(ctx) {
        this.inputEndDate = ctx.selectedFormatted;
    }

    onContextStartDate(ctx) {
        this.inputStartDate = ctx.selectedFormatted;
    }
}
