
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IImputationDate } from './imputation-interfaces';

@Component({})
export default class ImputationCalendarSummaryDay extends Vue {
    @Prop({ required: true })
    private imputationSummaryDay!: IImputationDate;

    get imputationClass(): string {
        if (this.imputationSummaryDay.isInWorkflow) {
            return `imputation-calendar-summary-day ${this.imputationSummaryDay.error ? 'error' : 'fine'}`;
        }
        return 'imputation-calendar-summary-day deactivate';
    }
}
