
import { vxm } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IProject } from '@/entity/project/project';
import { projectApi } from '@/wapi/project-api';
import { NU } from '@t/type';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import Dashboard from './dashboard.vue';
import { moduleApiGraph } from '@t/module-api-graph';
import { appTokenMgr } from '@t/employee-app-role';
import { IBackgroundImage } from '@/entity/shared/background-image';

@Component({
    components: {
        Dashboard
    }
})
export default class Home extends Vue {
    totalProgressAmount: NU<number> = null;
    totalBudget: NU<number> = null;
    totalContractLinesPrice: NU<number> = null;
    image: string = '';

    projectData: NU<IProject> = null;

    @Watch('selectedProjectId')
    async fetchSelectedProjectData(newVal: number): Promise<void> {
        this.projectData = null;
        const request = await projectApi.getBase(newVal + '');
        if (isCallValidAndNotCancelled(request)) {
            this.projectData = request?.datas;
            const projectImageCallData = await projectApi.getBackgroundImageFromProject(newVal);
            if (isCallValidAndNotCancelled<IBackgroundImage>(projectImageCallData)) {
                if (request && request.datas) {
                    if ((projectImageCallData.datas as unknown) === '') {
                        projectImageCallData.datas = { id: 0, img: '', title: '', type: '' };
                    }
                    request.datas.backgroundImage = projectImageCallData.datas as IBackgroundImage;
                    this.loadBackground();
                }
            }
        }
    }

    private canSeeDashboard(): boolean {
        return appTokenMgr.isAdmin() || appTokenMgr.isCP() || appTokenMgr.isStudioManager();
    }

    private formatNumber(val: number | null): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(
            Number(val?.toFixed(0) ?? 0) ?? 0
        );
    }

    get projectLoading(): boolean {
        return vxm.project.projectLoading;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    get base64ToImg(): string {
        if (this.image) {
            return this.image;
        } else if (this.projectData && this.projectData.backgroundImage && this.projectData.backgroundImage.img) {
            return 'data:image/jpg;base64,' + this.projectData.backgroundImage.img;
        }
        return '';
    }

    async created(): Promise<void> {
        const graphReq = await moduleApiGraph.Client.api('/me').get();
        if (this.selectedProjectId) {
            this.fetchSelectedProjectData(this.selectedProjectId);
        }
        if (graphReq.givenName) {
            vxm.app.changeTitleMain(`Bonjour ${graphReq.givenName}`);
        }
    }

    async mounted(): Promise<void> {
        // this.loadBackground();
    }

    get getClient(): string {
        if (this.selectedProject && this.selectedProject.clients && this.selectedProject.clients.length > 0) {
            return this.selectedProject?.clients[0].label ?? '';
        }
        return '';
    }

    private loadBackground() {
        (
            document.getElementById('dashboard-main') as HTMLDivElement
        ).style.backgroundImage = `url('${this.base64ToImg}')`;
    }

    updateTotalProgressAmount(value: NU<number>): void {
        this.totalProgressAmount = value;
    }

    updateTotalBudget(value: NU<number>): void {
        this.totalBudget = value;
    }

    updateTotalContractLinesPrice(value: NU<number>): void {
        this.totalContractLinesPrice = value;
    }

    formatDate(date: string): string {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [day, month, year].join('/');
    }

    get selectedProject(): NU<IProject> {
        return this.projectData;
    }
}
