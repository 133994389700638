
import { IPlanificationPhaseProfileWorkDay } from '@/entity/planification/planification-phase-profile-work-day';
import { IPlanificationProfileScheduler } from '@/entity/planification/planification-profile-scheduler';
import { differenceInDays, isSameDay, isWeekend } from 'date-fns';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class CalendarRow extends Vue {
    @Prop({ required: true })
    item!: IPlanificationProfileScheduler;

    @Prop()
    currentFields!: any[];

    @Prop()
    selectedPeriod!: { item: IPlanificationProfileScheduler; startDate: Date; endDate: Date };

    @Prop({ required: true })
    fullDay!: boolean | null;

    @Prop({ required: true })
    oldFullDay!: boolean;

    @Prop({ required: true })
    isReadonly!: boolean;

    @Watch('fullDay')
    changeSelectionMode(newVal: boolean | null, oldVal: boolean | null) {
        if (
            newVal != null &&
            this.item != null &&
            this.selectedPeriod != null &&
            this.item === this.selectedPeriod.item
        ) {
            const date = new Date(this.selectedPeriod.startDate);
            while (date.setHours(0, 0, 0, 0) <= this.selectedPeriod.endDate.setHours(0, 0, 0, 0)) {
                this.$emit('markDate', new Date(date), newVal ? 3 : 1);
                date.setDate(date.getDate() + 1);
            }
            this.$emit('selectPeriod', undefined);
        }
    }

    isWorkDay(date: Date): boolean {
        let res = false;
        if (this.item.planificationPhaseProfileWorkDays && this.item.planificationPhaseProfileWorkDays.length > 0) {
            const parmasDate = new Date(date);
            res =
                this.item.planificationPhaseProfileWorkDays.filter((x) => {
                    const itemDate = new Date(x.date);
                    return (
                        itemDate.getFullYear() === parmasDate.getFullYear() &&
                        itemDate.getMonth() === parmasDate.getMonth() &&
                        itemDate.getDate() === parmasDate.getDate()
                    );
                }).length > 0;
        }
        return res;
    }

    getWorkDayBgColor(date: Date): string {
        // isMorning(item, field.key) && isAfternoon(item, field.key)
        //                                             ? '#2e942a'
        //                                             : isMorning(item, field.key) || isAfternoon(item, field.key)
        //                                             ? '#6ee13e'
        //                                             : '#FFFFFF'
        let res = '#FFFFFF';
        if (this.item.planificationPhaseProfileWorkDays && this.item.planificationPhaseProfileWorkDays.length > 0) {
            const parmasDate = new Date(date);
            const workDay = this.item.planificationPhaseProfileWorkDays.filter((x) => {
                const itemDate = new Date(x.date);
                return (
                    itemDate.getFullYear() === parmasDate.getFullYear() &&
                    itemDate.getMonth() === parmasDate.getMonth() &&
                    itemDate.getDate() === parmasDate.getDate()
                );
            });
            if (workDay && workDay.length > 0) {
                if (workDay.length === 1) {
                    // dans ce cas on n'a qu'une demi-journée
                    res = '#6ee13e';
                } else {
                    // ici on a forcément une journée complète, il y a 2 entrées
                    res = '#2e942a';
                }
            }
        }
        return res;
    }

    dragging: any = {};

    dragStart(field) {
        this.$emit('selectPeriod', undefined);
        this.dragging = {
            originDate: new Date(field.key),
            startDate: new Date(field.key),
            endDate: new Date(field.key),
            dayPartType: this.isWorkDay(new Date(field.key))
                ? 0
                : (this.fullDay == null ? this.oldFullDay : this.fullDay)
                ? 3
                : 1
        };
    }

    dragEnd(field) {
        if (this.dragging.startDate) {
            if (this.dragging.dayPartType != 0) {
                const date = new Date(this.dragging.startDate);

                while (date <= this.dragging.endDate) {
                    this.$emit('markDate', new Date(date), this.dragging.dayPartType);
                    date.setDate(date.getDate() + 1);
                }
                this.dragging = {};
            } else {
                this.selectDraggedPeriod(this.dragging.startDate, this.dragging.endDate);
                this.dragging = {};
            }
        }
    }

    timeout: any;
    dragOver(field): void {
        if (this.timeout) {
            return;
        }
        if (this.dragging.startDate) {
            const currentDate = new Date(field.key);

            if (differenceInDays(this.dragging.originDate, currentDate) <= 0) {
                this.dragging.startDate = this.dragging.originDate;
                this.dragging.endDate = currentDate;
            } else {
                this.dragging.startDate = currentDate;
                this.dragging.endDate = this.dragging.originDate;
            }
        }
        this.timeout = setTimeout(() => {
            this.timeout = null;
        }, 250);
    }

    isDragOver(date) {
        return (
            differenceInDays(this.dragging.startDate, new Date(date)) <= 0 &&
            differenceInDays(this.dragging.endDate, new Date(date)) >= 0
        );
    }

    click(field, event) {
        const d: Date = new Date(field.key);

        if (event.ctrlKey) {
            this.selectDraggedPeriod(d, d);
        } else {
            if (this.isWorkDay(d) && !this.isSelectedPeriod(field)) {
                this.selectPeriod(d);
            } else {
                this.$emit('selectPeriod', undefined);
            }
        }
    }

    isSelectedPeriod(field) {
        // debugger;
        return (
            this.selectedPeriod &&
            this.selectedPeriod.item === this.item &&
            differenceInDays(this.selectedPeriod.startDate, new Date(field.key)) <= 0 &&
            differenceInDays(this.selectedPeriod.endDate, new Date(field.key)) >= 0
        );
    }

    selectDraggedPeriod(startDragDate: Date, endDragDate: Date) {
        if (
            this.selectedPeriod &&
            this.selectedPeriod.item === this.item &&
            isSameDay(this.selectedPeriod.startDate, startDragDate) &&
            isSameDay(this.selectedPeriod.endDate, endDragDate)
        ) {
            this.$emit('selectPeriod', undefined);
        } else {
            this.$emit('selectPeriod', { item: this.item, startDate: startDragDate, endDate: endDragDate });
        }
    }

    selectPeriod(date: Date) {
        const days = this.item.planificationPhaseProfileWorkDays
            .map((x) => new Date(x.date))
            .sort((a, b) => a.getTime() - b.getTime());
        let index = days.findIndex((x) => isSameDay(x, date));
        let startDate = new Date(date);
        while (index > 0) {
            const d = new Date(days[index]);
            d.setDate(d.getDate() - 1);
            while (isWeekend(d)) d.setDate(d.getDate() - 1);
            while (index >= 0 && isSameDay(days[index], startDate)) index--;
            if (isSameDay(days[index], d)) {
                startDate = d;
            } else {
                break;
            }
        }

        let endDate = new Date(date);
        index = days.findIndex((x) => isSameDay(x, date));
        while (index < days.length) {
            const d = new Date(days[index]);
            d.setDate(d.getDate() + 1);
            while (isWeekend(d)) d.setDate(d.getDate() + 1);
            while (index < days.length && isSameDay(days[index], endDate)) index++;
            if (isSameDay(days[index], d)) {
                endDate = d;
            } else {
                break;
            }
        }
        this.selectDraggedPeriod(startDate, endDate);
        // if (
        //     this.selectedPeriod &&
        //     this.selectedPeriod.item === this.item &&
        //     isSameDay(this.selectedPeriod.startDate, startDate) &&
        //     isSameDay(this.selectedPeriod.endDate, endDate)
        // ) {
        //     this.$emit('selectPeriod', undefined);
        // } else {
        //     this.$emit('selectPeriod', { item: this.item, startDate, endDate });
        // }
    }

    isPhaseIntervaleTime(date: Date): boolean {
        let res = false;
        if (this.item && this.item.startDate && this.item.endDate) {
            let startDate = new Date(this.item.startDate);
            startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            let endDate = new Date(this.item.endDate);
            endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
            const parmasDate = new Date(date);
            res = startDate.getTime() <= parmasDate.getTime() && endDate.getTime() >= parmasDate.getTime();
        }

        return res;
    }

    isDayOfWeekData(date: Date): boolean {
        const paramsDate = new Date(date);
        const res = paramsDate.getDay();
        return res === 0 || res === 6;
    }

    getBgColor(date: Date): string {
        if (this.isDayOfWeekData(date)) {
            return '#CECECE';
        }
        if (this.isDragOver(date)) {
            return this.dragging.dayPartType !== 0
                ? (this.fullDay == null ? this.oldFullDay : this.fullDay)
                    ? '#2e942a'
                    : '#6ee13e'
                : '#cccccc';
        }
        return '';
    }
}
