
import { IContractLine } from '@/entity/contract/contract-line';
import { IProjectComment } from '@/entity/project/project-comment';
import { NU } from '@t/type';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CommentForm from './comment-form.vue';

@Component({
    components: {
        CommentForm
    }
})
export default class CommentItem extends Vue {
    @Prop({ required: true })
    private comment!: IProjectComment;

    @Prop({ required: true })
    private contractLine: NU<IContractLine>;

    @Prop({ required: true })
    private contractLines!: IContractLine[];

    private editingComment!: IProjectComment;

    created(): void {
        this.updateEditingComment(this.comment);
    }

    @Watch('comment')
    private updateEditingComment(newComment: IProjectComment) {
        this.editingComment = {
            ...newComment
        };
    }

    formatDate(date: Date): string {
        let minutes = '' + date.getMinutes();
        let hours = '' + date.getHours();
        let day = '' + date.getDate();
        let month = '' + (date.getMonth() + 1);
        const year = date.getFullYear();
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        if (month.length < 2) {
            month = '0' + month;
        }
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
    }

    editComment(): void {
        this.$emit('edit-comment', this.editingComment);
    }
}
