var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-row',{staticClass:"title"},[_c('b-col',{attrs:{"cols":"8"}},[_c('h4',{staticClass:"h-inline"},[_vm._v(" "+_vm._s(_vm.isCurrentUserInformations ? 'Mes réalisations de charrette' : 'Réalisations de charrette')+" ("+_vm._s(_vm.workflowRealizedOverTimeList && _vm.workflowRealizedOverTimeList.length > 0 ? _vm.workflowRealizedOverTimeList.length : 0)+") ")]),_c('h5',{staticClass:"h-inline",staticStyle:{"width":"fit-content"}},[_c('b-badge',{staticClass:"h-inline new-workflow badget-style"},[_vm._v(_vm._s(_vm.workflowRealizedOverTimeListToBeSubmitted.length)+" soumise(s) à validation")]),_c('b-badge',{staticClass:"h-inline in-progress-workflow badget-style"},[_vm._v(_vm._s(_vm.workflowRealizedOverTimeList.filter((x) => x.status === 0).length)+" en cours(s)")]),_c('b-badge',{staticClass:"h-inline valid-workflow badget-style"},[_vm._v(_vm._s(_vm.workflowRealizedOverTimeList.filter((x) => x.status === 2).length)+" validé(s)")]),_c('b-badge',{staticClass:"h-inline deny-workflow badget-style"},[_vm._v(_vm._s(_vm.workflowRealizedOverTimeList.filter((x) => x.status === -1).length)+" refusé(s)")])],1)]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('b-avatar',{staticClass:"align-center",staticStyle:{"margin-left":"30px","top":"-4px"},attrs:{"button":"","aria-expanded":_vm.isVisible ? 'true' : 'false',"aria-controls":"collapse-realized-over-time","variant":"all","icon":_vm.isVisible ? 'chevron-double-up' : 'chevron-double-down'},on:{"click":function($event){_vm.isVisible = !_vm.isVisible}}})],1)],1),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-realized-over-time"},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[(_vm.workflowRealizedOverTimeListToBeSubmitted && _vm.workflowRealizedOverTimeListToBeSubmitted.length > 0)?_c('div',{staticClass:"user-workflows-grid mt-3",style:({
                'grid-template-rows':
                    'repeat(' + (_vm.workflowRealizedOverTimeListToBeSubmitted.length / 5 + 1) + ',auto)'
            })},_vm._l((_vm.workflowRealizedOverTimeListToBeSubmitted),function(workflow,index){return _c('div',{key:workflow.id,staticClass:"user-workflow-block",class:{
                    'grid-column1': index % 5 === 0,
                    'grid-column2': index % 5 === 1,
                    'grid-column3': index % 5 === 2,
                    'grid-column4': index % 5 === 3,
                    'grid-column5': index % 5 === 4
                },style:({ 'grid-row': 1 + 4 * parseInt(index / 5) + '/' + (5 + 4 * parseInt(index / 5)) })},[_c('div',{staticClass:"new-workflow",staticStyle:{"line-height":"40px","position":"relative"}},[_vm._v(" Charrette #"+_vm._s(workflow.id)+" "),_c('b',[_vm._v("Soumise à validation...")]),_c('b-avatar',{staticClass:"align-center",staticStyle:{"position":"absolute","right":"10px","top":"5px"},attrs:{"variant":"outline-all","button":"","icon":"box-arrow-in-right"},on:{"click":function($event){return _vm.consultRealizedOverTime(workflow.id)}}})],1),_c('div',[_vm._v(" entre le "),_c('b',[_vm._v(_vm._s(_vm.getFormattedDate(workflow.betweenMin)))])]),_c('div',[_vm._v(" et le "),_c('b',[_vm._v(_vm._s(_vm.getFormattedDate(workflow.betweenMax)))])]),_c('div',[_c('b',[_vm._v(_vm._s(workflow.total))]),_vm._v(" heures ")])])}),0):_vm._e(),(_vm.workflowRealizedOverTimeListSubmitted && _vm.workflowRealizedOverTimeListSubmitted.length > 0)?_c('div',{staticClass:"user-workflows-grid mt-3",style:({
                'grid-template-rows': 'repeat(' + (_vm.workflowRealizedOverTimeListSubmitted.length / 5 + 1) + ',auto)'
            })},_vm._l((_vm.workflowRealizedOverTimeListSubmitted),function(workflow,index){return _c('div',{key:workflow.id,staticClass:"user-workflow-block",class:{
                    'grid-column1': index % 5 === 0,
                    'grid-column2': index % 5 === 1,
                    'grid-column3': index % 5 === 2,
                    'grid-column4': index % 5 === 3,
                    'grid-column5': index % 5 === 4
                },style:({ 'grid-row': 1 + 4 * parseInt(index / 5) + '/' + (5 + 4 * parseInt(index / 5)) })},[_c('div',{class:{
                        'valid-workflow': workflow.status === 2,
                        'in-progress-workflow': workflow.status === 0,
                        'deny-workflow': workflow.status === -1
                    },staticStyle:{"line-height":"40px","position":"relative"}},[_vm._v(" Charrette #"+_vm._s(workflow.id)+" "),_c('b',[_vm._v(_vm._s(_vm.transformToStatus(workflow.status)))]),_c('b-avatar',{staticClass:"align-center",staticStyle:{"position":"absolute","right":"10px","top":"5px"},attrs:{"variant":"outline-all","button":"","icon":"box-arrow-in-right"},on:{"click":function($event){return _vm.consultRealizedOverTime(workflow.id)}}})],1),_c('div',[_vm._v(" entre le "),_c('b',[_vm._v(_vm._s(_vm.getFormattedDate(workflow.betweenMin)))])]),_c('div',[_vm._v(" et le "),_c('b',[_vm._v(_vm._s(_vm.getFormattedDate(workflow.betweenMax)))])]),_c('div',[_c('b',[_vm._v(_vm._s(workflow.total))]),_vm._v(" heures ")])])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }