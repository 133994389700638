
import { MgtPerson } from '@microsoft/mgt';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { User } from '@microsoft/microsoft-graph-types/microsoft-graph';
import { moduleApiGraph } from '@t/module-api-graph';

@Component
export default class CustomMgtPerson extends Vue {
    @Prop({ required: false, default: null }) userId!: string | null;
    @Prop({ required: false, default: null }) user!: User | null;
    @Prop({ required: false, default: null }) studioManagerId!: string | null;
    @Prop({ required: false, default: null }) creationUserId!: string | null;
    @Prop({ required: false, default: 'oneLine' }) mgtView!: 'oneline' | 'twolines' | 'threelines' | 'fourlines';

    public currentUser: User | null = null;
    public transformedUser: User | null = null;

    @Watch('currentUser') onCurrentUserAssigned(newval: User | null) {
        if (newval == null) {
            this.transformedUser = null;
            return;
        }
        const u = { ...newval, displayName: this.currentUser?.givenName != null && this.currentUser?.surname != null ? `${this.currentUser?.givenName} ${this.currentUser?.surname}` : this.currentUser?.displayName };
        this.transformedUser = u;
    } 

    async created() {
        if (this.userId == null && this.user == null) {
            throw new Error('Both user and userid are null');
        }
        if (this.user != null) {
            this.currentUser = this.user;
        } else {
            const gettedUser = await moduleApiGraph.Client.api(`/users/${this.userId}?$select=id,displayName,surname,givenName,userType`).get();
            if (gettedUser != null) {
                this.currentUser = gettedUser;
            }
        }
    }
}
