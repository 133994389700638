
import { Component, Prop, Watch } from 'vue-property-decorator';
import RHBaseClass from '../rh-class-base.vue';
import { vxm } from '@/store';
import { workflowApi } from '@api/workflow-api';
import { realizedOverTimeApi } from '@api/realized-over-time-api';
import { IRealizedOverTimeValidate, IRealizedOverTimeWorkflowItem, IWorkflow } from '@/entity/rh/workflow';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
import { authModule } from '@t/session';
import { moduleApiGraph } from '@t/module-api-graph';
import { format, max, min } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { IProject } from '@/entity/project/project';
import { projectApi } from '@/wapi/project-api';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { contractApi } from '@/wapi/contract-api';

interface IUnique {
    id?: string;
    uniqueValue: number;
    defaultId?: string;
    comment?: string;
    validAction: number;
}

@Component({
    components: {
        InputElementMgt
    }
})
export default class RealizedOverTimeInformation extends RHBaseClass {
    @Prop({}) realizedOverTimeId!: number;
    private currentWorkflow: IWorkflow<IRealizedOverTimeWorkflowItem> = {} as IWorkflow<IRealizedOverTimeWorkflowItem>;
    private validators: Array<IUnique> = [];
    private realizedOverTimes: Array<IRealizedOverTimeWorkflowItem> = [];
    private projects: ISelectListOption[] = [];
    private isSetValidators: boolean = true;
    private uniqueValidatorValue: number = 1;
    private contractLines: ISelectListOption[] = [];

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Projet', key: 'projectId', thStyle: 'text-align: center;min-width:200px' },
        { label: 'Phase', key: 'contractLineId', thStyle: 'text-align: center;min-width:150px' },
        { label: 'Date de début', key: 'fromDate', thStyle: 'text-align: center;min-width:150px' },
        { label: 'Date de fin', key: 'toDate', thStyle: 'text-align: center;min-width:150px' },
        { label: 'Heures / jour', key: 'hoursPerDay', thStyle: 'text-align: center;min-width:100px' },
        { label: 'Heures totales', key: 'TotalesHous', thStyle: 'text-align: center;min-width:100px' },

    ];

    getTotalHours(item: any) {
        const overTimeElement = item.item as IRealizedOverTimeWorkflowItem;
        if (overTimeElement.toDate && overTimeElement.fromDate && overTimeElement.hoursPerDay) {
            const t =
                overTimeElement.hoursPerDay * this.getBusinessDays(overTimeElement.fromDate, overTimeElement.toDate);
            return t;
        }
        return 0;
    }

    getBusinessDays(startDate, endDate): number {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const daysBetween = Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;
        return daysBetween;
    }

    get isValidationPage(): boolean {
        return this.$router.currentRoute.name === 'realized-over-time-information-validation';
    }

    get isValidRealizedOverTime(): boolean {
        let res = true;
        res = res && this.isSetValidators;
        res = res && this.realizedOverTimes && this.realizedOverTimes.length > 0;
        this.realizedOverTimes.forEach((element) => {
            res = res && element.projectId !== undefined && element.projectId !== null && element.projectId !== 0;
            res = res && element.fromDate !== undefined && element.fromDate !== null;
            res = res && element.toDate !== undefined && element.toDate !== null;
        });
        return res;
    }

    get hasBeenValidated(): boolean {
        const currentValidator = this.validators.find((x) => x.defaultId === this.currentUserId);
        return currentValidator?.validAction !== 0;
    }

    get formatedRealizedOverTimeDate(): string {
        if (this.currentWorkflow?.elements) {
            const fromDates = this.currentWorkflow.elements.map((x) => {
                return new Date(x.fromDate);
            });
            const toDates = this.currentWorkflow.elements.map((x) => {
                return new Date(x.toDate);
            });
            if (fromDates && fromDates.length > 0 && toDates && toDates.length > 0) {
                return `Du ${
                    format(new Date(String(min(fromDates))), 'EEEE dd MMMM yyyy', {
                        locale: frenchLocale
                    }) ?? ''
                } au ${
                    format(new Date(String(max(toDates))), 'EEEE dd MMMM yyyy', {
                        locale: frenchLocale
                    }) ?? ''
                }
                `;
            }
        }
        return '';
    }

    get currentUserId(): string {
        return authModule.getAccount()!.localAccountId!.toUpperCase();
    }

    get isSubmittedRealizedOverTimePage(): boolean {
        return (
            this.currentWorkflow &&
            this.currentWorkflow.elements &&
            this.currentWorkflow.elements.find((x) => x.isSubmitted === true) === undefined
        );
    }

    private checkValidators(): void {
        let res = true;
        this.validators.forEach((element) => {
            res = res && element.defaultId !== null && element.defaultId !== undefined;
        });
        this.isSetValidators = res;
    }

    private async getContractLinesByProject(projectId) {
        var res = await contractApi.getContractLinesByProjectId(projectId);
        if (isCallValidAndNotCancelled(res)) {
            this.contractLines =
                res?.datas?.map((x) => {
                    return {
                        id: x.id,
                        code: x.designation,
                        label: x.designation
                    } as ISelectListOption;
                }) ?? [];
        }
    }

    @Watch('realizedOverTimeId')
    async onRealizedOverTimeIdChange(newVal: number, _oldVal: number): Promise<void> {
        (this.currentWorkflow as unknown) = null;
        this.validators.splice(0, this.validators.length);
        if (newVal && newVal > 0) {
            const res = await workflowApi.getRealizedOverTimeWorkflowById(this.id, this.realizedOverTimeId);
            if (isCallValidAndNotCancelled(res)) {
                const workflow = res.datas as IWorkflow<IRealizedOverTimeWorkflowItem>;
                this.validators = workflow.validators.map((x) => {
                    return {
                        uniqueValue: this.uniqueValidatorValue++,
                        defaultId: x.employeeId,
                        validAction: x.workflowValidatorEvent?.status ?? 0,
                        comment: x.workflowValidatorEvent?.comment
                    } as IUnique;
                });
                this.realizedOverTimes = workflow.elements;
                this.getContractLinesByProject(this.realizedOverTimes[0].projectId)
                this.currentWorkflow = workflow;
            }
        }
        this.checkValidators();
        this.setPageTitle();
        const projectCallData = await projectApi.getAllBase();

        if (isCallValidAndNotCancelled<IProject[]>(projectCallData)) {
            this.projects =
                projectCallData?.datas?.map((x) => {
                    return {
                        id: x.id,
                        code: x.trigram,
                        label: x.designation
                    } as ISelectListOption;
                }) ?? [];
        }
    }

    private async validate(): Promise<void> {
        const validateObject: IRealizedOverTimeValidate = {} as IRealizedOverTimeValidate;
        validateObject.workflowId = this.currentWorkflow.id;
        validateObject.comment = this.validators.find((x) => x.defaultId === this.currentUserId)?.comment;
        validateObject.validate = true;
        const res = await realizedOverTimeApi.validateRealizedOverTimeWorkflow(validateObject, this.id);
        if (isCallValidAndNotCancelled(res)) {
            await this.onRealizedOverTimeIdChange(this.currentWorkflow.id, 0);
            this.$bvToast.toast('Validation effectué avec succès', {
                title: 'Soumettre de Charrette',
                variant: 'success',
                solid: true
            });
        }
    }

    private async deny(): Promise<void> {
        const validateObject: IRealizedOverTimeValidate = {} as IRealizedOverTimeValidate;
        validateObject.workflowId = this.currentWorkflow.id;
        validateObject.comment = this.validators.find((x) => x.defaultId === this.currentUserId)?.comment;
        validateObject.validate = false;
        const res = await realizedOverTimeApi.validateRealizedOverTimeWorkflow(validateObject, this.id);
        if (isCallValidAndNotCancelled(res)) {
            await this.onRealizedOverTimeIdChange(this.currentWorkflow.id, 0);
            this.$bvToast.toast('Refus effectué avec succès', {
                title: 'Soumettre de Charrette',
                variant: 'success',
                solid: true
            });
        }
    }

    selectionChanged(event: CustomEvent, item: IUnique): void {
        if (event && event.detail) {
            item.defaultId = event.detail[0]?.id;
            item.id = event.detail[0]?.id;
            this.checkValidators();
        }
    }

    async created(): Promise<void> {
        this.setPageTitle();
    }

    private async setPageTitle(): Promise<void> {
        const employeeIdentifier = authModule.getAccount()!.localAccountId!;
        vxm.app.changeTitleMain('Soumettre de Charrette');
        if (employeeIdentifier === this.id) {
            if (this.realizedOverTimeId > 0) {
                await vxm.app.changeTitleExt(`Votre demande (identifiant n°${this.realizedOverTimeId})`);
            } else {
                await vxm.app.changeTitleExt('Créer une nouvelle demande');
            }
        } else {
            const currentUserData = await moduleApiGraph.Client.api(`/users/${this.id}`).get();
            const prevName = currentUserData ? currentUserData.displayName + ' > ' : '';
            if (this.realizedOverTimeId > 0) {
                vxm.app.changeTitleExt(prevName + `demande (identifiant n°${this.realizedOverTimeId})`);
            } else {
                vxm.app.changeTitleExt(prevName + 'Créer une nouvelle demande');
            }
        }
    }

    async mounted(): Promise<void> {
        await this.onRealizedOverTimeIdChange(this.realizedOverTimeId, this.realizedOverTimeId);
    }

    private backToProfile() {
        this.$router.push({ name: 'user-information', params: { id: this.id as string } });
    }

    private backToCurrentUserProfile() {
        this.$router.push({
            name: 'user-information',
            params: { id: authModule.getAccount()!.localAccountId as string }
        });
    }

    async saveRealizedOverTime(): Promise<void> {
        this.currentWorkflow.elements = this.realizedOverTimes;
        if (this.currentWorkflow.id && this.currentWorkflow.id !== 0) {
            const res = await realizedOverTimeApi.updateRealizedOverTimeWorkflow(this.currentWorkflow, this.id);
            if (isCallValidAndNotCancelled(res)) {
                const res = await workflowApi.getRealizedOverTimeWorkflowById(this.id, this.realizedOverTimeId);
                if (isCallValidAndNotCancelled(res)) {
                    const workflow = res.datas as IWorkflow<IRealizedOverTimeWorkflowItem>;
                    this.validators = workflow.validators.map((x) => {
                        return {
                            uniqueValue: this.uniqueValidatorValue++,
                            defaultId: x.employeeId,
                            validAction: x.workflowValidatorEvent?.status ?? 0,
                            comment: x.workflowValidatorEvent?.comment
                        } as IUnique;
                    });
                    this.realizedOverTimes = workflow.elements;
                    this.currentWorkflow = workflow;
                }
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: 'Soumettre de Charrette',
                    variant: 'success',
                    solid: true
                });
                this.backToCurrentUserProfile();
            }
        }
    }
    //
}
