
import { Component, Prop, Vue } from 'vue-property-decorator';
import { pdf } from '@/wapi/pdf-api';
import feePdfCard from './fee-pdf-card.vue';
import feePdfPage from './fee-pdf-page.vue';
import { IFeeExtention } from '@/entity/fee/fee';
import { IPdfGenerationItem } from '@/entity/pdf/pdf-generation-item';
import { ISignature } from '@/entity/pdf/pdf-signature';
import { employeeApi } from '@/wapi/employee-api';
import { User } from '@microsoft/microsoft-graph-types/microsoft-graph';
import { moduleApiGraph } from '@t/module-api-graph';
import { IEmployee } from '@/entity/shared/employee';
import { IUser } from '@/entity/shared/user';
import { projectApi } from '@/wapi/project-api';
import { contractApi } from '@/wapi/contract-api';
import { IObjectShort } from '@/entity/shared/object-short';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';

@Component({
    components: {
        feePdfCard,
        feePdfPage
    }
})
export default class FeePdf extends Vue {
    @Prop({ required: true }) id!: string;
    @Prop({ required: true }) fees!: string;

    private generatingPdf: boolean = false;
    private userInfo: IUser = {} as IUser;
    private employee: IEmployee = {} as IEmployee;
    private phases: IObjectShort[] = [];
    private projects: IObjectShort[] = [];

    private selectedSignature: ISignature | null = null;
    private setSelectedSignature(signature: ISignature | null) {
        this.selectedSignature = signature;
    }

    private currentDate(): string {
        return format(new Date(), 'd MMMM Y', { locale: frenchLocale });
    }

    private async generatePdf() {
        this.generatingPdf = true;
        const page = this.$refs['pdf-page'];

        let styles = '<style type="text/css">';
        // We add styles from page stylesheet
        for (let i = 0; i < document.styleSheets.length; ++i) {
            const styleSheet = document.styleSheets.item(i);
            if (styleSheet && styleSheet.rules) {
                for (let j = 0; j < styleSheet.rules.length; ++j) {
                    styles += styleSheet.rules[j].cssText;
                }
            }
        }
        styles += '</style>';

        const params: IPdfGenerationItem[] = [
            {
                html: `<!DOCTYPE html><html><head><meta charset="UTF-8">${styles}</head><body>${
                    (page as Vue).$el.innerHTML
                }</body>`,
                orientation: 'Portrait'
            }
        ];
       
        const response = await pdf.downloadNdfPdf(params);
        
        if (response && response.datas) {
            const blob = new Blob([response.datas], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = 'Note de frais_' + this.userInfo?.fullName + '.pdf';
            link.click();
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.generatingPdf = false;
        }, 1000);
    }

    private goBack() {
        this.$router.push({ name: 'effective-fee-information' });
    }

    get selectedFees(): IFeeExtention[] {
        const fees = JSON.parse(this.fees) as IFeeExtention[];
        const projectIndex: any = {};
        const phaseIndex: any = {};
        fees.forEach(element => {
            if (!projectIndex[element.projectId]) {
                const proj = this.projects.find(el => el.id === element.projectId);
                if (proj) projectIndex[element.projectId] = proj.designation;
            }
            element.projectDesignation = projectIndex[element.projectId] as string;
            if (!phaseIndex[element.contractLineId]) {
                const phase = this.phases.find(el => el.id === element.contractLineId);
                if (phase) phaseIndex[element.contractLineId] = phase.designation;
            }
            element.contractLineDesignation = phaseIndex[element.contractLineId] as string;
            element.taxAmount = element.fees - (element.fees / (1 + (element.tax ? element.tax / 100 : 0)));
        });
        return fees;
    }

    async getProjectAndPhasesMapping(): Promise<void> {
        const projectIdList: Array<number> = [];
        const contractLineIdList: Array<number> = [];
        this.selectedFees?.forEach(element => {
            projectIdList.push(element.projectId);
            contractLineIdList.push(element.contractLineId);
        });
        const projectCall = await projectApi.getProjectIdList(projectIdList);
        const cLCall = await contractApi.getContractLineIdList(contractLineIdList);
        if (projectCall != null && projectCall.cancelled === false && !projectCall.error && projectCall.datas != null) {
            this.projects = projectCall.datas;
        }
        if (cLCall != null && cLCall.cancelled === false && !cLCall.error && cLCall.datas != null) {
            this.phases = cLCall.datas;
        }
    }

    async getUser(): Promise<void> {
        const reqUser: string = `/users/${this.id}?$select=id,businessPhones,displayName,surname,givenName,jobTitle,userType`;
        const user: User = (await moduleApiGraph.Client.api(reqUser as string).get()) as User;
        this.userInfo = {
            id: user.id,
            businessPhones: user.businessPhones,
            fullName: user.displayName,
            lastName: user.surname,
            firstName: user.givenName,
            jobTitle: user.jobTitle,
            userType: user.userType
        } as IUser;
    }

    async mounted(): Promise<void> {
        await this.getProjectAndPhasesMapping();
        await this.getUser();
        const call = await employeeApi.getBase(this.id);
        if (call != null && call.cancelled === false && !call.error && call.datas != null) {
            this.employee = call.datas;
        }
    }
}
