
import { IContractLineWithProjectInfos } from '@/entity/contract/contract-line';
import { IImputationWorkflowItem } from '@/entity/rh/workflow';
import { IReferential } from '@/entity/shared/referential';
import { NU } from '@t/type';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ImputationGridHeader from './imputation-grid-header.vue';
import ImputationGridLine from './imputation-grid-line.vue';
import ImputationGridRecap from './imputation-grid-recap.vue';
import { ITeleworkingData, ITeleworkingSelection } from './imputation-interfaces';
import { ILeaveEmployee } from '@/entity/leave/leaveEmployee';

@Component({
    components: {
        ImputationGridHeader,
        ImputationGridRecap,
        ImputationGridLine
    }
})
export default class ImputationGrid extends Vue {
    @Prop({ required: true })
    private selectedMonth!: Date;

    @Prop({ required: true })
    private contractLineInWorkflow!: NU<IContractLineWithProjectInfos[]>;

    @Prop({ required: true })
    private leavesCurrentMonth!: NU<ILeaveEmployee[]>;

    @Prop({ required: true })
    private dayPartTypes!: IReferential[];

    @Prop({ required: true })
    private daysOfSelectedMonth!: string[];

    @Prop({ required: true })
    private teleworkingList!: ITeleworkingData;

    private nameWeekDay: string[] = ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'];
    private cellsToDisplay: string[] = [];
    private numberToDisplay: number[] = [];

    get teleworking(): ITeleworkingData {
        return this.teleworkingList;
    }

    // Gestion Erreurs et Sommes
    private sums: number[] = [];
    private error: boolean[] = [];

    created(): void {
        this.updateCellsToDisplay();
        this.updateSumContractLineEvent();
    }

    @Watch('contractLineInWorkflow')
    updateDisplayDaysInfo(): void {
        this.updateCellsToDisplay();
        this.updateSumContractLineEvent();
    }

    updatedTeleworkingDay(parameters: any): void {
        this.$emit('teleworking-update', parameters);
    }

    updateSumContractLineEvent(): void {
        const date = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1);
        let dayImputations: Array<IImputationWorkflowItem[]> = [];
        this.sums = [];
        this.error = [];
        let sumsWeek: number[] = [];
        const sumAllDays: number[] = [];
        const errorAllDays: boolean[] = [];
        const sumsMonth: number[] = [];
        let sum: number;
        let sumMor: number = 0;
        let sumAft: number = 0;
        let sumCha: number = 0;
        while (date.getMonth() === this.selectedMonth.getMonth()) {
            sum = 0;
            sumMor = 0;
            sumAft = 0;
            sumCha = 0;
            dayImputations = this.getImputationDayInContractLines(date);
            dayImputations?.forEach((imputation) => {
                imputation?.forEach((element) => {
                    switch (element.dayPartTypeId) {
                        case 1:
                            sumMor += element.value;
                            break;
                        case 2:
                            sumAft += element.value;
                            break;
                        case 3:
                            sumCha += element.value;
                            break;
                        default:
                            break;
                    }
                });
            });
            sum = sumMor + sumAft + sumCha;
            if (sumMor > 1 || sumAft > 1 || sum > 4) {
                this.error.push(true);
                errorAllDays.push(true);
            } else {
                this.error.push(false);
                errorAllDays.push(false);
            }
            this.sums.push(sumMor + sumAft + sumCha);
            sumAllDays.push(sum);
            sumsWeek.push(sum);
            if (date.getDay() === 0) {
                this.calculateSumWeekSum(sumsWeek, sumsMonth);
                sumsWeek = [];
            }
            date.setDate(date.getDate() + 1);
            if (date.getMonth() !== this.selectedMonth.getMonth() && date.getDay() !== 1) {
                this.calculateSumWeekSum(sumsWeek, sumsMonth);
                sumsWeek = [];
            }
        }
        sum = 0;
        sumsMonth.forEach((element) => {
            sum += element;
        });
        this.error.push(false);
        this.sums.push(sum);
        this.$emit('is-submitable', this.error);
        this.$emit('sums', sumAllDays);
        this.$emit('errors', errorAllDays);
    }

    deleteLineInContractLines(val: IContractLineWithProjectInfos): void {
        const morPartDay = this.dayPartTypes.find((x) => x.code === 'MOR')!;
        const aftPartDay = this.dayPartTypes.find((x) => x.code === 'AFT')!;
        const chaPartDay = this.dayPartTypes.find((x) => x.code === 'CHA')!;
        const index = this.contractLineInWorkflow!.indexOf(val, 0);
        if (index > -1) {
            const contractLine = this.contractLineInWorkflow![index];
            contractLine.imputations = [];
            for (let j = 0; j < this.daysOfSelectedMonth.length; j++) {
                const day = Number(this.daysOfSelectedMonth[j]);
                contractLine.imputations[day - 1] = [
                    {
                        workflowId: 0,
                        dayPartTypeId: morPartDay.id,
                        dayPartType: morPartDay,
                        value: 0,
                        date: new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), day, 3, 0, 0),
                        contractLineId: contractLine.id,
                        id: 0
                    },
                    {
                        workflowId: 0,
                        dayPartTypeId: aftPartDay.id,
                        dayPartType: aftPartDay,
                        value: 0,
                        date: new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), day, 3, 0, 0),
                        contractLineId: contractLine.id,
                        id: 0
                    },
                    {
                        workflowId: 0,
                        dayPartTypeId: chaPartDay.id,
                        dayPartType: chaPartDay,
                        value: 0,
                        date: new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), day, 3, 0, 0),
                        contractLineId: contractLine.id,
                        id: 0
                    }
                ];
            }
            this.contractLineInWorkflow![index] = contractLine;
            this.contractLineInWorkflow?.splice(index, 1);
        }
    }

    calculateSumWeekSum(sumsWeek: number[], sumsMonth: number[]): void {
        let sum: number = 0;
        sumsWeek.forEach((element) => {
            sum += element;
        });
        this.sums.push(sum);
        this.error.push(false);
        sumsMonth.push(sum);
    }

    getImputationDayInContractLines(date: Date): Array<IImputationWorkflowItem[]> {
        const imputationsToSum: Array<IImputationWorkflowItem[]> = [];
        if (this.contractLineInWorkflow?.length !== 0) {
            this.contractLineInWorkflow?.forEach((element) => {
                imputationsToSum.push(element.imputations![date.getDate() - 1]);
            });
        }
        return imputationsToSum;
    }

    updateCellsToDisplay(): void {
        const date = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1);
        this.cellsToDisplay = [];
        this.numberToDisplay = [];
        while (date.getMonth() === this.selectedMonth.getMonth()) {
            this.cellsToDisplay.push(this.nameWeekDay[date.getDay()]);
            this.numberToDisplay.push(date.getDate());
            if (date.getDay() === 0) {
                this.pushWeekInfo(date);
            }
            date.setDate(date.getDate() + 1);
            if (date.getMonth() !== this.selectedMonth.getMonth() && date.getDay() !== 1) {
                this.pushWeekInfo(date);
            }
        }
    }

    pushWeekInfo(date: Date): void {
        const oneJan = new Date(date.getFullYear(), 0, 1);
        const numberOfDays = Math.floor(((date as any) - (oneJan as any)) / (24 * 60 * 60 * 1000));
        const result = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
        this.cellsToDisplay.push('S' + result);
        this.numberToDisplay.push(-1);
    }
}
