
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IThirdParty } from '@/entity/shared/third-party';

@Component({
    
})

export default class ContactGroupForList extends Vue {
    @Prop({ required: true }) private thirdParty!: IThirdParty;

    private groupId: string = '';

    private async mounted(): Promise<void> {
        /// A ENLEVER LES COMMENTAIRES lorsqu'il sera possible et utile d'utiliser Graph d'azure AD 
        // this.groupId = await this.getAdGroupId(this.thirdParty.label as string);                
    }
}
