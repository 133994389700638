import { ILeave, ILeaveValidate, ILeaveWorkflow } from '@/entity/rh/workflow';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';


class LeaveApi extends ApiBase<ILeave> {
    constructor(urlBase: string = 'api/users') {
        super(urlBase);
    }

    public async createLeaveWorkflow(workflow: ILeave, userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number, ILeave>('post', this.url, {
            payload: workflow,
            urlParameter: [userId, 'workflowsleave']
        });
    }

    public async validateLeaveWorkflow(leaveValidate: ILeaveValidate, userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number, ILeaveValidate>('post', this.url, {
            payload: leaveValidate,
            urlParameter: [userId, 'workflowsleave', 'validate']
        });
    }

    public async updateLeaveWorkflow(workflow: ILeave, userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number, ILeave>('patch', this.url, {
            payload: workflow,
            urlParameter: [userId, 'workflowsleave', workflow.id + '']
        });
    }
}

export const leaveApi: LeaveApi = new LeaveApi();
