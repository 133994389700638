import { IOverTimeValidate, IOverTimeWorkflow } from '@/entity/rh/workflow';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';

class OverTimeApi extends ApiBase<IOverTimeWorkflow> {
    constructor(urlBase: string = 'api/users') {
        super(urlBase);
    }

    public async createOverTimeWorkflow(workflow: IOverTimeWorkflow, userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number, IOverTimeWorkflow>('post', this.url, {
            payload: workflow,
            urlParameter: [userId, 'workflowsovertime']
        });
    }

    public async validateOverTimeWorkflow(overTimeValidate: IOverTimeValidate, userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number, IOverTimeValidate>('post', this.url, {
            payload: overTimeValidate,
            urlParameter: [userId, 'workflowsovertime', 'validate']
        });
    }
}

export const overTimeApi: OverTimeApi = new OverTimeApi();
