import { render, staticRenderFns } from "./imputation-grid-header.vue?vue&type=template&id=22616006&"
import script from "./imputation-grid-header.vue?vue&type=script&lang=ts&"
export * from "./imputation-grid-header.vue?vue&type=script&lang=ts&"
import style0 from "./imputation-grid-header.vue?vue&type=style&index=0&id=22616006&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports