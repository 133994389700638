import { IPdfGenerationItem, IPdfGlobalParams } from '@/entity/pdf/pdf-generation-item';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

class PdfApi {
    url: string = 'api/pdf';

    public async downloadPdf(
        htmlPages: IPdfGlobalParams
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IPdfGlobalParams>('post', this.url, {
            payload: htmlPages,
            responseType: 'blob' 
        });
    }

    public async downloadNdfPdf(
        htmlPages: IPdfGenerationItem[]
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IPdfGenerationItem[]>('post', this.url, {
            payload: htmlPages,
            urlParameter: ['downloadNdfPdf'],
            responseType: 'blob' 
        });
    }
}

export const pdf = new PdfApi();
