
import { Component, Vue, Watch } from 'vue-property-decorator';

import { subcontractApi } from '@/wapi/subcontract-api';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { IProject } from '@/entity/project/project';
import { NU } from '@t/type';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { vxm } from '@/store';
import { ISubcontract } from '@/entity/subcontracting/subcontract';
import { IContractLine } from '@/entity/contract/contract-line';
import { contractApi } from '@/wapi/contract-api';
import CurrencyInput from '@c/shared/currency-input.vue';

@Component({
    components: {
        CurrencyInput
    }
})
export default class SubcontractingList extends Vue {
    subcontracts: NU<ISubcontract[]> = [];
    contractLinesList: NU<ISelectListOption[]> = [];
    private promiseExport: boolean = false;
    private isBusy: boolean = true;

    get selectedProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    get formatedOpeningDate(): string {
        if (this.selectedProject?.openingDate) {
            return (
                format(new Date(String(this.selectedProject?.openingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    get formatedClosingDate(): string {
        if (this.selectedProject?.closingDate) {
            return (
                format(new Date(String(this.selectedProject?.closingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    get isValidForm(): boolean {
        let res = true;
        this.subcontracts?.forEach((element) => {
            res = res && element.contractLineId !== undefined && element.contractLineId !== 0;
            res = res && element.subcontractor !== undefined && element.subcontractor !== '';
            res = res && element.date !== undefined;
        });
        return res;
    }

    get isReadonly(): boolean {
        return (vxm.user.isAssociate || vxm.user.isDaf) && !vxm.user.isAdmin && !vxm.user.isCP && !vxm.user.isStudioManager && !vxm.user.isRH
    }

    @Watch('selectedProjectId')
    async assigneProject(newVal: number, _oldVal: number): Promise<void> {
        if (newVal && newVal > 0) {
            await this.getSubContractings(newVal);
            const contractLineCallData = await contractApi.getAllContractLinesByProjectId(newVal + '');
            if (isCallValidAndNotCancelled<IContractLine[] | null>(contractLineCallData)) {
                if (contractLineCallData?.datas) {
                    this.contractLinesList = contractLineCallData.datas.map((x, index) => {
                        const contractLine: ISelectListOption = {
                            id: x.id,
                            code: 'CO' + index,
                            label: x.designation ?? ''
                        };
                        return contractLine;
                    });
                }
            }
        }
    }

    async getSubContractings(projectId: number): Promise<void> {
        const subcontractCallData = await subcontractApi.getAllSubcontractsByProjectId(projectId);
        if (isCallValidAndNotCancelled<ISubcontract[]>(subcontractCallData)) {
            if (subcontractCallData?.datas) {
                this.subcontracts = subcontractCallData.datas;
            }
        }
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Date commande', key: 'date' },
        { label: 'Mission', key: 'contractLineId', thStyle: 'min-width:230px' },
        { label: 'Sous-traitant', key: 'subcontractor' },
        { label: 'Montant HT (€)', key: 'amountTaxFree', thStyle: 'width:200px; text-align:right', formatter: (val: number) => (val === 0 || val == null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)) },
        { label: 'Commentaire', key: 'comment', thStyle: 'min-width:230px' },
        { label: '', key: 'delete', thStyle: 'width:80px' },
        { label: 'Commande passée ?', key: 'issued', thStyle: 'width:80px' }
    ];

    get sumAmountTaxFree(): number {
        let res: number = 0;
        this.subcontracts?.forEach((element) => {
            res += element.amountTaxFree ?? 0;
        });
        return res;
    }

    async saveSubcontracts(): Promise<void> {
        const callData = await subcontractApi.patchSubcontracts(this.subcontracts ?? [], this.selectedProjectId + '');
        if (isCallValidAndNotCancelled(callData)) {
            this.getSubContractings(this.selectedProject?.id ?? 0);
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Sous traitance prévisionnelle',
                variant: 'success',
                solid: true
            });
        }
    }

    addSubcontract(): void {
        const subcontract = {} as ISubcontract;
        subcontract.projectId = this.selectedProject?.id ?? 0;
        this.subcontracts?.push(subcontract);
    }

    deleteSubContractLine(item: ISubcontract): void {
        const index = this.subcontracts?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.subcontracts?.splice(index, 1);
        }
    }

    private async mounted() {
        vxm.app.changeTitleMain('Sous traitance prévisionnelle');
        if (this.selectedProjectId && this.selectedProjectId > 0) {
            await this.getSubContractings(this.selectedProjectId);
            const contractLineCallData = await contractApi.getAllContractLinesByProjectId(this.selectedProjectId + '');
            if (isCallValidAndNotCancelled<IContractLine[] | null>(contractLineCallData)) {
                if (contractLineCallData?.datas) {
                    this.contractLinesList = contractLineCallData.datas.map((x, index) => {
                        const contractLine: ISelectListOption = {
                            id: x.id,
                            code: 'CO' + index,
                            label: x.designation ?? ''
                        };
                        return contractLine;
                    });
                }
            }
        }
        this.isBusy = false;

        vxm.user.checkIsAssociate();
        vxm.user.checkIsAdmin();
        vxm.user.checkIsDaf();
        vxm.user.checkIsStudioManager();
        vxm.user.checkIsRH();
        vxm.user.checkIsCP();
    }

    async exportListSubContract(): Promise<void> {
        await this.generateReport(
            subcontractApi.exportSubContractData({ data: this.selectedProjectId } as any),
            `Sous_traitance_prévisionnelle_${this.selectedProject?.designation}_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }
}
