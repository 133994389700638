
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { fr } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';

@Component({
    components: {
        Datepicker
    }
})
export default class MonthPicker extends Vue {
    @Prop({ required: true }) month!: Date;
    private language = fr;
    private selectedMonth: Date = this.month;

    @Watch('month')
    monthChanged(newValue: Date): void {
        this.selectedMonth = newValue;
    }

    syncDate(newDate: Date): void {
        this.$emit('month-changed', newDate);
    }

    ShiftMonth(inc: number): void {
        const newDate = new Date(this.month.setMonth(this.month.getMonth() + inc));
        this.syncDate(newDate);
    }
}
