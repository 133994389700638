
import { IContractLine } from '@/entity/contract/contract-line';
import { ILeaveEmployee } from '@/entity/leave/leaveEmployee';
import { IImputationWorkflowItem } from '@/entity/rh/workflow';
import { NU } from '@t/type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IImputationWeek, ISumPartDay } from './imputation-interfaces';

@Component({})
export default class ImputationGridLine extends Vue {
    @Prop({ required: true })
    private selectedMonth!: Date;

    @Prop({ required: true })
    private contractLineInWorkflow!: NU<IContractLine>;
    
    @Prop({ required: true })
    private leavesCurrentMonth!: NU<ILeaveEmployee[]>;

    @Prop({ required: true })
    private projectDesignation!: NU<string>;

    @Prop({ required: true })
    private cellsToDisplay!: string[];

    @Prop({ required: true })
    private numberToDisplay!: number[];

    private arrayToDisplay: any = [];
    private sumMonth: Array<ISumPartDay[]> = [];

    private popupState: boolean = false;

    checkValue(imp: IImputationWorkflowItem): void {
        if (typeof imp.value !== 'number') {
            imp.value = 0;
        }
        if (imp.value !== null && imp.value !== undefined) {
            if (imp.value < 0) {
                imp.value = 0;
            }
            if (imp.value > 1) {
                imp.value = 1;
            }
        }

        this.$emit('update-sums');
    }

    displayImputation(day: string, daypartIndex: IImputationWorkflowItem): boolean {
        return !(
            (day === 'dim.' || day === 'sam.') &&
            (daypartIndex.dayPartType.id === 1 || daypartIndex.dayPartType.id === 2)
        );
    }

    getClass(day: string, val: number): string {
        return val === -1 ? 'color-dark-grey' : day === 'dim.' || day === 'sam.' ? 'color-grey' : '';
    }

    getClassInput(day: string): string {
        return day === 'dim.' || day === 'sam.' ? 'input-format-week' : 'input-format';
    }

    getClassWeek(index: number, lastIndex: number): string {
        return index === lastIndex - 1 ? 'week-sums color-light-grey' : 'week-sums';
    }

    get generateDisplayWeek(): any {
        const date = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1);
        let imputationWeek: IImputationWeek[] = [];
        let imputationsOfWeek: Array<IImputationWorkflowItem[]> = [];
        this.arrayToDisplay = [];
        while (date.getMonth() === this.selectedMonth.getMonth()) {
            imputationWeek.push({ imputation: this.contractLineInWorkflow?.imputations![date.getDate() - 1] ?? [] });
            imputationsOfWeek.push(this.contractLineInWorkflow?.imputations![date.getDate() - 1] ?? []);
            if (date.getDay() === 0) {
                imputationWeek.push({ sums: this.calculateSums(imputationsOfWeek) });
                imputationWeek.forEach((element) => {
                    this.arrayToDisplay.push(element);
                });
                imputationWeek = [];
                imputationsOfWeek = [];
            }
            date.setDate(date.getDate() + 1);
            if (date.getMonth() !== this.selectedMonth.getMonth() && date.getDay() !== 1) {
                imputationWeek.push({ sums: this.calculateSums(imputationsOfWeek) });
                imputationWeek.forEach((element) => {
                    this.arrayToDisplay.push(element);
                });
                imputationWeek = [];
                imputationsOfWeek = [];
            }
        }
        this.setTotalMonth();
        return this.arrayToDisplay;
    }

    setTotalMonth(): void {
        const weekSum: ISumPartDay[] = [];
        let sumMor: number = 0;
        let sumMorT: number = 0;
        let sumAft: number = 0;
        let sumAftT: number = 0;
        let sumCha: number = 0;
        let sumChaT: number = 0;
        this.arrayToDisplay.forEach((element) => {
            if (element.sums !== undefined) {
                sumMor += element.sums[0].imputationSum;
                sumMorT += element.sums[0].teletravailSum;
                sumAft += element.sums[1].imputationSum;
                sumAftT += element.sums[1].teletravailSum;
                sumCha += element.sums[2].imputationSum;
                sumChaT += element.sums[2].teletravailSum;
            }
        });
        weekSum.push({ imputationSum: sumMor, teletravailSum: sumMorT });
        weekSum.push({ imputationSum: sumAft, teletravailSum: sumAftT });
        weekSum.push({ imputationSum: sumCha, teletravailSum: sumChaT });
        this.arrayToDisplay.push({ sums: weekSum });
    }

    calculateSums(imputations: Array<IImputationWorkflowItem[]>): ISumPartDay[] {
        const weekSum: ISumPartDay[] = [];
        let sumMor: number = 0;
        let sumMorT: number = 0;
        let sumAft: number = 0;
        let sumAftT: number = 0;
        let sumCha: number = 0;
        let sumChaT: number = 0;
        for (let i = 0; i < imputations.length; i++) {
            imputations[i].forEach((element) => {
                switch (element.dayPartTypeId) {
                    case 1:
                        if (element.value !== null && element.value !== undefined && !isNaN(element.value)) {
                            sumMor += Number(element.value);
                        }

                        break;
                    case 2:
                        if (element.value !== null && element.value !== undefined && !isNaN(element.value)) {
                            sumAft += Number(element.value);
                        }

                        break;
                    case 3:
                        if (element.value !== null && element.value !== undefined  && !isNaN(element.value)) {
                            sumCha += Number(element.value);
                        }
                        break;                       
                    default:
                        break;
                }
            });
        }
        weekSum.push({ imputationSum: sumMor, teletravailSum: sumMorT });
        weekSum.push({ imputationSum: sumAft, teletravailSum: sumAftT });
        weekSum.push({ imputationSum: sumCha, teletravailSum: sumChaT });
        return weekSum;
    }

    displayPopup(): void {
        this.popupState = true;
    }

    closePopup(): void {
        this.popupState = false;
    }

    deleteContractLine(): void {
        this.$emit('delete-line', this.contractLineInWorkflow);
        this.popupState = false;
    }

    checkLeave(day:number, daypartIndex: IImputationWorkflowItem): boolean {
        if(this.leavesCurrentMonth){
            if(this.leavesCurrentMonth.some(leave=>leave.dateLeave && new Date(leave.dateLeave).getDate() == day && daypartIndex.dayPartType.id==leave.dayPartId)){
                return true
            }
        }
        return false
    }
}
