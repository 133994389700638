
import { IContractLine } from '@/entity/contract/contract-line';
import { IProject } from '@/entity/project/project';
import { IProjectComment } from '@/entity/project/project-comment';
import { contractApi } from '@/wapi/contract-api';
import { projectApi } from '@/wapi/project-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CommentItem from './comment-item.vue';
import CommentForm from './comment-form.vue';
import { authModule } from '@t/session';

@Component({
    components: {
        CommentItem,
        CommentForm
    }
})
export default class CommentList extends Vue {
    @Prop({ required: true })
    private project!: IProject;

    private comments: IProjectComment[] = [];
    private contractLinesObjects: IContractLine[] = [];
    private loading = false;
    private employeeIdentifier!: string;

    private newComment: IProjectComment = {
        id: null,
        content: '',
        createdById: null,
        editedById: null,
        contractLineId: null,
        createdAt: null,
        editedAt: null,
        projectId: this.project.id
    };

    private createNewComment() {
        this.newComment = {
            id: null,
            content: '',
            createdById: null,
            editedById: null,
            contractLineId: null,
            createdAt: null,
            editedAt: null,
            projectId: this.project.id
        };

        this.showPopup();
    }

    private showPopup() {
        (this.$refs['comment-modal'] as any).show();
    }

    get contractLineOptions(): { id: number; code: string; label: string }[] {
        return this.contractLinesObjects.map((x, index) => {
            return {
                id: x.id,
                code: `CL${index}`,
                label: `${x.designation}`
            };
        });
    }

    get isSubmittable(): boolean {
        return this.newComment!.content !== '';
    }

    private beginEditComment(comment: IProjectComment) {
        this.showPopup();
        this.newComment = comment;
    }

    async created(): Promise<void> {
        this.employeeIdentifier = await authModule.getAccount()!.localAccountId!;
        await this.updateProject(this.project);
    }

    @Watch('project')
    private async updateProject(newProject: IProject) {
        await this.fetchDatasFromProject(newProject);
    }

    async contractLinesForProject(project: IProject): Promise<IContractLine[]> {
        const contractLineRequest = await contractApi.getAllContractLinesByProjectId(project.id + '');
        if (isCallValidAndNotCancelled(contractLineRequest)) {
            return contractLineRequest.datas!;
        }
        return [];
    }

    async newCommentSave(): Promise<void> {
        const comment: IProjectComment = this.newComment;
        if (comment.id !== null) {
            comment.editedAt = new Date();
            comment.editedById = this.employeeIdentifier;
        } else {
            comment.createdAt = new Date();
            comment.createdById = this.employeeIdentifier;
        }
        const result = await projectApi.submitComments(this.project.id, [comment]);
        if (isCallValidAndNotCancelled(result)) {
            this.comments = result.datas!.map(x => {
                x.createdAt = new Date(x.createdAt!);
                if (x.editedAt) {
                    x.editedAt = new Date(x.editedAt);
                }
                return x;
            });
            this.comments = this.comments.sort((a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 1));
            this.newComment = {
                id: null,
                content: '',
                createdById: null,
                editedById: null,
                contractLineId: null,
                createdAt: null,
                editedAt: null,
                projectId: this.project.id
            };
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Commentaires',
                variant: 'success',
                solid: true
            });
        } else {
            this.$bvToast.toast('Une erreur s\'est produite. Veuillez rééssayer ultérieurement', {
                title: 'Commentaires',
                variant: 'error',
                solid: true
            });
        }
        this.loading = false;
        (this.$refs['comment-modal'] as any).hide();
    }

    async fetchDatasFromProject(project: IProject): Promise<void> {
        this.loading = true;
        const commentsCall = this.commentsForProject(project);
        const contractLineCall = this.contractLinesForProject(project);

        this.contractLinesObjects = await contractLineCall;

        this.comments = await commentsCall;
        this.comments = this.comments.map(x => {
            x.createdAt = new Date(x.createdAt!);
            if (x.editedAt) {
                x.editedAt = new Date(x.editedAt);
            }
            return x;
        });
        this.comments = this.comments.sort((a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 1));
        this.loading = false;
    }

    private getContractLineForComment(comment: IProjectComment): IContractLine {
        return this.contractLinesObjects.find(x => x.id === comment.contractLineId)!;
    }

    async commentsForProject(project: IProject): Promise<IProjectComment[]> {
        const requestResult = await projectApi.getCommentsByProjectId(project.id + '');
        if (isCallValidAndNotCancelled(requestResult)) {
            return requestResult.datas!;
        }
        return [];
    }
}
