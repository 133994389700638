
import { vxm } from '@/store';
import { NU } from '@t/type';
import { ILeaveEmployee } from '@/entity/leave/leaveEmployee';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ITeleworkingData, ITeleworkingSelection } from './imputation-interfaces';

@Component({})
export default class ImputationGridHeader extends Vue {
    @Prop({ required: true })
    private cellsToDisplay!: string[];

    @Prop({ required: true })
    private numberToDisplay!: number[];

    @Prop({ required: true })
    private loadedTeleworking!: ITeleworkingData;

    @Prop({ required: true })
    private currentMonth!: Date;

    @Prop({ required: true })
    private leavesCurrentMonth!: NU<ILeaveEmployee[]>;

    private teleworkingList: ITeleworkingData = {};

    isWeekDay(day: string) {
        // return true;
        return day !== 'dim.' && day !== 'sam.';
    }

    @Watch('numberToDisplay', { immediate: true })
    onNumberChange(newVal) {
        this.teleworkingList = this.loadedTeleworking;
    }

    mounted() {
        this.teleworkingList = this.loadedTeleworking;
    }

    onTeleworkingDayPartChange(dayPartCode: string, dayNumber: number, added: boolean) {
        this.$emit('teleworking-update', {
            month: this.currentMonth,
            day: dayNumber,
            part: dayPartCode,
            added: added
        });
    }

    checkLeave(dayPartCode: string, dayNumber: number): boolean {
        if(this.leavesCurrentMonth){
            if(this.leavesCurrentMonth.some(leave=>leave.dateLeave && new Date(leave.dateLeave).getDate() == dayNumber && dayPartCode==leave.dayPartCode)){
                return true
            }
        }
        return false
    }


    getClass(day: string, val: number, index: number): string {
        return val === -1
            ? 'number color-dark-grey week-style'
            : this.isWeekDay(day)
            ? 'number color-light-grey'
            : 'number color-grey';
    }
}
