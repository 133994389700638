// import analytics from '@/tools/analytics';
import { vxm } from '@/store';
import { authModule } from '@t/session';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { IRoute, routeDefinition } from './route-definition';
import { appTokenMgr } from '@t/employee-app-role';

Vue.use(VueRouter);

const routes: IRoute[] = routeDefinition;

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    linkExactActiveClass: 'is-active'
});

router.beforeEach(async (to, _from, next) => {
    // analytics.startTrackPage(to.fullPath);
    const acc = authModule.getAccount();
    if (to.name === 'externalValidation') {
        next();
        return;
    } else if (to.name === 'login') {
        if (acc !== null) {
            next({ name: 'home' });
            return;
        }
    } else {
        if (to.name === 'not-a-user') {
            if (acc === null) {
                next({ name: 'login' });
            }
            return;
        }
        if (acc === null) {
            next({ name: 'login' });
            return;
        }
        if (!appTokenMgr.isUser(acc)) {
            next({ name: 'not-a-user' });
            return;
        }
    }

    next();
});

router.afterEach(to => {
    vxm.app.clearTitle();

    // analytics.stopTrackPage(to.fullPath);
});

export default router;
