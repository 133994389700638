import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { IEmployee } from '@/entity/shared/employee';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';
import { IPlanificationPhaseProfile } from '@/entity/planification/planification-phase-profile';
import { ICrossProjectImputationRecap, ICrossProjectProfileUnaffectedRecap } from '@/entity/rh/imputation-recap';

class PlanificationApi extends ApiBase<IPlanificationPhase> {
    /**
     *
     */
    constructor(urlBase: string = 'api/planification') {
        super(urlBase);
    }

    public async getAllPhasesByProjectId(projectId: number): Promise<ICancellableResult<IPlanificationPhase[]>> {
        return await ajaxCall.baseAjaxCall<IPlanificationPhase[]>('get', this.url, {
            urlParameter: ['planificationphases'],
            queryParameter: { projectId: projectId + '' }
        });
    }

    public async getCrossProjectProfileUnaffectedRecapBetweenDates(
        beginDate: Date,
        endDate: Date,
        perMonth: boolean = true
    ): Promise<ICancellableResult<ICrossProjectProfileUnaffectedRecap[]>> {
        const result = await ajaxCall.baseAjaxCall<ICrossProjectProfileUnaffectedRecap[]>('get', this.url, {
            urlParameter: ['crossProjectProfileUnaffected'],
            queryParameter: {
                beginYear: `${beginDate.getFullYear()}`,
                beginMonth: `${beginDate.getMonth() + 1}`,
                beginDay: `${beginDate.getDate()}`,
                endYear: `${endDate.getFullYear()}`,
                endMonth: `${endDate.getMonth() + 1}`,
                endDay: `${endDate.getDate()}`,
                perMonth: `${perMonth}`
            }
        });
        result?.datas?.map((x) => {
            x.recaps.map((y) => {
                y.fromDate = new Date(y.fromDate);
                y.toDate = new Date(y.toDate);
            });
        });
        return result;
    }

    public async getEmployeesLeavesValidatedRecapBetweenDates(
        beginDate: Date,
        endDate: Date,
        perMonth: boolean = true
    ): Promise<ICancellableResult<ICrossProjectImputationRecap[]>> {
        const result = await ajaxCall.baseAjaxCall<ICrossProjectImputationRecap[]>('get', this.url, {
            urlParameter: ['leaveListValidatedAllEmployeeByDate'],
            queryParameter: {
                beginYear: `${beginDate.getFullYear()}`,
                beginMonth: `${beginDate.getMonth() + 1}`,
                beginDay: `${beginDate.getDate()}`,
                endYear: `${endDate.getFullYear()}`,
                endMonth: `${endDate.getMonth() + 1}`,
                endDay: `${endDate.getDate()}`,
                perMonth: `${perMonth}`
            }
        });
        result?.datas?.map((x) => {
            x.employeeId = x.employeeId.toUpperCase();
            x.recaps.map((y) => {
                y.fromDate = new Date(y.fromDate);
                y.toDate = new Date(y.toDate);
                y.employeeId = y.employeeId.toUpperCase();
            });
        });
        return result;
    }

    public async getEmployeeHistorization(id: string): Promise<ICancellableResult<IEmployee>> {
        return await ajaxCall.baseAjaxCall<IEmployee, string>('get', this.url, {
            urlParameter: ['employee', id, 'historization']
        });
    }

    public async patchPhases(data: IPlanificationPhase[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IPlanificationPhase[]>('patch', this.url, {
            urlParameter: ['patchphases'],
            payload: data
        });
    }

    public async exportFeeData(
        data: number[],
        idProject: number,
        sortKeySelected: string,
        isSortDesc: boolean
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                FeeIdList: data,
                ProjectId: idProject,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc
            },
            urlParameter: ['exportFeeData'],
            responseType: 'blob'
        });
    }

    public async slidePhases(
        data: IPlanificationPhaseProfile[],
        oldVal: Date,
        newVal: Date
    ): Promise<ICancellableResult<IPlanificationPhaseProfile[]>> {
        for (let i = 0; i < data.length; ++i) {
            if (typeof data[i].employee == 'string') data[i].employee = null as any;
        }
        return await ajaxCall.baseAjaxCall<IPlanificationPhaseProfile[], IPlanificationPhaseProfile[]>(
            'post',
            this.url,
            {
                urlParameter: ['slidephaseprojection', oldVal.toISOString(), newVal.toISOString()],
                payload: data
            }
        );
    }
}

export const planificationApi: PlanificationApi = new PlanificationApi();
