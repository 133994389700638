import { IInvoice } from './../invoice/invoice';
import { IAgency } from '../project/agency';
import { IClient } from '../shared/client';
import { IContractLine } from './contract-line';
import { IReferential } from '../shared/referential';
export interface IContract {
    id: number;
    projectId: number;
    reference: string;
    clientId?: number;
    client?: IClient;
    agencyId?: number;
    agency?: IAgency;
    description: string;
    date?: Date;
    comment?: string;
    interestShareCalculation?: string;
    insurancePercentageId?: number;
    insurancePercentage: IReferential;
    interestShare?: string;
    salesAccount?: number;
    buildingWorkAmount?: number;
    totalExcTax: number;
    tax: number;
    total: number;
    bankId?: number;
    bank: IReferential;
    creationDate?: Date;
    creationUserId?: string;
    modificationDate?: Date;
    modificationUserId?: string;
    contractLines?: IContractLine[];
    invoices?: IInvoice[];
    terme?: number;
    termeEndOfMonth?: boolean;
    nbMarket?: string;
    nbCommitment?: string;
    paymentDueDateId?: number;
    invoiceTotalExcTax?: number;
}

export const deadlines: Array<{ code: string; label: string }> =
[
    { code: '30=', label: '30 jours net' },
    { code: '30+', label: '30 jours fin de mois' },
    { code: '45=', label: '45 jours net' },
    { code: '45+', label: '45 jours fin de mois' },
    { code: '60=', label: '60 jours net' },
    { code: '00=', label: 'Comptant' }
];
