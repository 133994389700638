
import { IContract } from '@/entity/contract/contract';
import { ICreateContract } from '@/entity/contract/create-contract';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { contractApi } from '@/wapi/contract-api';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { BTable } from 'bootstrap-vue';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { IEmployeeAppRoleManager, appTokenMgr } from '@t/employee-app-role';
import frenchLocale from 'date-fns/locale/fr';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IStudio } from '@/entity/project/studio';
import { IProject } from '@/entity/project/project';
import { IContractProject } from '@/entity/contract/contract-project';
import { moduleApiGraph } from '@t/module-api-graph';
import { IReferential } from '@/entity/shared/referential';
import { referentialApi } from '@/wapi/referential-api';
import { action, mutation } from 'vuex-class-component';
import { IProjectManager } from '@/entity/project/project-manager';
import { employeeApi } from '@/wapi/employee-api';
import { IEmployee } from '@/entity/shared/employee';

@Component({
    components: {
        InputElement
    }
})
export default class ContractList extends Vue {

    private filteredContracts: NU<IContractProject[]> = [];
    private filteredData: IContractProject[] = [];
    private filterData: string = '';
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private contractsCurrentPage: number = 1;
    private promiseExport: boolean = false;
    private projectManagers: IEmployee[] = [];
    private isLoaded: boolean = false;
    
    private columnPlaceholder: string = 'Nouvelle colonne';
    private selectedFieldToAdd: NU<IBootstrapTableColumn> = null;
    public get addedAdditionalFields() {
        return vxm.lists.additionnalFieldChoice('contracts-list-state');
    }

    public get listOfFilters() {
        return vxm.lists.filters('contracts-list-state');
    }

    @Watch('selectedFieldToAdd')
    public addColumn(column: string, _oldVal: string): void {
        vxm.lists.addColumn({listKey: 'contracts-list-state', column});
    }

    get fields(): Array<IBootstrapTableColumn | string> {
       return vxm.lists.listFields('contracts-list-state')
    }

    get additionalFieldChoice(): Array<IBootstrapTableColumn> {
        return vxm.lists.additionnalFieldChoice('contracts-list-state');
    }

    public deleteColumn(column: string): void {
        vxm.lists.removeColumn({listKey: 'contracts-list-state', column});
    }

    public onRowClicked(data): void {
        const contract = data[0];
        const id = contract.projectId
        localStorage.setItem('selected-project', String(id));
        vxm.project.updateSelectedProject(id + '');
        this.$router.push({ name : "project-information", params: { id: id }});
    }

    public getContracts():NU<IContractProject[]>{
        const list = vxm.project.allContractsList;
        this.filteredContracts = list?.map((_) => {
            return <IContractProject>{
                id: _.id,
                projectId: _.projectId,
                reference: _.reference,
                clientId: _.clientId,
                client: _.client,
                clientLabel: _.client?.label,
                agencyId: _.agencyId,
                agency: _.agency,
                agencyLabel: _.agency?.label,
                description: _.description,
                date: _.date,
                comment: _.comment,
                interestShareCalculation: _.interestShareCalculation,
                insurancePercentageId: _.insurancePercentageId,
                insurancePercentage: this.getInsurancePercentage(_.insurancePercentageId!),
                interestShare: _.interestShare,
                salesAccount: _.salesAccount,
                buildingWorkAmount: _.buildingWorkAmount,
                totalExcTax: _.totalExcTax,
                tax: _.invoiceTotalExcTax,
                total: _.total,
                bankId: _.bankId,
                bank: _.bank,
                bankLabel: _.bank?.label,
                creationDate: _.creationDate,
                creationUserId: _.creationUserId,
                modificationDate: _.modificationDate,
                modificationUserId: _.modificationUserId,
                contractLines: _.contractLines,
                invoices: _.invoices,
                terme: _.terme,
                designation: this.getDesignation(_.projectId),
                trigram: this.getTrigram(_.projectId),
                studio: this.getStudio(_.projectId),
                projectManager: "",
                projectStudio: "",
                projectManagerIds: this.getProjectManagers(_.projectId)
            };
        })

        return this.filteredContracts;
    } 

    get contracts(): NU<IContractProject[]> {
        const allContract=this.getContracts();
        return allContract==null ? []: allContract;
    }

    get filteredDataByColumn(): IContractProject[] {
        const keys = Object.keys(this.listOfFilters);
        const filtersApplied = keys.filter((x) => this.listOfFilters[x].value.length > 0);

        if (filtersApplied.length > 0) {
            this.filteredContracts = this.contracts!.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
            return this.contracts!.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        }

        this.filteredContracts = this.contracts!.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        return this.contracts!;
    }

    get totalContracts(): number {
        if (this.filteredContracts && this.filteredContracts.length > 0) {
            return this.filteredContracts.length;
        }
        return 0;
    }

    get isReadOnly(): boolean {
        return !appTokenMgr.isAdmin() || (vxm.project.projectData?.isSale ?? false);
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    private getDesignation(projectId: number): NU<string> {
        const list = vxm.project.dropdownProjectListOptions;
        return list?.find(_ => _.id == projectId)?.designation;
    }

    private getTrigram(projectId: number): NU<string> {
        const list = vxm.project.dropdownProjectListOptions;
        return list?.find(_ => _.id == projectId)?.trigram;
    }

    private getStudio(projectId: number): NU<String> {
        const list = vxm.project.dropdownProjectListOptions;
        return list?.find(_ => _.id == projectId)?.studio?.label;
    }
    get insurancePercentages(): NU<IReferential[]> {
        return vxm.referential.insurancePercentages;
    }

    private getInsurancePercentage(insurancePercentageId: number): NU<String> {
        const list = vxm.referential.insurancePercentages;
        return list?.find(_ => _.id == insurancePercentageId)?.label;
    }

    private getProjectManagers(projectId: number): string {
        const list = vxm.project.dropdownProjectListOptions;
        const ids = list?.find(_ => _.id == projectId)?.projectManagerIds;
        if (ids) {
            return this.projectManagers.filter(_ => ids!.map(id => id.toLowerCase()).includes(_.id.toLowerCase())).map(_ => _.employeeFirstName + ' ' +  _.employeeLastName).join(", ")
        }

        return "";
    }

    private isBusy: boolean = true;

    public filterListByColumn(key: string, x: IContractProject): boolean {
        if (key === 'reference' && x.reference != undefined) {
            return x.reference.toUpperCase().includes(this.listOfFilters.reference.value.toUpperCase());
        } else if (key === 'designation' && x.designation != undefined) {
            return x.designation!.toLowerCase().includes(this.listOfFilters.designation.value.toLowerCase());
        } else if (key === 'description' && x.description != undefined) {
            return x.description.toUpperCase().includes(this.listOfFilters.description.value.toUpperCase());
        } else if (key === 'clientLabel' && x.clientLabel != undefined) {
            return x.clientLabel!.toUpperCase().includes(this.listOfFilters.clientLabel.value.toUpperCase());
        } else if (key === 'agencyLabel' && x.agencyLabel != undefined) {
            return x.agencyLabel!.toUpperCase().includes(this.listOfFilters.agencyLabel.value.toUpperCase());
        } else if (key === 'trigram'&& x.trigram != undefined) {
            return x.trigram.toUpperCase().includes(this.listOfFilters.trigram.value.toUpperCase());
        } else if (key === 'terme') {
            return x.terme?.toString() == this.listOfFilters.terme.value;
        } else if (key === 'bankLabel') {
            return x.bankLabel!.toUpperCase().includes(this.listOfFilters.bankLabel.value.toUpperCase());
        } else if (key === 'comment' && x.comment != undefined) {
            return x.comment!.toUpperCase().includes(this.listOfFilters.comment.value.toUpperCase());
        } else if (key === 'insurancePercentage' && x.insurancePercentage != undefined) {
            return x.insurancePercentage!.toUpperCase().includes(this.listOfFilters.insurancePercentage.value.toUpperCase());
        } else if (key === 'salesAccount' && x.salesAccount != undefined) {
            return x.salesAccount?.toString().includes(this.listOfFilters.salesAccount.value.toString());
        } else if (key === 'buildingWorkAmount' && x.buildingWorkAmount != undefined) {
            return x.buildingWorkAmount?.toString().includes(this.listOfFilters.buildingWorkAmount.value);
        } else if (key === 'tax' && x.tax != undefined) {
            return x.tax?.toString().includes(this.listOfFilters.tax.value);
        } else if (key === 'interestShareCalculation' && x.interestShareCalculation != undefined) {
            return x.interestShareCalculation!.toUpperCase().includes(this.listOfFilters.interestShareCalculation.value.toUpperCase());
        } else if (key === 'studio' && x.studio != undefined) {
            return x.studio!.toUpperCase().includes(this.listOfFilters.studio.value.toUpperCase());
        } else if (key === 'projectManagerIds' && x.projectManagerIds != undefined) {
            return x.projectManagerIds!.toUpperCase().includes(this.listOfFilters.projectManagerIds.value.toUpperCase());
        } else if (key === 'date') {
            return (x.date) ? new Date(x.date).toLocaleDateString('fr-FR').includes(this.listOfFilters.date.value) : false;
        } else {
            return false;
        }
    }

    async exportContracts(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        let listIds =
            this.filteredContracts && this.filteredContracts.length !== this.contracts?.length
                ? this.filteredContracts.map((x) => x.id)
                : this.filteredDataByColumn && this.filteredDataByColumn.length !== this.contracts?.length
                    ? this.filteredDataByColumn.map((x) => x.id)
                    : this.contracts?.map((x) => x.id);                   
        const listAdds = this.addedAdditionalFields.map((x) => x.key);

        const filteredDatalistIds = this.filteredData.map((x) => x.id);

        if(this.filterData && filteredDatalistIds.length > 0) {

            if(this.filteredDataByColumn && this.filteredDataByColumn.length !== this.contracts?.length){
                listIds = listIds?.filter(id =>filteredDatalistIds.includes(id));
            }
            else listIds = filteredDatalistIds;            
        }

  await this.generateReport(
            contractApi.exportContractsData(
                listIds!,
                listAdds as any,
                sortKeySelected as string,
                sortKeyDescSelected as any,
                appTokenMgr.isAdmin()
            ),
            `Liste_des_contracts_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private async mounted(): Promise<void>{
        vxm.app.changeTitleMain('Projets');
        vxm.app.changeTitleExt(`Liste de contrats`);
        vxm.lists.init('contracts-list-state');
        await vxm.project.fetchDropDownProjectOptions();
        const list = vxm.project.dropdownProjectListOptions;
        const ids = list?.map(_ => _.projectManagerIds).reduce((acc, item) => [...acc!, ...item!], []);

        const employeeCall = await employeeApi.getEmployees();
        if (isCallValidAndNotCancelled(employeeCall)) {
            const employees = employeeCall!.datas!;
            this.projectManagers = employees.filter(e=>ids?.includes(e.id.toLocaleUpperCase()));
        }
        await this.getContractList();
        await vxm.referential.fetchInsurancePercentages();
    }


    private async getContractList(): Promise<void> {
        this.isBusy = true;
       await vxm.project.fetchListsContracts();
        this.isBusy = false;
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    checkFilterIncluded(item : IContractProject ,filter : string): boolean {

       return item.trigram.toLowerCase().includes(filter.toLowerCase()) || item.reference.toLowerCase().includes(filter.toLowerCase()) 
        || item.description.toLowerCase().includes(filter.toLowerCase()) || (item.clientLabel?.toLowerCase().includes(filter.toLowerCase())?? false)
    }

    filterFunction(item : IContractProject ,filter : string): boolean {

        this.filteredData = this.contracts?.filter(d=> this.checkFilterIncluded(d, filter))?? [];
        if(this.filteredDataByColumn && this.filteredDataByColumn.length !== this.contracts?.length){
            this.filteredData = this.filteredDataByColumn?.filter(d => this.checkFilterIncluded(d, filter))?? [];
        }
        return this.checkFilterIncluded(item, filter);
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        let a, b;
        if (key === 'description') {
            a = itemA[key];
            b = itemB[key];
            if (a === null) a = '';
            if (b === null) b = '';
            return this.strcmp(a.replaceAll('_', '-').toLowerCase(), b.replaceAll('_', '-').toLowerCase());
        }
    }
}
