
import { IWorkflowSummary } from '@/entity/rh/workflow';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UserRealizedOverTime extends Vue {
    @Prop({ required: true }) workflowRealizedOverTimeList!: IWorkflowSummary[];
    @Prop({ required: true }) userId!: string;
    @Prop({ required: true }) isCurrentUserInformations!: boolean;

    isVisible: boolean = false;

    get workflowRealizedOverTimeListToBeSubmitted(): IWorkflowSummary[] {
        return this.workflowRealizedOverTimeList.filter((x) => x.isSubmittedToBeValidate === false);
    }

    get workflowRealizedOverTimeListSubmitted(): IWorkflowSummary[] {
        return this.workflowRealizedOverTimeList.filter((x) => x.isSubmittedToBeValidate === true);
    }

    private consultRealizedOverTime(workflowId: number) {
        this.$router.push({
            name: 'realized-over-time-information',
            params: { id: this.userId, realizedOverTimeId: workflowId + '' }
        });
    }

    private transformToStatus(val: number): string {
        switch (val) {
            case -2:
                return 'Annulé';
            case -1:
                return 'Refusé';
            case 0:
                return 'En cours';
            case 1:
                return 'Soumis';
            case 2:
                return 'Validé';
            default:
                return 'inconnu';
        }
    }

    private getFormattedDate(date: Date) {
        return new Date(date).toLocaleDateString('fr-FR');
    }
}
