
import { IEmployee } from '@/entity/shared/employee';
import { IEmployeeRole } from '@/entity/shared/referential';
import { vxm } from '@/store';
import { employeeApi } from '@/wapi/employee-api';
import { referentialApi } from '@/wapi/referential-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IFilterCache } from '@t/filter-cache-service';
import { NU } from '@t/type';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IEmployeeFilter, IRoleFilter, IElementFilter } from './filter-entities';
import { studioApi } from '@/wapi/studio-api';
import { IStudio } from '@/entity/project/studio';
import AffectationMgtPerson from './affectation-mgt-person.vue';

@Component({
    components: {
        AffectationMgtPerson,
    }
})
export default class AfffectationFilter extends Vue {
    @Prop({ required: true })
    private employeeIdsOfProject!: string[];

    @Prop({ required: true })
    private rolesOfProject!: IEmployeeRole[];

    @Prop({ required: true })
    private initialProjectFilter: NU<IFilterCache>;

    @Prop({ required: true })
    private type!: 'EMP' | 'ROL' | 'STU' | 'PRO';

    @Prop({ required: true, default: [] })
    private projects!: { id: number, trigram: string }[];

    private editedEmployeeFilters: IEmployeeFilter[] = [];
    private editedRoleFilters: IElementFilter[] = [];
    private editedStudioFilters: IElementFilter[] = [];
    private editedProjectFilters: IElementFilter[] = [];
    private loading: boolean = false;

    private forCurrentProjectOnly: boolean = false;

    async created(): Promise<void> {
        this.editedProjectFilters = this.projects.map(p => ({
            id: p.id.toString(),
            label: p.trigram,
            selected: false,
        }));
        await this.fetchData();
        this.employeeFiltersOnChange(this.employeeIdsOfProject);
        this.roleFiltersOnChange(this.rolesOfProject);
        this.initialProjectFilterOnChange(this.initialProjectFilter);
    }

    @Watch('employeeFilters')
    employeeFiltersOnChange(newVal: string[]): void {
        newVal.forEach((x) => {
            const current = this.editedEmployeeFilters.find((y) => y.employeeId === x);
            if (current) {
                current.selected = this.initialProjectFilter == null || this.initialProjectFilter.employeeIds.includes(x);
            }
        });
    }

    @Watch('projects')
    projectsOnChange(newVal: { id: number, trigram: string }[]): void {
        this.editedProjectFilters = newVal.map(p => ({
            id: p.id.toString(),
            label: p.trigram,
            selected: this.editedProjectFilters.find(x => x.id === p.id.toString())?.selected ?? false,
        }));
    }

    @Watch('initialProjectFilter')
    initialProjectFilterOnChange(newVal: NU<IFilterCache>): void {
        this.editedRoleFilters.forEach(x => {
            if (newVal == null || newVal.roles.findIndex(y => y.id.toString() === x.id) >= 0) {
                x.selected = true;
            }
        });
        this.editedEmployeeFilters.forEach(x => {
            if (newVal == null || newVal.employeeIds.includes(x.employeeId)) {
                x.selected = true;
            }
        });
        this.editedStudioFilters.forEach(x => {
            if (newVal == null || newVal.studios.findIndex(y => y.id === x.id) >= 0) {
                x.selected = true;
            }
        });
        this.editedProjectFilters.forEach(x => {
            if (newVal == null || newVal.projects.findIndex(y => y.id === x.id) >= 0) {
                x.selected = true;
            }
        });
    }

    @Watch('roleFilters')
    roleFiltersOnChange(newVal: IEmployeeRole[]): void {
        newVal.forEach((x) => {
            const current = this.editedRoleFilters.find((y) => +y.id === x.id);
            if (current) {
                current.selected = this.initialProjectFilter == null || this.initialProjectFilter.roles.findIndex(y => +y.id === x.id) >= 0;
            }
        });
    }

    async fetchData(): Promise<void> {
        this.loading = true;
        await this.fetchEmployees();
        await this.fetchRoles();
        await this.fetchStudios();
        this.loading = false;
    }

    async fetchStudios(): Promise<void> {
        const studioCallData = await studioApi.getAllBase();
        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.editedStudioFilters = studioCallData?.datas?.map(s => ({
                id: s.id!.toString(),
                label: s.label ?? '',
                selected: false,
            })) ?? [];
        }
    }

    async fetchRoles(): Promise<void> {
        let employeeRoles = vxm.referential.employeeRoles;
        if (!employeeRoles || employeeRoles.length === 0) {
            const employeeRolesCall = await referentialApi.getAllEmployeeRoles();
            if (isCallValidAndNotCancelled(employeeRolesCall)) {
                employeeRoles = employeeRolesCall!.datas!;
            } else {
                employeeRoles = [];
            }
        }
        this.editedRoleFilters = employeeRoles.map((x) => {
            return {
                id: x.id.toString(),
                label: x.label,
                selected: false
            };
        });
    }

    async fetchEmployees(): Promise<void> {
        const employeeCall = await employeeApi.getAllBase();
        let employees: IEmployee[] = [];
        if (isCallValidAndNotCancelled(employeeCall)) {
            employees = employeeCall!.datas!;
        }
        this.editedEmployeeFilters = employees.map((x) => {
            return {
                employeeId: x.id,
                trigram: x.trigram,
                selected: false
            };
        }).sort((x, y) => 
            x.trigram == null || x.trigram.length === 0 
                ? 1 
                : y.trigram == null || y.trigram.length === 0 
                    ? -1 
                    : x.trigram.localeCompare(y.trigram));
    }

    selectAllEmployees(value: boolean): void {
        this.employeeFiltersToShow.forEach((x) => {
            x.selected = value;
        });
    }

    selectAll(value: boolean): void {
        this.elementFiltersToShow.forEach((x) => {
            x.selected = value;
        });
    }

    isRoleInCurrentProject(id: string): boolean {
        return this.rolesOfProject.some((x) => x.id === +id);
    }

    isEmployeeInCurrentProject(employeeFilter: IEmployeeFilter): boolean {
        return this.employeeIdsOfProject.some((x) => x === employeeFilter.employeeId);
    }

    submitFilter(): void {
        this.$emit('filter-update', {
            employeeIds: this.selectedEmployeeFilters,
            roles: this.selectedRoleFilters,
            studios: this.selectedStudioFilters,
            projects: this.selectedProjectFilters,
        });
    }

    cancel(): void {
        this.$emit('cancel-filter');
    }

    get elementFiltersToShow() {
        if (this.type === 'ROL') return this.employeeRoleFiltersToShow;
        if (this.type === 'STU') return this.editedStudioFilters;
        if (this.type === 'PRO') return this.editedProjectFilters;
        return [];
    }

    get employeeRoleFiltersToShow(): IElementFilter[] {
        if (this.forCurrentProjectOnly) {
            return this.editedRoleFilters.filter(x => this.isRoleInCurrentProject(x.id));
        }
        return this.editedRoleFilters;
    }

    get employeeFiltersToShow(): IEmployeeFilter[] {
        if (this.forCurrentProjectOnly) {
            return this.editedEmployeeFilters.filter(x => this.isEmployeeInCurrentProject(x));
        }
        return this.editedEmployeeFilters;
    }

    get selectedEmployeeFilters(): IEmployeeFilter[] {
        return this.editedEmployeeFilters.filter((x) => x.selected);
    }

    get everyEmployeeSelected(): boolean {
        return this.editedEmployeeFilters.every((x) => x.selected);
    }

    get noEmployeeSelected(): boolean {
        return this.editedEmployeeFilters.every((x) => !x.selected);
    }

    get selectedRoleFilters(): IElementFilter[] {
        return this.editedRoleFilters.filter((x) => x.selected);
    }

    get selectedStudioFilters(): IElementFilter[] {
        return this.editedStudioFilters.filter((x) => x.selected);
    }

    get selectedProjectFilters(): IElementFilter[] {
        return this.editedProjectFilters.filter((x) => x.selected);
    }

    get allSelected(): boolean {
        return this.elementFiltersToShow.every((x) => x.selected);
    }

    get noSelected(): boolean {
        return this.elementFiltersToShow.every((x) => !x.selected);
    }
}
