
import { IAgency } from '@/entity/project/agency';
import { IProject } from '@/entity/project/project';
import { projectApi } from '@/wapi/project-api';
import { agencyApi } from '@/wapi/agency-api';
import { studioApi } from '@/wapi/studio-api';
import { clientApi } from '@/wapi/client-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputElement from '@c/shared/input-element.vue';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
import ContractBase from '@c/contract/contract-base.vue';
import PurchaseOrderBase from '@c/purchase-order/purchase-order-base.vue';
import InvoiceBase from '@c/invoice/invoice-base.vue';
import FeeBase from '@c/fee/fee-base.vue';
import { IUser } from '@/entity/shared/user';
import { IStudio } from '@/entity/project/studio';
import { IClient } from '@/entity/shared/client';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { IContract } from '@/entity/contract/contract';
import { IBackgroundImage } from '@/entity/shared/background-image';
import { appTokenMgr, IEmployeeAppRoleManager } from '@t/employee-app-role';

@Component({
    components: {
        InputElement,
        InputElementMgt,
        InvoiceBase,
        ContractBase,
        PurchaseOrderBase,
        FeeBase
    }
})
export default class ProjectInformation extends Vue {
    @Prop({}) id!: string;

    private agencies: NU<IAgency[]> = [];
    private studios: NU<IStudio[]> = [];
    private clients: NU<IClient[]> = [];
    private backgroundImages: NU<IBackgroundImage[]> = [];
    private image: string = '';
    private targetFile: any = {};
    private imagePlaceholder: string = "Sélectionnez l'image";

    private advancementFormatter: string = '0,00';
    private advancementItem: number = 0;
    private localProjectId = localStorage.getItem('selected-project');

    // contract and invoice
    tabIndex: number = 0;

    componentKey:number= 0;

    // fees
    tabIndexFees: number = 0;

    initGetLoadedProjectManagerIds: NU<string> = null;

    initGetStudioManagerId: NU<string> = null;

    initGetProjectReferenceId: NU<string> = null;

    get currentProject(): NU<IProject> {
        return vxm.project.projectData;
    }

    get isGenerated(): boolean {
        const invoicesList = vxm.project.invoicesList;
        if (invoicesList) {
            return invoicesList.some(invoice => invoice.isGenerated);
        }
        return false;
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    get base64ToImg(): string {
        if (this.image) {
            return this.image;
        } else if (
            this.currentProject &&
            this.currentProject.backgroundImage &&
            this.currentProject.backgroundImage.img
        ) {
            return 'data:image/jpg;base64,' + this.currentProject.backgroundImage.img;
        }
        return '';
    }

    @Watch('currentProject.backgroundImage')
    private assignBackgroundImageId(newVal: IBackgroundImage, _oldVal: IBackgroundImage) {
        if (this.currentProject) {
            // URL.revokeObjectURL(this.image);
            if (newVal && newVal.id !== 0) {
                const base64Img = this.backgroundImages?.find((x) => x.id === newVal.id);
                if (base64Img) {
                    this.currentProject.backgroundImage = base64Img;
                    const baseString = base64Img.img.trim();
                    if (baseString.substring(0, 4) !== 'data') {
                        this.image = 'data:image/jpg;base64,' + baseString;
                    }
                }
            }
        }
    }

    private async addImage(): Promise<void> {
        if (this.targetFile && this.targetFile.name) {
            const imageToSend: IBackgroundImage = {
                img: this.image.substring(this.image.indexOf(',') + 1),
                id: 0,
                title: this.targetFile.name,
                type: 'image/' + this.targetFile.name.split('.').pop()
            };
            if (!this.backgroundImages?.includes(imageToSend)) {
                await projectApi.addBackgroundImage(imageToSend, Number(this.id));
            }
        }
    }

    private onChange(e): void {
        const file = e.target.files[0];
        this.targetFile = file;
        const reader = new FileReader();
        reader.onload = async () => {
            this.image = reader.result as string;
        };
        reader.readAsDataURL(this.targetFile);
    }

    @Watch('currentProject')
    private assignCurrentProject(newVal, _oldVal) {
        if (newVal) {
            this.initGetStudioManagerId = newVal.studioManagerId;
            this.initGetLoadedProjectManagerIds = newVal.projectManagerIds.join(',');
            this.initGetProjectReferenceId = newVal.projectReferenceId;
            this.componentKey += 1;
        } else {
            this.initGetStudioManagerId = '';
            this.initGetLoadedProjectManagerIds = '';
            this.initGetProjectReferenceId = '';
        }
    }

    @Watch('currentProject.isSale')
    private isSaleEvent(newVal: boolean, _oldVal: boolean) {
        vxm.project.updateIsSale(newVal);
    }

    private studioManager: IUser = {};
    private studioManagerEvent(event: CustomEvent) {
        this.studioManager = event.detail[0];
    }

    @Watch('studioManager')
    updateStudioManager(newVal: IUser, _oldVal: IUser): void {
        if (this.currentProject) {
            if (newVal) {
                this.currentProject.studioManagerId = newVal.id;
            } else {
                this.currentProject.studioManagerId = undefined;
            }
        }
    }

    get getStudioManagerId(): string | undefined | null {
        const result =
            this.currentProject?.studioManagerId && this.currentProject?.studioManagerId.length > 0
                ? this.currentProject?.studioManagerId
                : null;
        return result;
    }

    get isReadOnly(): boolean {
        return !vxm.user.isAdmin && !vxm.user.isDaf;
    }

    get isSoldCheckboxReadOnly(): boolean {
    return !vxm.user.isAdmin;
    }

    private projectReference: IUser = {};
    private projectReferenceEvent(event: CustomEvent) {
        this.projectReference = event.detail[0];
    }

    @Watch('projectReference')
    updateProjectReference(newVal: IUser, _oldVal: IUser): void {
        if (this.currentProject) {
            if (newVal) {
                this.currentProject.projectReferenceId = newVal.id;
            } else {
                this.currentProject.projectReferenceId = undefined;
            }
        }
    }

    get getProjectReferenceId(): NU<string> {
        const result =
            this.currentProject?.projectReferenceId && this.currentProject?.projectReferenceId.length > 0
                ? this.currentProject?.projectReferenceId
                : null;
        return result;
    }

    private projectManagers: IUser[] = [];

    private projectManagersEvent(event: CustomEvent) {
        this.projectManagers = event.detail;
    }

    @Watch('projectManagers')
    updateManagerList(newVal: IUser[], _oldVal: IUser[]): void {
        if (this.currentProject) {
            if (newVal) {
                this.currentProject.projectManagerIds = newVal.filter((x) => x && x.id).map((x) => x.id) as string[];
            } else {
                this.currentProject.projectManagerIds = [];
            }
        }
    }

    @Watch('$route.params.id')
    updateProject (newVal, _oldVal): void {
        if (newVal) {
            vxm.app.changeTitleMain('Projets');
            vxm.app.changeTitleExt('Informations projet');
            vxm.project.fetchProject(newVal);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getValidationState({ dirty, validated, valid = null }): boolean | null {
        return dirty || validated ? valid : null;
    }

    get resultToDate(): number {
        const invoices = vxm.project.invoicesList;
        const contracts = vxm.project.contractsList;
        const invoicesTotalExcTax = invoices
            ?.filter((x) => x.contractId !== null && contracts?.find((y) => y.id === x.contractId))
            ?.map((x) => x.totalExcTax);
        let amount = 0;
        if (invoicesTotalExcTax && invoicesTotalExcTax.length === 1) {
            amount = invoicesTotalExcTax[0] ?? 0;
        } else if (invoicesTotalExcTax && invoicesTotalExcTax.length > 1) {
            amount = invoicesTotalExcTax.reduce((a: number, b: number) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)) ?? 0;
        }
        return amount;
    }

    get resultToDateDisplay(): string {
        return `${new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(this.resultToDate)}`;
    }

    get resultToDatePercentage(): number {
        const contracts = vxm.project.contractsList;
        const sumTotalExcTax = Number(
            (contracts?.map((x: IContract) => x.totalExcTax).reduce((x: number, y: number) => x + y, 0) ?? 0).toFixed(2)
        );
        const percentage = Number(
            (Number(this.resultToDate) > 0 && sumTotalExcTax > 0
                ? (Number(this.resultToDate) / sumTotalExcTax) * 100
                : 0
            ).toFixed(2)
        );
        return percentage;
    }

    get resultToDatePercentageDisplay(): string {
        return `${new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(this.resultToDatePercentage)}`;
    }

    get groupPickerId() {
        if (process.env.NODE_ENV === 'production') {
            return 'e59b07b5-a6f9-4040-b817-bad6fe2f2f1b';
        }
        return null;
    }

    async saveProject(): Promise<void> {
        if (this.currentProject) {
            const callData = await projectApi.patchBase(this.currentProject.id + '', this.currentProject);
            if (isCallValidAndNotCancelled(callData)) {
                await vxm.project.updateProject(this.id);
                await vxm.project.fetchDropDownProjectOptions();
                if (vxm.project.dropdownProject?.id === this.currentProject?.id) {
                    await vxm.project.updateSelectedProject(this.currentProject?.id + '');
                    await this.addImage();
                }
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: `Projet: ${this.currentProject.designation}`,
                    variant: 'success',
                    solid: true
                });
            }
        }
    }

    async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Projets');
        vxm.app.changeTitleExt('Informations projet');
        vxm.user.checkIsDaf();
        vxm.user.checkIsAdmin();

        const advancementCallData = await projectApi.getAdvancement(this.id);
        if (isCallValidAndNotCancelled<number>(advancementCallData)) {
            this.advancementItem = advancementCallData?.datas ?? 0;
            this.advancementFormatter = new Intl.NumberFormat('fr-FR', {
                style: 'percent',
                minimumFractionDigits: 2
            }).format(this.advancementItem / 100);
        }

        const bkgImageCallData = await projectApi.getAllBackgroundImages();
        if (isCallValidAndNotCancelled<IBackgroundImage[]>(bkgImageCallData)) {
            this.backgroundImages = bkgImageCallData?.datas;
        }

        const clientCallData = await clientApi.getAllByCode('CLT');
        if (isCallValidAndNotCancelled<IClient[]>(clientCallData)) {
            this.clients = clientCallData?.datas;
        }

        await this.getProjectData();

        this.initGetLoadedProjectManagerIds =
            this.currentProject &&
            this.currentProject.projectManagerIds &&
            this.currentProject.projectManagerIds.length > 0
                ? this.currentProject.projectManagerIds.join(',')
                : '';

        this.initGetStudioManagerId =
            this.currentProject?.studioManagerId && this.currentProject?.studioManagerId.length > 0
                ? this.currentProject?.studioManagerId
                : '';
                
        this.initGetProjectReferenceId =
            this.currentProject?.projectReferenceId && this.currentProject?.projectReferenceId.length > 0
                ? this.currentProject?.projectReferenceId
                : '';
    }

    async getProjectData(): Promise<void> {
        const agencyCallData = await agencyApi.getAllBase();

        if (isCallValidAndNotCancelled<IAgency[]>(agencyCallData)) {
            this.agencies = agencyCallData?.datas;
        }

        const studioCallData = await studioApi.getAllBase();

        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studios = studioCallData?.datas;
        }
        if (this.id != undefined) {
            vxm.project.updateSelectedProject(this.id + '');
            await vxm.project.fetchProject(this.id);
        }
        if (this.localProjectId && this.id == undefined) { 
            this.$router.push({ name: 'project-information', params: { id: this.localProjectId + '' } });
        }
    }
}
