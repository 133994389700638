import { currentServerConfiguration } from '@s/server-configuration';

class GroupNamingConventionManager {
    private readonly configuration;
    private readonly _startWith!: string;

    constructor() {
        this.configuration = currentServerConfiguration.getConfiguration();
        const sep = this.configuration.groupNamingConvention.separator;
        const pre = this.configuration.groupNamingConvention.prefix;
        if (sep == null || pre == null) throw new Error('A mandatory key is missing in configuration');
        this._startWith = `${pre}${sep}`;
    }

    public encodeGroupName(name: string): string {
        return `${this._startWith}${name}`;
    }

    public decodeGroupName(name: string) {
        if (this.isIndicateurGroup(name)) {
            return name.replace(this._startWith, '');
        }
        return null;
    }

    private isIndicateurGroup(name: string): boolean {
        return name.startsWith(this._startWith);
    }    
}
export const groupNamingConventionManager: GroupNamingConventionManager = new GroupNamingConventionManager();
