
import { getWeeksInMonth, getDaysInMonth, getDay, sub, isAfter, isEqual, isBefore } from 'date-fns';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CalendarDayPickerHeader from '@c/shared/calendar-day-picker/calendar-day-picker-header.vue';
import CalendarDayPickerData from '@c/shared/calendar-day-picker/calendar-day-picker-data.vue';
import { deepCopy } from '@t/object';
import { ICalendarHalfDay } from '@/entity/shared/calendar-day-picker';
import MonthPicker from '@c/shared/month-picker.vue';
import { vxm } from '@/store';

interface ISelectionHalfDay {
    dayNumber: number;
    isInCurrentMonth: boolean;
    isWeekEnd: boolean;
}

@Component({
    components: {
        CalendarDayPickerHeader,
        CalendarDayPickerData,
        MonthPicker
    }
})
export default class CalendarDayPicker extends Vue {
    @Prop({ default: null }) defaultMonth!: number | null;
    @Prop({ default: null }) defaultYear!: number | null;
    @Prop({ default: null }) defaultSelected!: Array<ICalendarHalfDay> | null;
    @Prop({ required: false, default: null }) minDate!: Date | null;
    @Prop({ required: false, default: null }) MaxDate!: Date | null;
    @Prop({ required: false, default: false }) isReadonly!: boolean;
    @Prop({ default: false }) onlyWeekDay!: boolean;

    private currentMonth: number = 0;
    private currentYear: number = 0;
    private loadCalendar: boolean = true;
    private selectedHalfDays: Array<ICalendarHalfDay> = [];

    private getCurrentDate(dayNumber : number): Date {
        return new Date(this.currentYear, this.currentMonth, dayNumber);
    }

    get currentYearMonth(): Date {
        return new Date(this.currentYear, this.currentMonth, 1);
    }

    private canBeSelected(item: ISelectionHalfDay): boolean {
        let res = true;
        if (this.minDate !== undefined && this.minDate !== null) {
            res = res && (isAfter(this.getCurrentDate(item.dayNumber),this.minDate) 
            || isEqual(this.minDate,this.getCurrentDate(item.dayNumber)));
        }
        if (this.MaxDate !== undefined && this.MaxDate !== null) {
            res = res && (isBefore(this.getCurrentDate(item.dayNumber),this.MaxDate)           
            || isEqual(this.MaxDate,this.getCurrentDate(item.dayNumber)));
        }
        return res;
    }

    @Watch('defaultMonth')
    onMonthChange(newVal: number, _oldVal: number): void {
        this.currentMonth = newVal;
    }

    @Watch('defaultYear')
    onYearChange(newVal: number, _oldVal: number): void {
        this.currentYear = newVal;
    }

    @Watch('defaultSelected')
    onDefaultSelectedChange(newVal: ICalendarHalfDay[], _oldVal: ICalendarHalfDay[]): void {
        if (newVal && newVal.length > 0) {
            this.loadCalendar = false;
            this.$nextTick(() => {
                this.selectedHalfDays = newVal;
                this.loadCalendar = true;
            });
        }
    }

    onPickerMonthChange(date: Date): void {
        this.currentYear = date.getFullYear();
        this.currentMonth = date.getMonth();
    }

    created(): void {
        const defaultDate: Date = new Date(
            this.defaultYear != null ? this.defaultYear : new Date().getFullYear(),
            this.defaultMonth != null ? this.defaultMonth : new Date().getMonth(),
            1
        );

        this.currentMonth = defaultDate.getMonth();
        this.currentYear = defaultDate.getFullYear();

        if (this.defaultSelected != null) {
            this.selectedHalfDays.push(...this.defaultSelected);
            this.$emit('collectionChanged', this.selectedHalfDays);
        }
    }

    get dayPartTypeMorning(): number {
        if (vxm.referential.dayPartTypes && vxm.referential.dayPartTypes.length > 0) {
            return vxm.referential.dayPartTypes.filter((x) => x.code === 'MOR')[0].id;
        }
        return -1;
    }

    get dayPartTypeAfternoon(): number {
        if (vxm.referential.dayPartTypes && vxm.referential.dayPartTypes.length > 0) {
            return vxm.referential.dayPartTypes.filter((x) => x.code === 'AFT')[0].id;
        }
        return -1;
    }

    async fetchReferential(): Promise<void> {
        await vxm.referential.fetchDayPartTypes();
    }

    async mounted(): Promise<void> {
        await this.fetchReferential();
    }

    get validDays () {
        if (this.onlyWeekDay === true) {
            return this.getMonthDays.filter(x => x.isWeekEnd === false);
        }
        return this.getMonthDays;
    }

    get getMonthDays(): Array<ISelectionHalfDay> {
        const d = this.startWeekday;
        const prevMonthDays = getDaysInMonth(sub(new Date(this.selectedMonthDate), { months: 1 }));
        const md: Array<ISelectionHalfDay> = [];
        let dayOfWeekNum: number;
        for (let i = 0; i < this.getWeeksInMonth * 7; ++i) {
            if (i < d) {
                const subDate = sub(new Date(this.selectedMonthDate), { months: 1 });
                dayOfWeekNum = new Date(subDate.getFullYear(), subDate.getMonth(), prevMonthDays - (d - i - 1)).getDay();
                md.push({
                    dayNumber: prevMonthDays - (d - i - 1),
                    isInCurrentMonth: false,
                    isWeekEnd: dayOfWeekNum === 6 || dayOfWeekNum === 0
                });
            } else if (i > d + (this.getDays - 1)) {
                dayOfWeekNum = new Date(this.currentYear, this.currentMonth, i - (this.getDays - 1 + d)).getDay();
                md.push({
                    dayNumber: i - (this.getDays - 1 + d),
                    isInCurrentMonth: false,
                    isWeekEnd: dayOfWeekNum === 6 || dayOfWeekNum === 0
                });
            } else {
                dayOfWeekNum = new Date(this.currentYear, this.currentMonth, i + 1 - d).getDay();
                md.push({
                    dayNumber: i + 1 - d,
                    isInCurrentMonth: true,
                    isWeekEnd: dayOfWeekNum === 6 || dayOfWeekNum === 0
                });
            }
        }
        return md;
    }

    private isMornSelected(day: ISelectionHalfDay): boolean {
        return (
            this.selectedHalfDays.filter(
                (x) =>
                    x.year === this.currentYear &&
                    x.day === day.dayNumber &&
                    x.month === this.currentMonth &&
                    x.dayPartId === this.dayPartTypeMorning
            ).length === 1
        );
    }

    private isAfterSelected(day: ISelectionHalfDay): boolean {
        const ret =
            this.selectedHalfDays.filter(
                (x) =>
                    x.year === this.currentYear &&
                    x.day === day.dayNumber &&
                    x.month === this.currentMonth &&
                    x.dayPartId === this.dayPartTypeAfternoon
            ).length === 1;
        return ret;
    }

    get selectedMonthDate(): Date {
        return new Date(this.currentYear, this.currentMonth, 1);
    }

    get getWeeksInMonth(): number {
        return getWeeksInMonth(this.selectedMonthDate, { weekStartsOn: 1 });
    }

    get getDays(): number {
        return getDaysInMonth(this.selectedMonthDate);
    }

    get startWeekday(): number {
        const v = getDay(this.selectedMonthDate);
        return v === 0 ? 6 : v - 1; // transform to check by monday, getDay only return index by sunday
    }

    private onDayStateChanged(dayChange: ICalendarHalfDay): void {
        if (dayChange.isSelected === false) {
            const resultSelection = this.selectedHalfDays.filter(
                (x) =>
                    x.year !== dayChange.year ||
                    x.day !== dayChange.day ||
                    x.month !== dayChange.month ||
                    (x.dayPartId != null && x.dayPartId !== dayChange.dayPartId)
            );
            if (this.selectedHalfDays.length !== resultSelection.length) {
                this.selectedHalfDays = resultSelection;
                this.$emit('collectionChanged', resultSelection);
            }
        } else {
            const copy: ICalendarHalfDay = deepCopy<ICalendarHalfDay>(dayChange);
            delete copy.isSelected;
            this.selectedHalfDays.push(copy);
            this.$emit('collectionChanged', this.selectedHalfDays);
        }
    }
}
