const numbers = {
    ones: {
        0: '',
        1: 'un',
        2: 'deux',
        3: 'trois',
        4: 'quatre',
        5: 'cinq',
        6: 'six',
        7: 'sept',
        8: 'huit',
        9: 'neuf',
    },
    tens: {
        0: { value: '' },
        1: { specialOnes: true, value: '' },
        2: { value: 'vingt' },
        3: { value: 'trente' },
        4: { value: 'quarante' },
        5: { value: 'cinquante' },
        6: { value: 'soixante' },
        7: { specialOnes: true, value: 'soixante' },
        8: { value: 'quatre-vingt' },
        9: { specialOnes: true, value: 'quatre-vingt' },
    },
    specialOnes: {
        0: 'dix',
        1: 'onze',
        2: 'douze',
        3: 'treize',
        4: 'quatorze',
        5: 'quinze',
        6: 'seize',
        7: 'dix-sept',
        8: 'dix-huit',
        9: 'dix-neuf',
    },
    thousands: ['', ' mille ', ' million ', ' milliards '],
};

function NumberPartToWord(value: string): string {
    if (value.length !== 3) {
        // We have a problem here
        const e: Error = { name: 'bad_argument', message: 'Invalid argument, value must have .length === 3' };
        throw e;
    }

    let str = '';

    str += value[0] !== '0' ? (value[0] !== '1' ? numbers.ones[value[0]] : '') + ' cent ' : '';
    str += numbers.tens[value[1]].value;
    if (numbers.tens[value[1]].specialOnes) {
        str += ' ' + numbers.specialOnes[value[2]];
    } else {
        if (value[1] !== '0' && value[2] !== '0') {
            if (value[2] === '1') {
                str += ' et ';
            } else {
                str += '-';
            }
        }
        str += numbers.ones[value[2]];
    }

    return str;
}

/**
 * Transforms a number into its french literal string
 * 
 * e.g. 2400 -> deux mille quatre-cent
 */
export function NumberToWord(value: number): string {
    let str = '';
    let currentValue = value.toString();
    if (value < 0) {
        currentValue = (-value).toString();
    }

    let cents = '';
    const vals = currentValue.split('.');
    if (vals.length > 1) {
        let centsStr = vals[1].substr(0, 2);
        centsStr = centsStr.padEnd(2, '0').padStart(3, '0');
        cents = ' et ' + NumberPartToWord(centsStr) + ' centimes';
    }
    currentValue = vals[0];
    if (currentValue === '0') {
        str += 'zéro ';
    }
    const maxIndex = (currentValue.length / 3);
    for (let index = 0; index < maxIndex; index++) {
        currentValue = currentValue.padStart(3, '0');
        const part = currentValue.substr(currentValue.length - 3, 3);
        const res = NumberPartToWord(part);
        if (res === 'un' && index === 1) {
            str = numbers.thousands[index] + (index > 1 && part !== '000' ? 's' : '') + str;
        } else {
            str = NumberPartToWord(part) + numbers.thousands[index] + (index > 1 && part !== '000' ? 's' : '') + str;
        }
        currentValue = currentValue.substr(0, currentValue.length - 3);
    }

    if (str.substr(str.length - 1, 1) !== ' ') {
        str += ' ';
    }
    str += 'euros';
    str += cents;

    return str;
}
