import { ISubcontract } from '@/entity/subcontracting/subcontract';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';

class SubcontractApi extends ApiBase<ISubcontract> {
    /**
     *
     */
    constructor(urlBase: string = 'api/subcontracts') {
        super(urlBase);
    }

    public async getAllSubcontractsByProjectId(projectId: number): Promise<ICancellableResult<ISubcontract[]>> {
        return await ajaxCall.baseAjaxCall<ISubcontract[]>('get', this.url, {
            urlParameter: ['getsubcontracts'],
            queryParameter: { projectId: projectId + '' }
        });
    }

    public async patchSubcontracts(data: ISubcontract[], projectId: string): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, ISubcontract[]>('patch', this.url, {
            urlParameter: ['savesubcontracts', projectId],
            payload: data
        });
    }

    public async exportSubContractData(data: number): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, number>('post', this.url, {
            payload: data,
            urlParameter: ['exportSubContractData'],
            responseType: 'blob'
        });
    }
}

export const subcontractApi: SubcontractApi = new SubcontractApi();
