import { IElementFilter } from '@c/project-planification/affectations/filter-entities';

const FILTER_CACHE_FIELD: string = 'FILTER_CACHE_ITEM';

export interface IFilterCache {
    roles: IElementFilter[];
    studios: IElementFilter[];
    projects: IElementFilter[];
    employeeIds: string[];
}

class FilterCacheService {
    getFilters(): IFilterCache | null {
        const filterCache: string | null = localStorage.getItem(FILTER_CACHE_FIELD);
        if (filterCache !== null && filterCache !== '') {
            const objectFilter: IFilterCache = JSON.parse(filterCache);
            return objectFilter ?? null;
        }
        return null;
    }

    saveFilters(roles: IElementFilter[], employeeIds: string[], studios: IElementFilter[], projects: IElementFilter[]): IFilterCache {
        const current: IFilterCache = {
            employeeIds: employeeIds,
            roles: roles,
            studios: studios,
            projects: projects,
        };
        localStorage.setItem(FILTER_CACHE_FIELD, JSON.stringify(current));
        return current;
    }
}

export const filterCacheService = new FilterCacheService();
