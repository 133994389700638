
import Vue from 'vue';
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';

@Component
export default class CurrencyInput extends Vue {
    @Prop({ required: false }) inputId!: string;
    @Prop({ required: true }) disabled!: boolean;
    @Prop({ required: false }) isLazyFormatter!: boolean;
    @VModel({ required: true }) valueBind!: number;
    displayInput: string = '0,00';

    mounted(): void {
        if (this.valueBind !== undefined) {
            this.displayInput = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(this.valueBind);
        } else {
            this.displayInput = '0,00';
        }
    }

    @Watch('valueBind')
    assignValueBind(newVal: number, _oldVal: number): void {
        if (newVal !== undefined) {
            this.displayInput = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(newVal);
        } else {
            this.displayInput = '0,00';
        }
    }

    private numberFormat(value: string): string {
        if (value) {
            const valueToSet = Number(value.replace(',', '.').replace(/\s/g, '').replace('€', ''));
            if (isNaN(valueToSet) === false) {
                this.valueBind = valueToSet;
                return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(valueToSet);
            } else {
                this.valueBind = 0;
            }
        }
        return '0,00';
    }
}
