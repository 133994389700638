import { IContact } from '@/entity/shared/contact';
import { ISupplier } from '@/entity/shared/supplier';
import ApiBase from '@api/api-base';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

class SupplierApi extends ApiBase<ISupplier> {
    /**
     *
     */
    constructor(urlBase: string = 'api/suppliers') {
        super(urlBase);
    }

    public async createSupplier(data: ISupplier): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, ISupplier>(this.url, data);
    }

    public async getAllByCode(code: string): Promise<ICancellableResult<ISupplier[]> | null> {
        return await ajaxCall.get<ISupplier[]>(this.url, { urlParameter: 'code', code });
    }

    public async getByIdAndCode(id: string, code: string): Promise<ICancellableResult<ISupplier> | null> {
        return await ajaxCall.baseAjaxCall<ISupplier>('get', this.url, { urlParameter: [id], queryParameter: { code } });
    }
    
    public async createContactInADGroup(data: IContact[], supplierId: number): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IContact[]>('post', this.url, { urlParameter: [supplierId + '', '/contacts'], payload: data });
    }

    public async removeContactInADGroup(data: IContact[], supplierId: number): Promise<ICancellableResult<void>> {
        return await ajaxCall.baseAjaxCall<void, IContact[]>('patch', this.url, {
            urlParameter: [supplierId + '', '/contacts'],
            payload: data
        });
    }

    public async exportSupplierData(data: number[], sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                SupplierIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportSupplierData'],
            responseType: 'blob'
        });
    }
}

export const supplierApi: SupplierApi = new SupplierApi();
