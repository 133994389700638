
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { imputationApi } from '@/wapi/imputation-api';
import { vxm } from '@/store';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { ICrossProjectImputationRecap, ICrossProjectProfileUnaffectedRecap, IProjectProfileUnaffectedRecap, IProjectRecap } from '@/entity/rh/imputation-recap';
import { NU } from '@t/type';
import { IEmployeeRole } from '@/entity/shared/referential';
import AffectationFilter from './affectation-filter.vue';
import { filterCacheService, IFilterCache } from '@/tools/filter-cache-service';
import { IElementFilter, IEmployeeFilter, IRoleFilter } from './filter-entities';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { IEmployee } from '@/entity/shared/employee';
import { employeeApi } from '@/wapi/employee-api';
import { IStudio } from '@/entity/project/studio';
import { studioApi } from '@/wapi/studio-api';
import { appTokenMgr } from '@t/employee-app-role';
import { moduleApiGraph } from '@t/module-api-graph';
import { planificationApi } from '@/wapi/planification-api';

interface RadioOption {
    text: string;
    value: number;
    code: string;
}

interface ICellData {
    value: number;
}

interface UserRowCell extends ICellData {
    isValid: boolean;
    date: Date;
    role?: NU<string>;
}

interface IDictionary<T> {
    [key: string]: string | boolean | T | NU<string>;
}

interface TableRow<T> extends IDictionary<T> {
    name: string;
    employeeId?: NU<string>;
    profileRoleId?: NU<string>;
    isUserRow: boolean;
    isGroupRow: boolean;
}

interface IProjectTrigrams {
    id: number;
    trigram: string;
    projectStudioId?: number;
}

@Component({
    components: {
        AffectationFilter
    }
})
export default class Affectations extends Vue {
    @Prop({ required: true })
    private employeeIds!: string[];

    @Prop({ required: false })
    private allocationsCompareShown!: boolean;

    @Prop({ required: false })
    private filterCriteria!: string;

    getEmployeeName (id: any) : string {
        const employee = this.allEmployees.find(e => e.id === id);
        if (employee !== null && employee !== undefined) {
            if (employee.employeeLastName && employee.employeeFirstName) {
                if (employee.trigram) {
                    return employee.trigram + ' - ' + employee.employeeFirstName + ' ' + employee.employeeLastName;
                }
                else return employee.employeeFirstName + ' ' + employee.employeeLastName;
            }
            else if (employee.employeeDisplayName) {
                if (employee.trigram) {
                    return employee.trigram + ' - ' + employee.employeeDisplayName;
                }
                else return employee.employeeDisplayName;
            }
            else { 
                return "Nom de l'utilisateur introuvable";
            }
        }
        else { 
            return 'Utilisateur introuvable';
        }
    }

    getEmployeeTrigram (id: any) : string {
        const employee = this.allEmployees.find(e => e.id === id);
        if (employee !== null && employee !== undefined) {
            if (employee.trigram) {
                return employee.trigram;
            }
            else return '';
        }
        else return '';
    }

    private loading: boolean = false;
    private excelLoading: boolean = false;
    private showFilters: boolean = false;
    private currentDurationFilterId: number = 1;
    private currentDisplayTableOptionId: number = 1;
    private projectFilter: NU<IFilterCache> = null;
    private imputationRecap: ICrossProjectImputationRecap[] = [];
    private profileUnaffectedRecap: ICrossProjectProfileUnaffectedRecap[] = [];
    allEmployees: IEmployee[] = [];
    private employeesLeavesValidatedRecap: ICrossProjectImputationRecap[] = [];
    private isAdmin: boolean = true;
    private isStudioManager: boolean = false;
    studios: NU<IStudio[]> = [];
    private selectedStudio: NU<number> = null;
    private projectsTrigrams: IProjectTrigrams[] = [];
    private projectsTrigramsProfileUnaffected: IProjectTrigrams[] = [];
    private durationFilters: RadioOption[] = [
        {
            value: 1,
            code: '6M',
            text: '6 Mois'
        },
        {
            value: 2,
            code: '6S',
            text: '6 Semaines'
        }
    ];

    private displayTableOptions = [
        {
            value: 1,
            code: 'EMP',
            text: 'Par Employé',
            columnHeader: 'Collaborateur',
        },
        {
            value: 2,
            code: 'ROL',
            text: 'Par profil',
            columnHeader: 'Rôle',
        },
        {
            value: 3,
            code: 'STU_INT',
            text: 'Par studio des intervenants',
            columnHeader: 'Studio',
        },
        {
            value: 4,
            code: 'STU_PRO',
            text: 'Par studio des projets',
            columnHeader: 'Studio',
        },
        {
            value: 5,
            code: 'PRO',
            text: 'Par Projets',
            columnHeader: 'Projet',
        },
    ];

    created(): void {
        this.fetchData();
    }

    async mounted() {
        const studioCallData = await studioApi.getAllBase();
        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studios = studioCallData?.datas;
            if (this.studios) {
                this.studios.push({ id: 0, label: 'Tous' });
            }
        }

        if (this.filterCriteria === "project") {
            this.currentDisplayTableOptionId = 5;
        }
        this.isAdmin = appTokenMgr.isAdmin();
        this.isStudioManager = appTokenMgr.isStudioManager();
    }

    async fetchData(): Promise<void> {
        this.loading = true;
        this.imputationRecap = await this.fetchEmployeeImputations();
        this.profileUnaffectedRecap = await this.fetchProfileUnaffectedRecap();
        this.projectsTrigrams = this.imputationRecap
            .flatMap(x => x.recaps)
            .map(x => ({ id: x.project.id, trigram: x.project.trigram, projectStudioId: x.project.studioId } as IProjectTrigrams))
            .reduce((acc: IProjectTrigrams[], e) => acc.some(x => x.id === e.id) ? acc : [...acc, e], []);

        this.projectsTrigramsProfileUnaffected = this.profileUnaffectedRecap
            .flatMap(x => x.recaps)
            .map(x => ({ id: x.project.id, trigram: x.project.trigram, projectStudioId: x.project.studioId} as IProjectTrigrams))
            .reduce((acc: IProjectTrigrams[], e) => acc.some(x => x.id === e.id) ? acc : [...acc, e], []);

        this.allEmployees = await this.fetchEmployees();
        this.employeesLeavesValidatedRecap = await this.fetchEmployeesLeavesValidated();
        this.projectFilter = filterCacheService.getFilters();
        this.loading = false;
    }

    rowClass(d: NU<TableRow<ICellData>>): string {
        if (d) {
            if (d.isGroupRow) {
                return 'headRow';
            } else if (d.isUserRow) {
                return 'userRow';
            } else if (d.name === '_') {
                return 'emptyRow';
            }
        }
        return '';
    }

    updateFilter(newFilter: { employeeIds: IEmployeeFilter[]; roles: IElementFilter[], studios: IElementFilter[], projects: IElementFilter[] }): void {
        this.projectFilter = {
            employeeIds: newFilter.employeeIds.map((x) => x.employeeId),
            roles: [...newFilter.roles],
            studios: [...newFilter.studios],
            projects: [...newFilter.projects],

        };
        filterCacheService.saveFilters(this.projectFilter.roles, this.projectFilter.employeeIds, this.projectFilter.studios, this.projectFilter.projects);
        this.showFilters = false;
    }

    cancelFilter(): void {
        this.showFilters = false;
    }

    onFilterShow(): void {
        this.showFilters = true;
    }

    @Watch('currentDurationFilterId')
    filterOnChange(): void {
        this.fetchData();
    }

    @Watch('selectedStudio')
    filterStudio(): void {
        this.fetchData();
    }

    @Watch('selectedProjectId')
    onProjectChanged(): void {
        this.fetchData();
    }

    async fetchEmployees(): Promise<IEmployee[]> {
        // const employeeCall = await employeeApi.getAllBase();
        const employeeCall = await employeeApi.getEmployeeNameActive();
        let employees: IEmployee[] = [];
        if (isCallValidAndNotCancelled(employeeCall)) {
            employees = employeeCall!.datas!;
        }
        if (this.selectedStudio != null && this.selectedStudio !== 0 && this.currentDisplayTableOption?.code !== 'STU_PRO' && this.currentDisplayTableOption?.code !== 'PRO') {
            employees = employees.filter(emp => emp.studioId && emp.studioId === this.selectedStudio);
        }
        if (!this.isAdmin && this.isStudioManager) {
            await moduleApiGraph.Client.api('/me').get().then(value => {
                const employeesCurrent = employees.filter(emp => emp.studioId && emp.id === value.id.toUpperCase())[0];
                employees = employees.filter(emp => emp.studioId === employeesCurrent.studioId);
            });
        }

        const sortedEmployees = employees.sort((a, b) => {
            const nameA = a.trigram?.toLowerCase() || '';
            const nameB = b.trigram?.toLowerCase() || '';

            if (nameA < nameB) {
                return -1; 
            }
            if (nameA > nameB) {
                return 1; 
            }
            return 0; 
        }
        );
        return sortedEmployees;
    }

    async fetchEmployeesLeavesValidated(): Promise<ICrossProjectImputationRecap[]> {
        const today = new Date(this.beginDate);
        let limit: Date;
        if (this.currentDurationFilter?.code === '6M') {
            limit = new Date(today.getFullYear(), today.getMonth() + 6, 1);
        } else {
            limit = new Date(today);
            limit.setDate(today.getDate() - today.getDay() + 7 * 6);
        }
        const employeeLeavesValidatedRecap = await planificationApi.getEmployeesLeavesValidatedRecapBetweenDates(
            today,
            limit,
            this.currentDurationFilter?.code === '6M' ?? true
        );
        if (isCallValidAndNotCancelled(employeeLeavesValidatedRecap)) {
            return employeeLeavesValidatedRecap!.datas!;
        }
        return [];
    }

    async fetchEmployeeImputations(): Promise<ICrossProjectImputationRecap[]> {
        this.imputationRecap = [];
        const today = new Date(this.beginDate);
        let limit: Date;
        if (this.currentDurationFilter?.code === '6M') {
            limit = new Date(today.getFullYear(), today.getMonth() + 6, 1);
        } else {
            limit = new Date(today);
            limit.setDate(today.getDate() - today.getDay() + 7 * 6);
        }
        const imputationRecaps = await imputationApi.getCrossProjectRecapBetweenDates(
            today,
            limit,
            this.currentDurationFilter?.code === '6M' ?? true
        );
        if (isCallValidAndNotCancelled(imputationRecaps)) {
            return imputationRecaps!.datas!;
        }
        return [];
    }

    async fetchProfileUnaffectedRecap(): Promise<ICrossProjectProfileUnaffectedRecap[]> {
        const today = new Date(this.beginDate);
        let limit: Date;
        if (this.currentDurationFilter?.code === '6M') {
            limit = new Date(today.getFullYear(), today.getMonth() + 6, 1);
        } else {
            limit = new Date(today);
            limit.setDate(today.getDate() - today.getDay() + 7 * 6);
        }
        const profileUnaffectedRecap = await planificationApi.getCrossProjectProfileUnaffectedRecapBetweenDates(
            today,
            limit,
            this.currentDurationFilter?.code === '6M' ?? true
        );
        if (isCallValidAndNotCancelled(profileUnaffectedRecap)) {
            if (this.selectedStudio != null && this.selectedStudio !== 0 && profileUnaffectedRecap!.datas && this.currentDisplayTableOption?.code !== 'STU_PRO'
             && this.currentDisplayTableOption?.code !== 'PRO') {
                return profileUnaffectedRecap!.datas.filter(p => p.recaps.some(r => r.profileStudioId === this.selectedStudio));
            }
            return profileUnaffectedRecap!.datas!;
        }
        return [];
    }

    get columnsToDisplay(): Date[] {
        const result: Date[] = this.listOfPeriods.map((x) => x.begin);
        return result;
    }

    get allExistingRoles(): IEmployeeRole[] {
        return vxm.referential.employeeRoles ?? [];
    }

    get rolesOfProject(): IEmployeeRole[] {
        const result: IEmployeeRole[] = [];
        for (let imputationIndex = 0; imputationIndex < this.imputationRecap.length; imputationIndex++) {
            const curImputation = this.imputationRecap[imputationIndex];
            if (
                curImputation.role !== null &&
                result.every((x) => x.id !== curImputation.role.id) &&
                (this.selectedProjectId || curImputation.recaps.some((x) => x.project.id === this.selectedProjectId))
            ) {
                result.push(curImputation.role);
            }
        }
        return result;
    }

    get tableColumns(): IBootstrapTableColumn[] {
        return [
            {
                key: 'name',
                label: this.currentDisplayTableOption?.columnHeader,
                stickyColumn: true,
                thClass: 'headerClass'
            },
            ...this.columnsToDisplay.map((x) => {
                return {
                    key: this.genKey(x),
                    thClass: 'headerClass fixedColumn',
                    label: this.genLabel(x),
                    tdClass: (value: any, key: string, item: any) => [
                        this.getCellClass(value as ICellData, key, item as TableRow<ICellData>),
                        'alignRight'
                    ],
                    formatter: (item) => this.fomatCell(item as ICellData)
                } as IBootstrapTableColumn;
            })
        ];
    }

    genLabel(date: Date): string {
        if (this.currentDurationFilter?.code === '6M') {
            return date.toLocaleString('default', { month: 'long', year: '2-digit' });
        } else {
            const nextDate: Date = new Date(date);
            nextDate.setDate(date.getDate() + 6);
            return `${date.toLocaleString('default', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            })} - ${nextDate.toLocaleString('default', { day: '2-digit', month: '2-digit', year: '2-digit' })}`;
        }
    }

    fomatCell(item: ICellData | null): any {
        return item?.value ?? null;
    }

    isGuid(id: string) {
        const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return guidRegex.test(id);
    }

    getRoleName(id: string) {
        const idSplited = id.split(' / ');
        const roleLabel = this.allExistingRoles.find(r => r.id === Number(idSplited[0]))?.label;
        const studioLabel = this.studios?.find(s => s.id === Number(id.split('/')[1]))?.label;
        
        if (roleLabel && studioLabel) {
            return roleLabel + ' / ' + studioLabel;
        }
        else if (roleLabel) {
            return roleLabel;
        }
        else {
            return 'Nom de profil introuvable';
        }
    }

    getCellClass(value: ICellData, key: string, item: TableRow<ICellData>): string {
        if ((key && key === 'name') || !item.isUserRow || !value) {
            return '';
        }
        if (value) {
            return (value as UserRowCell).isValid ? 'cellSuccess' : 'cellError';
        }
        return '';
    }

    get displayEmployeeTable(): boolean {
        return this.currentDisplayTableOption?.code === 'EMP' ?? true;
    }

    get currentDurationFilter(): RadioOption | null {
        return this.durationFilters.find((x) => x.value === this.currentDurationFilterId) || null;
    }

    get currentDisplayTableOption(): RadioOption & { columnHeader: string } | null {
        return this.displayTableOptions.find((x) => x.value === this.currentDisplayTableOptionId) || null;
    }

    get beginDate(): Date {
        let currentDay: number;
        if (this.currentDurationFilter?.code === '6M') {
            currentDay = 1;
        } else {
            const today = new Date();
            currentDay = today.getDate() - today.getDay();
        }
        return new Date(new Date().getFullYear(), new Date().getMonth(), currentDay);
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    get listOfPeriods(): { begin: Date; end: Date }[] {
        const result: { begin: Date; end: Date }[] = [];
        let currentDate: Date = new Date(this.beginDate);
        let end: Date;
        if (this.currentDurationFilter?.code === '6M') {
            end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, 1);
        } else {
            end = new Date(currentDate);
            end.setDate(currentDate.getDate() - currentDate.getDay() + 7 * 6);
        }
        while (currentDate < end) {
            let nextGap: Date;
            if (this.currentDurationFilter?.code === '6M') {
                nextGap = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
            } else {
                nextGap = new Date(currentDate);
                nextGap.setDate(currentDate.getDate() + 7);
            }
            result.push({
                begin: currentDate,
                end: nextGap
            });
            currentDate = nextGap;
        }
        return result;
    }

    genKey(date: Date): string {
        if (this.currentDurationFilter?.code === '6M') {
            return `M${date.getMonth() + 1}/${date.getFullYear()}`;
        } else {
            return `D${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
    }

    get getMaxForPeriod(): number {
        return this.currentDurationFilter?.code === '6M' ? 40 : 10;
    }

    get filteredRecaps(): IProjectRecap[] {
        return this.imputationRecap
            .flatMap(x => x.recaps.map(y => {
                y.role = x.role;
                return y;
            }))
            .filter(x => this.filterRecap(x));
    }

    get employeesLeavesValidatedMapped(): IProjectRecap[] {
        return this.employeesLeavesValidatedRecap
            .flatMap(x => x.recaps.map(y => {
                y.employeeId = x.employeeId;
                return y;
            }));
    }

    get profileUnaffectedRecapsMapped(): IProjectProfileUnaffectedRecap[] {
        return this.profileUnaffectedRecap
            .flatMap(x => x.recaps.map(y => {
                y.profileRoleId = x.profileRoleId;
                return y;
            }));
    }

    private filterRecap(recap: IProjectRecap) {
        if (this.currentDisplayTableOption?.code === 'ROL') {
            return !recap.role || !this.projectFilter?.roles || this.projectFilter.roles.some((x) => +x.id === recap.role.id);
        } else if (this.currentDisplayTableOption?.code === 'STU_INT') {
            return !recap.employeeStudioId || !this.projectFilter?.studios || this.projectFilter.studios.some((x) => +x.id === recap.employeeStudioId);
        } else if (this.currentDisplayTableOption?.code === 'STU_PRO') {
            return !recap.project.studioId || !this.projectFilter?.studios || this.projectFilter.studios.some((x) => +x.id === recap.project.studioId);
        } else if (this.currentDisplayTableOption?.code === 'PRO') {
            return !recap.project.id || !this.projectFilter?.projects || this.projectFilter.projects.some((x) => +x.id === recap.project.id);
        } else {
            return !this.projectFilter?.employeeIds || this.projectFilter.employeeIds.some((x) => x === recap.employeeId);
        }
    }

    private getGroupingKey(recap: IProjectRecap) {
        if (this.currentDisplayTableOption?.code === 'ROL') {
            return recap.role?.id?.toString() ?? '0';
        } else if (this.currentDisplayTableOption?.code === 'STU_INT') {
            return recap.employeeStudioId?.toString() ?? '0';
        } else if (this.currentDisplayTableOption?.code === 'STU_PRO') {
            return recap.project?.studioId?.toString() ?? '0';
        } else if (this.currentDisplayTableOption?.code === 'PRO') {
            return recap.project?.id.toString();
        } else {
            return recap.employeeId;
        }
    }

    private getProfileUnaffectedGroupingKey(recap: IProjectProfileUnaffectedRecap) {
        if (this.currentDisplayTableOption?.code === 'PRO') {
            return recap.project.id.toString();
        } else 
        {
            return recap.profileRoleId.toString();
        }
    }

    private getGroupName(key: string) {
        if (this.currentDisplayTableOption?.code === 'ROL') {
            return this.allExistingRoles.find(r => r.id === +key)?.label;
        } else if (this.currentDisplayTableOption?.code === 'STU_INT') {
            return this.studios?.find(s => s.id === +key)?.label;
        } else if (this.currentDisplayTableOption?.code === 'STU_PRO') {
            return this.studios?.find(s => s.id === +key)?.label;
        } else if (this.currentDisplayTableOption?.code === 'PRO') {
            const projetTrigram = [...this.projectsTrigrams, ...this.projectsTrigramsProfileUnaffected].find(p => p.id === +key);
            if (projetTrigram) {
                // const projectStudioLabel = this.studios?.find(x => x.id === projetTrigram.projectStudioId)?.label;
                // if (projectStudioLabel) {
                //     return projetTrigram.trigram + ' / ' + projectStudioLabel;
                // }
                return projetTrigram.trigram;
            }
        } else {
            return key;
        }
    }

    removeDuplicates (originalArray:IProjectTrigrams[], prop : string): IProjectTrigrams[] {
        const newArray : IProjectTrigrams[] = [];
        const lookupObject = {};

        for (const item of originalArray) {
            lookupObject[item[prop]] = item;
        }

        for (const key in lookupObject) {
            newArray.push(lookupObject[key]);
        }

        return newArray;
    }

    private getFilteredElements() {
        if (this.currentDisplayTableOption?.code === 'ROL') {
            return this.projectFilter?.roles ?? this.allExistingRoles.map(x => ({ id: x.id.toString(), label: x.label }));
        } else if (this.currentDisplayTableOption?.code === 'STU_INT') {
            return this.projectFilter?.studios ?? this.studios?.filter(x => !!x.id).map(x => ({ id: x.id!.toString(), label: x.label ?? '' })) ?? [];
        } else if (this.currentDisplayTableOption?.code === 'STU_PRO') {
            return this.projectFilter?.studios ?? this.studios?.filter(x => !!x.id).map(x => ({ id: x.id!.toString(), label: x.label ?? '' })) ?? [];
        } else if (this.currentDisplayTableOption?.code === 'PRO') {
            if (this.projectFilter?.projects) {
                return this.projectFilter?.projects;
            }
            else {
                let combinedArray = [...this.projectsTrigrams, ...this.projectsTrigramsProfileUnaffected];

                if (this.selectedStudio != null && this.selectedStudio !== 0) {
                    const filteredProjectsTrigrams = this.projectsTrigrams.filter(p => p.projectStudioId === this.selectedStudio);
    
                    const filteredProjectsTrigramsProfileUnaffected = this.projectsTrigramsProfileUnaffected.filter(p => p.projectStudioId === this.selectedStudio);
    
                    combinedArray = [...filteredProjectsTrigrams, ...filteredProjectsTrigramsProfileUnaffected];
                }

                const uniqueArray = this.removeDuplicates(combinedArray, 'id').map(x => ({ id: x.id.toString(), label: x.trigram }));

                const uniqueSortedArray = uniqueArray.sort((a, b) => {
                    const nameA = a.label?.toLowerCase() || '';
                    const nameB = b.label?.toLowerCase() || '';

                    if (nameA < nameB) {
                        return -1; 
                    }
                    if (nameA > nameB) {
                        return 1; 
                    }
                    return 0; 
                });

                return uniqueSortedArray;
            }
        } else {
            if (this.projectFilter?.employeeIds?.map(x => ({ id: x, label: '' }))) {
                return this.projectFilter?.employeeIds?.map(x => ({ id: x, label: '' }));
            }
            else {
                const employeeElements = this.allEmployees.map(x => ({ id: x.id, label: '' }));
                const unaffectedRecapElements = this.profileUnaffectedRecap.map(x => ({ id: x.profileRoleId.toString(), label: '' }));

                return [...employeeElements, ...unaffectedRecapElements];
            }
        }
    }

    get tableData(): TableRow<ICellData>[] {
        const result: TableRow<ICellData>[] = [];
        const grouped: { [key: string]: { [id: string]: IProjectRecap[] } } = {};
        const groupedProfileUnaffectedRecaps: { [key: string]: { [id: string]: IProjectProfileUnaffectedRecap[] } } = {};

        for (const recap of this.employeesLeavesValidatedMapped) {
            const groupKey = this.getGroupingKey(recap);
            if (!grouped[groupKey]) {
                grouped[groupKey] = {};
            }
            const subId = recap.employeeId;       
            if (!grouped[groupKey][subId]) {
                grouped[groupKey][subId] = [];
            }
            grouped[groupKey][subId].push(recap);
        }
        for (const recap of this.filteredRecaps) {
            const groupKey = this.getGroupingKey(recap);
            if (!grouped[groupKey]) {
                grouped[groupKey] = {};
            }
            let subId: string;
            if (this.currentDisplayTableOption?.code !== 'EMP') {
                subId = recap.employeeId;
            } else {
                subId = recap.project.id.toString();
            }

            if (!grouped[groupKey][subId]) {
                grouped[groupKey][subId] = [];
            }
            grouped[groupKey][subId].push(recap);
        }

        for (const recap of this.profileUnaffectedRecapsMapped) {
            const groupKey = this.getProfileUnaffectedGroupingKey(recap);
            if (!groupedProfileUnaffectedRecaps[groupKey]) {
                groupedProfileUnaffectedRecaps[groupKey] = {};
            }
            let subId: string;
            if (this.currentDisplayTableOption?.code !== 'EMP') {
                subId = recap.profileRoleId.toString() + ' / ' + recap.profileStudioId.toString();
            } else {
                subId = recap.project.id.toString() + recap.profileStudioId?.toString();
            }

            if (!groupedProfileUnaffectedRecaps[groupKey][subId]) {
                groupedProfileUnaffectedRecaps[groupKey][subId] = [];
            }
            groupedProfileUnaffectedRecaps[groupKey][subId].push(recap);
        }

        for (const key of this.getFilteredElements().map(x => x.id)) {
            const groupRow = {
                name: this.getGroupName(key) ?? '',
                isGroupRow: true,
                isUserRow: this.currentDisplayTableOption?.code === 'EMP',
                trigram: ''
            };
            result.push(groupRow);

            if (grouped[key]) {
                let subIds = Object.keys(grouped[key]); 
                if (!this.isGuid(key)) {
                    subIds = subIds.sort((a, b) => {
                        const nameA = this.getEmployeeTrigram(a)?.toLowerCase() || '';
                        const nameB = this.getEmployeeTrigram(b)?.toLowerCase() || '';

                        if (nameA < nameB) {
                            return -1; 
                        }
                        if (nameA > nameB) {
                            return 1; 
                        }
                        return 0; 
                    });
                }

                for (const subId of subIds) {
                    const row = {
                        isGroupRow: false,
                        isUserRow: this.currentDisplayTableOption?.code !== 'EMP',
                        name: subId
                    };
                    if (this.currentDisplayTableOption?.code === 'EMP') {
                        if (!this.isGuid(subId)) {
                            const proj = grouped[key][subId][0].project;
                            row.name = proj.trigram;
                            const studio = this.studios?.find(s => s.id === grouped[key][subId][0].employeeStudioId);
                            if (studio) {
                                row.name += ` /  ${studio.label}`;
                            }
                        }
                        else {
                            row.name = 'Congé';
                        }
                    }
                    result.push(row);
                    for (const recap of grouped[key][subId]) {
                        const curKey = this.genKey(recap.fromDate);
                        if (!row[curKey]) {
                            row[curKey] = { value: 0 };
                        }
                        row[curKey].value += recap.quantity;

                        if (!groupRow[curKey]) {
                            const cell: UserRowCell = {
                                value: recap.quantity,
                                date: recap.fromDate,
                                isValid: true
                            };
                            groupRow[curKey] = cell;
                        } else {
                            groupRow[curKey].value += recap.quantity;
                        }
                    }
                }

                const rowKeys = Object.keys(groupRow).filter((x) => x.startsWith('M') || x.startsWith('D'));
                rowKeys.map((x) => {
                    const currentVal: UserRowCell = groupRow[x] as UserRowCell;
                    currentVal.isValid = currentVal.value <= this.getMaxForPeriod;
                });
            }
            if (groupedProfileUnaffectedRecaps[key]) {
                for (const subId of Object.keys(groupedProfileUnaffectedRecaps[key])) {
                    const row = {
                        isGroupRow: false,
                        isUserRow: this.currentDisplayTableOption?.code !== 'EMP',
                        name: subId,
                    };
                    if (this.currentDisplayTableOption?.code === 'EMP') {      
                        const proj = groupedProfileUnaffectedRecaps[key][subId][0].project;
                        row.name = proj.trigram;
                        const studio = this.studios?.find(s => s.id == groupedProfileUnaffectedRecaps[key][subId][0].profileStudioId);
                        if (studio) {
                            row.name += ` /  ${studio.label}`;
                        }
                        result.push(row);
                    }

                    if (this.currentDisplayTableOption?.code === 'PRO') {
                        const proj = groupedProfileUnaffectedRecaps[key][subId][0].project;
                        row.name = subId;
                        result.push(row);
                    }
                    for (const recap of groupedProfileUnaffectedRecaps[key][subId]) {
                        const curKey = this.genKey(recap.fromDate);
                        if (!row[curKey])
                            row[curKey] = { value: 0 };
                        row[curKey].value += recap.quantity;

                        if (!groupRow[curKey]) {
                            const cell: UserRowCell = {
                                value: recap.quantity,
                                date: recap.fromDate,
                                isValid: true
                            };
                            groupRow[curKey] = cell;
                        } else {
                            groupRow[curKey].value += recap.quantity;
                        }
                    }
                }

                const rowKeys = Object.keys(groupRow).filter((x) => x.startsWith('M') || x.startsWith('D'));
                rowKeys.map((x) => {
                    const currentVal: UserRowCell = groupRow[x] as UserRowCell;
                    currentVal.isValid = currentVal.value <= this.getMaxForPeriod;
                });
            }

            result.push({
                name: '_',
                isUserRow: false
            } as TableRow<ICellData>);
        }
        return result;
    }

    async exportToExcel(): Promise<void> {
        const today = new Date(this.beginDate);
        let limit: Date;
        if (this.currentDurationFilter?.code === '6M') {
            limit = new Date(today.getFullYear(), today.getMonth() + 6, 1);
        } else {
            limit = new Date(today);
            limit.setDate(today.getDate() - today.getDay() + 7 * 6);
        }
        const filter = this.getFilteredElements();
        const request = imputationApi.exportCrossProjectRecapBetweenDates(
            this.selectedProjectId ?? 0,
            today,
            limit,
            this.currentDurationFilter?.code === '6M' ?? true,
            this.currentDisplayTableOption?.code ?? 'EMP',
            filter,
        );
        await this.generateExcelFile(request, `Affectation_Ressources_${this.formatDate(new Date())}.xlsx`);
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateExcelFile(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.excelLoading = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.excelLoading = false;
        }
        setTimeout(() => {
            this.excelLoading = false;
        }, 1000);
    }
}
