
import Vue from 'vue';
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';

@Component
export default class PercentageInput extends Vue {
    @Prop({ required: false }) inputId!: string;
    @Prop({ required: true }) disabled!: boolean;
    @Prop({ required: false }) isLazyFormatter!: boolean;
    @Prop({ required: false }) noFractionDigit!: boolean;
    @VModel({ required: true }) valueBind!: number;
    displayInput: string = this.noFractionDigit ? '0' : '0,00';

    mounted(): void {
        if (this.valueBind !== undefined) {
            this.displayInput = new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: this.noFractionDigit ? 0 : 2 }).format(
                this.valueBind / 100
            );
        } else {
            this.displayInput = this.noFractionDigit ? '0 %' : '0,00 %';
        }
    }

    @Watch('valueBind')
    assignValueBind(newVal: number, _oldVal: number): void {
        if (newVal !== undefined) {
            this.displayInput = new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: this.noFractionDigit ? 0 : 2 }).format(
                newVal / 100
            );
        } else {
            this.displayInput = this.noFractionDigit ? '0 %' : '0,00 %';
        }
    }

    private numberFormat(value: string): string {
        if (value) {
            const valueToSet = Number(value.replace(',', '.').replace(/\s/g, '').replace('%', ''));
            if (isNaN(valueToSet) === false) {
                this.valueBind = this.noFractionDigit ? Number(valueToSet.toFixed(0)) : valueToSet;
                return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: this.noFractionDigit ? 0 : 2 }).format(
                    valueToSet / 100
                );
            } else {
                this.valueBind = 0;
            }
        }
        this.valueBind = 0;
        return this.noFractionDigit ? '0 %' : '0,00 %';
    }

    onBlur = (value: string): void => {
        this.$emit('blur', value);
    };
}
