
import { Component, Prop, Vue } from 'vue-property-decorator';
import ContractList from '@c/contract/contract-list.vue';
import ContractInformation from '@c/contract/contract-information.vue';
import { IContract } from '@/entity/contract/contract';

@Component({
    components: {
        ContractList,
        ContractInformation
    }
})
export default class ContractBase extends Vue {
    @Prop({ required: true }) projectId!: number;
    @Prop({ required: true, default: false }) isReadOnly!: boolean;

    selectedContractId: number = 0;

    public setContract(item: IContract): void {
        if (item) this.selectedContractId = item.id;
        else this.selectedContractId = 0;
    }
}
