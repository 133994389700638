
import { IWorkflowSummary } from '@/entity/rh/workflow';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UserLeaveToValidate extends Vue {
    @Prop({ required: true }) workflowLeaveListToValidate!: IWorkflowSummary[];
    @Prop({ required: true }) isCurrentUserInformations!: boolean;

    isVisible: boolean = false;

    private leaveToValidatefields: Array<IBootstrapTableColumn> = [
        {
            label: 'Employée',
            key: 'employeeId'
        },
        {
            label: 'Date de début',
            key: 'betweenMin',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '-'),            
        },
        {
            label: 'Date de fin',
            key: 'betweenMax',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' }) : '-'),            
        },
        {
            label: 'Total',
            key: 'total',
            formatter: (val: string, key?: string, item?) => val + ' demi journées'
        },
        {
            label: 'statut',
            key: 'status'
        }
    ];

    private openLeave(item: IWorkflowSummary[], _index: number, _event: Event): void {
        this.$router.push({
            name: 'leave-information-validation',
            params: { id: item[0].employeeId, leaveId: item[0].id + '' }
        });
    }
}
