
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IProject, IProjectBudgetAndCost } from '@/entity/project/project';
import CommentList from '@c/comment/comment-list.vue';
import ChartBase from '@/components/shared/charts/chart-base.vue';
import { IContractLine } from '@/entity/contract/contract-line';
import { contractApi } from '@/wapi/contract-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IManagementPhase } from '@/entity/management/management-phase';
import { managementApi } from '@/wapi/management-api';
import { purchaseOrderApi } from '@/wapi/purchase-order-api';
import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';
import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { planificationApi } from '@/wapi/planification-api';
import { IFee } from '@/entity/fee/fee';
import { feeApi } from '@/wapi/fee-api';
import { authModule } from '@t/session';
import { NU } from '@t/type';
import { projectApi } from '@/wapi/project-api';
import { isBefore } from 'date-fns';
import ColorStyles from '@/style/color.scss';
import { IEmployeeAppRoleManager, appTokenMgr } from '@t/employee-app-role';

@Component({
    components: {
        CommentList,
        ChartBase
    }
})
export default class Dashboard extends Vue {
    @Prop({ required: true })
    private project!: IProject;

    private userId!: string;

    private managementPhases: IManagementPhase[] = [];
    private regroupingPhases: IManagementPhase[] = [];
    private purchaseOrderList: IPurchaseOrder[] = [];
    private fees: IFee[] = [];
    private advancement: number = 0;
    private phaseAdvancement: IManagementPhase[] = [];

    private budgetAndCost: Array<IProjectBudgetAndCost> = [];

    private loading: boolean = false;
    // valeur total de taille de l'élément en width.
    private psize: number = -1;

    private mounted() {
        this.userId = authModule.getAccount()!.localAccountId!;
        this.updateProject(this.project);
        this.psize = (this.$refs.pgrid as HTMLElement).clientWidth;
    }

    get progressSize(): number {
        // calcul la taille du progress bar en retirant le padding,
        // en rajoutant un block de link car il n'y aura pas 10 link mais 9
        // en divisant par le nombre max d'élement et en enlevant
        // la taille du link entre 2 élement
        return (this.psize - 20) / 10 - 20 + 1;
    }

    get linkPositionTop(): string {
        // reprend la taille d'un élément calculé (width === height)
        // divise par 2 pour avoir la position centrale
        // retire la moitié de la taille du link (20 px / 2)
        return this.progressSize / 2 - 10 + 'px';
    }

    get phaseBlockSize(): string {
        return this.phaseAdvancement.length * this.progressSize + 20 * (this.phaseAdvancement.length - 1) + 'px';
    }

    get columnsCut(): string {
        if (this.psize === -1) return '100%';
        let val: string = '';
        for (let i: number = 0; i < this.phaseAdvancement.length; ++i) {
            if (i > 0) {
                val += ' ';
            }
            val += this.progressSize + 'px';
            if (i < this.phaseAdvancement.length - 1) {
                val += ' ' + '20px';
            }
        }
        return val;
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    @Watch('project')
    private async updateProject(newVal: IProject) {
        this.loading = true;
        await this.fetchRegroupingcontractlines(newVal.id);
        await this.fetchManagementPhases(newVal.id);
        await this.fetchPruchaseOrderList(newVal.id);
        await this.fetchBudgetAndCost(newVal.id);
        await this.fetchProjectFees(newVal.id);
        this.calculateTotalProgressAmount();
        this.calculateTotalBudget();
        this.calculateTotalContractLinesPrice();
        this.loading = false;
    }

    private redirect(path: string) {
        this.$router.push({
            path
        });
    }

    private changeColor(mp: IManagementPhase) {
        const av: IContractLine = mp.contractLine;
        switch (av.advancement) {
            case 100:
                return 'green';
            default: {
                if (mp.contractLine.planificationInContractL?.closingDate) {
                    if (isBefore(new Date(), new Date(mp.contractLine.planificationInContractL?.closingDate))) {
                        if (av.advancement === 0) {
                            return 'grey';
                        }
                        return ColorStyles.main;
                    } else {
                        return 'red';
                    }
                } else {
                    if (av.advancement === 0) {
                        return 'grey';
                    }
                    return ColorStyles.main;
                }
            }
        }
    }

    private async fetchAdvancements() {
        this.phaseAdvancement = (this.managementPhases as IManagementPhase[]).sort(
            (x: IManagementPhase, y: IManagementPhase) => {
                if (x.contractLine.planificationInContractL?.openingDate != null && y.contractLine.planificationInContractL?.openingDate) {
                    return isBefore(
                        new Date(x.contractLine.planificationInContractL.openingDate),
                        new Date(y.contractLine.planificationInContractL.openingDate)
                    )
                        ? -1
                        : 1;
                } else if (y.contractLine.planificationInContractL?.openingDate) {
                    return -1;
                } else {
                    return 1;
                }
            }
        );
    }

    private async fetchBudgetAndCost(projectId: number | null) {
        const result = await projectApi.getBudgetAndCost(projectId);
        if (isCallValidAndNotCancelled(result)) {
            this.budgetAndCost = result.datas!;
        }
    }

    private async fetchProjectFees(projectId: number) {
        this.fees = [];
        const result = await feeApi.getAllByProjectId(projectId + '', this.userId);
        if (isCallValidAndNotCancelled(result)) {
            this.fees = result.datas!;
        }
    }

    private async fetchManagementPhases(projectId: number) {
        this.managementPhases = [];
        const result = await managementApi.getAllPhasesByProjectId(projectId);
        if (isCallValidAndNotCancelled(result)) {
            this.managementPhases = result.datas!;
            this.fetchAdvancements();
        }
    }

    private async fetchRegroupingcontractlines(projectId: number) {
        this.managementPhases = [];
        const result = await managementApi.getAllPhasesByProjectId(projectId);
        if (isCallValidAndNotCancelled(result)) {
            this.regroupingPhases = result.datas!;
        }
    }

    private async fetchPruchaseOrderList(projectId: number) {
        this.purchaseOrderList = [];
        const result = await purchaseOrderApi.getAllByProjectId(projectId + '');
        if (isCallValidAndNotCancelled(result)) {
            this.purchaseOrderList = result.datas!;
        }
    }

    private progressAmount(management: IManagementPhase): number {
        if (management && management.contractLine) {
            return management.planificationPhaseCostValue;
        }
        return 0;
    }

    private getBudget(management: IManagementPhase): number {
        if (management && management.contractLine) {
            return Number(((management.regroupingTotal !== 0 ? management.regroupingTotal : management.contractLine.total) - (management.contractLine.discount ?? 0) - management.subContractsValue - ((management.regroupingTotal / 100) * management.contractLine.marginPercentage)).toFixed(0));
        }
        return 0;
    }

    get getRowCount(): number {
        return 3;
    }

    get getBackgroundColor(): string {
        return ColorStyles['background-grey-alpha-light'];
    }

    get colorStyles(): unknown {
        return ColorStyles;
    }

    get totalFees(): number {
        if (this.fees.length > 0) {
            return this.fees.map((x) => x.fees).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get currentProjectCost(): number | null {
        const p = this.budgetAndCost.find((x) => x.id === this.project.id);
        if (p != null) {
            return p.cost;
        }
        return 0;
    }

    get currentProjectBudget(): number | null {
        const p = this.budgetAndCost.find((x) => x.id === this.project.id);
        if (p != null) {
            return p.budget;
        }
        return 0;
    }

    get totalImputationValue(): number {
        if (this.managementPhases.length > 0) {
            return this.managementPhases.map((x) => x.consumedRatesValue).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalProvisionnalFee(): number {
        if (this.managementPhases.length > 0) {
            return this.managementPhases.map((x) => x.provisionalFeesValue).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalPurchaseOrders(): number {
        if (this.purchaseOrderList.length > 0) {
            return this.purchaseOrderList.map((x) => x.totalExcTax).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalSubContract(): number {
        if (this.managementPhases.length > 0) {
            return this.managementPhases.map((x) => x.subContractsValue).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get advancementFormatter(): string {
        if (this.advancement) {
            return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(
                this.advancement / 100
            );
        }
        return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(0 / 100);
    }

    private canEdit(): boolean {
        return (
            appTokenMgr.isAdmin() ||
            appTokenMgr.isDaf() ||
            appTokenMgr.isAssociate() ||
            appTokenMgr.isCP() ||
            appTokenMgr.isStudioManager()
        );
    }

    updateTotalProgressAmount(value: NU<number> = null): void {
        this.$emit('total-progress-amount-changed', value);
    }

    updateTotalBudget(value: NU<number> = null): void {
        this.$emit('total-budget-changed', value);
    }

    updateTotalContractLinesPrice(value: NU<number> = null): void {
        this.$emit('total-contract-lines-price-changed', value);
    }

    calculateTotalProgressAmount(): void {
        if (this.managementPhases.length > 0) {
            this.updateTotalProgressAmount(
                this.managementPhases.map((x) => this.progressAmount(x)).reduce((x, y) => x + y)
            );
        } else {
            this.updateTotalProgressAmount(0);
        }
    }

    calculateTotalBudget(): void {
        if (this.regroupingPhases.length > 0) {
            this.updateTotalBudget(this.managementPhases.map((x) => this.getBudget(x)).reduce((x, y) => x + y));
        } else {
            this.updateTotalBudget(0);
        }
    }

    calculateTotalContractLinesPrice(): void {
        if (this.managementPhases.length > 0) {
            this.updateTotalContractLinesPrice(
                this.managementPhases.map((x) => x.contractLine.total ?? 0).reduce((prev, next) => prev + next)
            );
        } else {
            this.updateTotalContractLinesPrice(0);
        }
    }
}
