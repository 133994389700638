
import { Component, Prop, Vue } from 'vue-property-decorator';
import FeeList from '@c/fee/fee-list.vue';
import { IFee } from '@/entity/fee/fee';

@Component({
    components: {
        FeeList,
    }
})
export default class FeeBase extends Vue {
    @Prop({ required: true }) projectId!: number;

    selectedFeeId: number = 0;

    public setFee(item: IFee): void {
        if (item) this.selectedFeeId = item.id;
        else this.selectedFeeId = 0;
    }
}
