import { IStudio } from '@/entity/project/studio';
import ApiBase from '@api/api-base';

class StudioApi extends ApiBase<IStudio> {
    /**
     *
     */
    constructor(urlBase: string = 'api/studios') {
        super(urlBase);
    }
}

export const studioApi: StudioApi = new StudioApi();
