import { render, staticRenderFns } from "./input-element-mgt.vue?vue&type=template&id=323cd10e&scoped=true&"
import script from "./input-element-mgt.vue?vue&type=script&lang=ts&"
export * from "./input-element-mgt.vue?vue&type=script&lang=ts&"
import style0 from "./input-element-mgt.vue?vue&type=style&index=0&id=323cd10e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323cd10e",
  null
  
)

export default component.exports