import { ILeaveWorkflowItem, IOverTimeWorkflowItem, IRealizedOverTimeWorkflowItem, IWorkflow, IWorkflowSummary } from '@/entity/rh/workflow';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

class WorkflowApi {
    url: string = 'api/users';

    public async getLeaveWorkflowById(userId: string, workflowId: number): Promise<ICancellableResult<IWorkflow<ILeaveWorkflowItem>>> {
        return await ajaxCall.baseAjaxCall<IWorkflow<ILeaveWorkflowItem>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'leave', String(workflowId)]
        });
    }

    public async getOverTimeWorkflowById(userId: string, workflowId: number): Promise<ICancellableResult<IWorkflow<IOverTimeWorkflowItem>>> {
        return await ajaxCall.baseAjaxCall<IWorkflow<IOverTimeWorkflowItem>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'overtime', String(workflowId)]
        });
    }

    public async getRealizedOverTimeWorkflowById(userId: string, workflowId: number): Promise<ICancellableResult<IWorkflow<IRealizedOverTimeWorkflowItem>>> {
        return await ajaxCall.baseAjaxCall<IWorkflow<IRealizedOverTimeWorkflowItem>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'realizedovertime', String(workflowId)]
        });
    }

    public async getLeaveWorkflows(userId: string): Promise<ICancellableResult<Array<IWorkflowSummary>>> {
        return await ajaxCall.baseAjaxCall<Array<IWorkflowSummary>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'leaves']
        });
    }

    public async getOverTimeWorkflows(userId: string): Promise<ICancellableResult<Array<IWorkflowSummary>>> {
        return await ajaxCall.baseAjaxCall<Array<IWorkflowSummary>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'overtimes']
        });
    }

    public async GetTeleworkingCount(userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number>('get', this.url, {
            urlParameter: [userId, 'workflows', 'GetTeleworkingCount']
        });
    }

    public async getRealizedOverTimeWorkflows(userId: string): Promise<ICancellableResult<Array<IWorkflowSummary>>> {
        return await ajaxCall.baseAjaxCall<Array<IWorkflowSummary>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'realizedovertimes']
        });
    }

    public async getLeaveWorkflowsToValidate(userId: string): Promise<ICancellableResult<Array<IWorkflowSummary>>> {
        return await ajaxCall.baseAjaxCall<Array<IWorkflowSummary>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'listleavetovalidate']
        });
    }

    public async getOverTimeWorkflowsToValidate(userId: string): Promise<ICancellableResult<Array<IWorkflowSummary>>> {
        return await ajaxCall.baseAjaxCall<Array<IWorkflowSummary>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'listovertimetovalidate']
        });
    }

    public async getRealizedOverTimeWorkflowsToValidate(userId: string): Promise<ICancellableResult<Array<IWorkflowSummary>>> {
        return await ajaxCall.baseAjaxCall<Array<IWorkflowSummary>>('get', this.url, {
            urlParameter: [userId, 'workflows', 'listrealizedovertimetovalidate']
        });
    }
}

export const workflowApi: WorkflowApi = new WorkflowApi();
