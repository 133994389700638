import { IRealizedOverTimeValidate, IRealizedOverTimeWorkflow } from '@/entity/rh/workflow';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';

class RealizedOverTimeApi extends ApiBase<IRealizedOverTimeWorkflow> {
    constructor(urlBase: string = 'api/users') {
        super(urlBase);
    }

    public async updateRealizedOverTimeWorkflow(workflow: IRealizedOverTimeWorkflow, userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number, IRealizedOverTimeWorkflow>('patch', this.url, {
            payload: workflow,
            urlParameter: [userId, 'workflowsrealizedovertime', workflow.id + '']
        });
    }

    public async validateRealizedOverTimeWorkflow(realizedOverTimeValidate: IRealizedOverTimeValidate, userId: string): Promise<ICancellableResult<number>> {
        return await ajaxCall.baseAjaxCall<number, IRealizedOverTimeValidate>('post', this.url, {
            payload: realizedOverTimeValidate,
            urlParameter: [userId, 'workflowsrealizedovertime', 'validate']
        });
    }
}

export const realizedOverTimeApi: RealizedOverTimeApi = new RealizedOverTimeApi();
