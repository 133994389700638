
import { ICalendarHalfDay } from '@/entity/shared/calendar-day-picker';
import { vxm } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CalendarDayPickerData extends Vue {
    @Prop({ required: true }) dayNumber!: number;
    @Prop({ required: true }) isInMonth!: boolean;
    @Prop({ required: true }) isWeekEnd!: boolean;
    @Prop({ required: false, default: true }) canBeSelected!: boolean;
    @Prop({ required: false, default: false }) isReadonly!: boolean;
    @Prop({ default: false }) defaultMorningSelected!: boolean;
    @Prop({ default: false }) defaultAfternoonSelected!: boolean;
    @Prop({}) month!: number;
    @Prop({}) year!: number;
    @Prop({ default: false }) onlyWeekDay!: boolean;

    private isMorningSelected: boolean = false;
    private isAfternoonSelected: boolean = false;
    private isHovered: boolean = false;

    private created(): void {
        this.isMorningSelected = this.defaultMorningSelected;
        this.isAfternoonSelected = this.defaultAfternoonSelected;
    }

    get dayPartTypeMorning(): number {
        if (vxm.referential.dayPartTypes && vxm.referential.dayPartTypes.length > 0) {
            return vxm.referential.dayPartTypes.filter((x) => x.code === 'MOR')[0].id;
        }
        return -1;
    }

    get dayPartTypeAfternoon(): number {
        if (vxm.referential.dayPartTypes && vxm.referential.dayPartTypes.length > 0) {
            return vxm.referential.dayPartTypes.filter((x) => x.code === 'AFT')[0].id;
        }
        return -1;
    }

    private selectDay(): void {
        if (this.isInMonth === true) {
            const newVal = !(this.isMorningSelected === true || this.isAfternoonSelected === true);
            this.$emit('selectDay', {
                year: this.year,
                day: this.dayNumber,
                month: this.month,
                isSelected: newVal,
                dayPartId: this.dayPartTypeMorning
            } as ICalendarHalfDay);
            this.isMorningSelected = newVal;
            this.$emit('selectDay', {
                year: this.year,
                day: this.dayNumber,
                month: this.month,
                isSelected: newVal,
                dayPartId: this.dayPartTypeAfternoon
            } as ICalendarHalfDay);
            this.isAfternoonSelected = newVal;
        }
    }

    private selectHalfDay(halfDayId: number): void {
        if (this.isInMonth === true) {
            const isMorning = halfDayId === this.dayPartTypeMorning;

            if (isMorning === true) {
                this.isMorningSelected = !this.isMorningSelected;
            } else {
                this.isAfternoonSelected = !this.isAfternoonSelected;
            }
            this.$emit('selectDay', {
                year: this.year,
                day: this.dayNumber,
                month: this.month,
                isSelected: isMorning ? this.isMorningSelected : this.isAfternoonSelected,
                dayPartId: halfDayId
            } as ICalendarHalfDay);
        }
    }
}
