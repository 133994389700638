
import { IWorkflowSummary } from '@/entity/rh/workflow';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UserOverTime extends Vue {
    @Prop({ required: true }) workflowOverTimeList!: IWorkflowSummary[];
    @Prop({ required: true }) userId!: string;
    @Prop({ required: true }) isCurrentUserInformations!: boolean;

    isVisible: boolean = false;

    private createOverTime() {
        this.$router.push({ name: 'over-time-information', params: { id: this.userId, overTimeId: '0' } });
    }

    private consultOverTime(workflowId: number) {
        this.$router.push({ name: 'over-time-information', params: { id: this.userId, overTimeId: workflowId + '' } });
    }

    private transformToStatus(val: number): string {
        switch (val) {
            case -2:
                return 'Annulé';
            case -1:
                return 'Refusé';
            case 0:
                return 'En cours';
            case 1:
                return 'Soumis';
            case 2:
                return 'Validé';
            default:
                return 'inconnu';
        }
    }

    private getFormattedDate(date: Date) {
        return new Date(date).toLocaleDateString('fr-FR');
    }
}
