function subCopy(obj: any): any {
    const newObject: any = { ...obj };

    for (const key in obj) {
        if (obj[key] == null) {
            newObject[key] = null;
        } else if (Array.isArray(obj[key])) {
            const newArray: Array<any> = [];
            obj[key].forEach(element => {
                newArray.push(subCopy(element));
            });

            newObject[key] = newArray;
        } else if (typeof obj[key] === 'object') {
            if (obj.constructor.name === 'Date') {
                newObject[key] = new Date(obj[key]);
            } else {
                newObject[key] = subCopy(obj[key]);
            }
        }
    }

    return newObject;
}

export function deepCopy<T>(obj: T): T {
    return subCopy(obj) as T;
}
