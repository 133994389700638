import { IAgency } from '@/entity/project/agency';
import ApiBase from '@api/api-base';

class AgencyApi extends ApiBase<IAgency> {
    /**
     *
     */
    constructor(urlBase: string = 'api/agencies') {
        super(urlBase);
    }
}

export const agencyApi: AgencyApi = new AgencyApi();
