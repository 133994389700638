
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IClient } from '@/entity/shared/client';
import { IClientAddress } from '@/entity/shared/client-address';
import { IContact } from '@/entity/shared/contact';
import { clientApi } from '@/wapi/client-api';
import { vxm } from '@/store';
import { IReferential } from '@/entity/shared/referential';
import { NU } from '@t/type';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { groupNamingConventionManager } from '@t/group-naming-convention-manager';
import { IGraphRequestParameter } from '@/entity/shared/graph-request-parameter';
import { moduleApiGraph } from '@t/module-api-graph';
import { deepCopy } from '@t/object';
import debounce from 'debounce';

interface IVM<T> {
    uniq: number;
    element: T;
}

@Component({
    components: {
        InputElement
    }
})
export default class ClientInformation extends Vue {
    @Prop({ required: true }) id!: string;
    private code: string = 'CLT';

    @Validations()
    validations = {
        client: {
            label: { required }
        }
    };

    private newContact: IContact = {};
    private newAddress: IClientAddress = {};
    private autoIncrementUniqAddress: number = 1;

    private client: NU<IClient> = {} as IClient;
    private removedContacts: IContact[] = [];
    private autoIncrementUniqContact: number = 1;

    private loading: boolean = false;

    get addresses(): NU<IVM<IClientAddress>[]> {
        if (this.client && this.client.clientAddresses) {
            return this.client.clientAddresses.map(x => {
                return { uniq: this.autoIncrementUniqAddress++, element: x } as IVM<IClientAddress>;
            });
        }
        return [];
    }

    get contacts(): NU<IVM<IContact>[]> {
        if (this.client && this.client.clientContacts) {
            return this.client.clientContacts.map(x => {
                return { uniq: this.autoIncrementUniqContact++, element: x } as IVM<IContact>;
            });
        }
        return [];
    }

    get isReadOnly(): boolean {
        return !vxm.user.isAdmin;
    }

    private groupId: string = '';
    private d: any = {};

    get thirdPartyType(): NU<IReferential[]> {
        return vxm.referential.thirdPartyType;
    }

    get thirdPartyAddressType(): NU<IReferential[]> {
        return vxm.referential.thirdPartyAddressType;
    }

    private getLabelofThirdPartyAddressType(id: number): string {
        if (!this.thirdPartyAddressType || this.thirdPartyAddressType.length === 0 || id === null) {
            return '';
        } else {
            return this.thirdPartyAddressType.filter(x => x.id === id)[0].label;
        }
    }

    private addClientAddressLine(): void {
        this.newAddress = {
            id: undefined,
            tempId: this.autoIncrementUniqAddress++,
            code: '',
            thirdPartyId: Number(this.id),
            thirdPartyAddressTypeId: null,
            label: '',
            postalCode: '',
            city: '',
            country: ''
        };
        (this.$refs['add-client-address-modal'] as any).show();
    }

    private addAddress(): void {
        if (this.newAddress.id === undefined) {
            this.newAddress.id = 0;
        }
        if (this.client && this.client.clientAddresses) {
            const index = this.client.clientAddresses.findIndex(
                x =>
                    ((x.id as number) > 0 && x.id === this.newAddress.id) ||
                    (x.id === 0 && x.tempId === this.newAddress.tempId)
            );
            if (index > -1) {
                this.client.clientAddresses.splice(index, 1, this.newAddress);
            } else {
                this.client.clientAddresses.push(this.newAddress);
            }
        }
        (this.$refs['add-client-address-modal'] as any).hide();
    }

    private deleteClientAddressLine(item: IClientAddress): void {
        const index = this.client?.clientAddresses?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.client?.clientAddresses?.splice(index, 1);
        }
    }

    private deleteClientContact(item: IContact): void {
        const index = this.client?.clientContacts?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.client?.clientContacts?.splice(index, 1);
        }
    }

    private editClientAddressLine(item: IClientAddress): void {
        if (this.client && this.client.clientAddresses) {
            this.newAddress = deepCopy<IClientAddress>(item);
        }
        (this.$refs['add-client-address-modal'] as any).show();
    }

    private editClientContact(item: IContact): void {
        if (this.client && this.client.clientContacts) {
            this.newContact = deepCopy<IContact>(item);
        }
        (this.$refs['add-client-contact-modal'] as any).show();
    }

    private addContactLine(): void {
        this.newContact = { mail: '', givenName: '', surName: '', tempId: this.autoIncrementUniqContact++ };
        (this.$refs['add-client-contact-modal'] as any).show();
    }
    
    private async addContact(): Promise<void> {
        if (this.newAddress.id === undefined) {
            this.newAddress.id = 0;
        }

        if (this.client && this.client.clientContacts) {
            const index = this.client.clientContacts.findIndex(
                x =>
                    ((x.id != null && x.id === this.newContact.id) ||
                    (x.id == null && x.tempId === this.newContact.tempId)
                    ));
            if (index > -1) {
                this.client.clientContacts.splice(index, 1, this.newContact);
            } else {
                this.client.clientContacts.push(this.newContact);
            }
        }

        (this.$refs['add-client-contact-modal'] as any).hide();
    }

    public updateClientDebounced = debounce(this.updateClient, 500);

    async updateClient(): Promise<void> {
        if (this.client) {
            this.loading = true;
            const callData = await clientApi.patchBase(this.client.id + '', this.client);
            if (isCallValidAndNotCancelled(callData)) {
                this.fetchClientData();
                this.$bvToast.toast('Mise à jour du client effectué avec succès', {
                    title: `Client: ${this.client?.label}`,
                    variant: 'success',
                    solid: true
                });
                this.loading = false;
            }
        }
    }

    public createClientDebounced = debounce(this.createClient, 500);

    async createClient(): Promise<void> {
        if (this.client) {
            this.loading = true;
            const callData = await clientApi.createClient(this.client);
            if (isCallValidAndNotCancelled(callData) && callData.datas) {
                this.groupId = (callData.datas as any).groupId;
                await this.$router.push({
                    name: 'client-information',
                    params: { id: (callData.datas as any).thirdPartyId }
                });
                vxm.app.changeTitleMain('Clients');
                this.loading = false;       
                this.client = {} as IClient;
                this.fetchClientData(); 
            }
        }
    }

    async save(): Promise<void> {
        if (this.client?.id === 0) {
            this.loading = true;
            await this.createClientDebounced();
            this.loading = false;
        } else {
            this.loading = true;
            await this.updateClientDebounced();
            this.loading = false;
        }
    }

    async fetchReferencial(): Promise<void> {
        await vxm.referential.fetchThirdPartyType();
        await vxm.referential.fetchThirdPartyAddressType();
    }

    private newClient(): void {
        const clientAddresses: IClientAddress[] = [] as IClientAddress[];
        const clientContacts: IContact[] = [] as IContact[];
        this.client = {
            id: 0,
            code: this.code,
            label: '',
            thirdPartyTypeId: this.thirdPartyType?.filter(x => x.code === 'CLT')[0].id,
            parentId: null,
            directLine: '',
            webSite: '',
            mail: '',
            siret: '',
            comment: '',
            clientAddresses: clientAddresses,
            clientContacts: clientContacts
        } as IClient;
    }

    async fetchClientData(): Promise<void> {
        this.loading = true;
        const clientCallData = await clientApi.getByIdAndCode(this.id, this.code);
        if (isCallValidAndNotCancelled<IClient>(clientCallData)) {
            this.client = clientCallData?.datas;
            vxm.app.changeTitleMain('Clients');
            this.loading = false;
        }
    }

    async mounted(): Promise<void> {
        vxm.user.checkIsAdmin();
        await this.fetchReferencial();
        vxm.app.changeTitleMain('Clients');
        if (this.id === '0') {
            vxm.app.changeTitleExt("Création d'un nouveau client");
            this.newClient();
        } else {
            if (this.code === undefined || this.code === null) {
                this.code = 'CLT';
            }
            await this.fetchClientData();
        }
    }
}
