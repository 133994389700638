
import { Component, Vue } from 'vue-property-decorator';
import { vxm } from '@/store';

@Component
export default class ReferentialBase extends Vue {
    get isReadOnly(): boolean {
        return vxm.user.isAssociate && !vxm.user.isAdmin;
    }

    mounted() {
        vxm.user.checkIsAssociate();
        vxm.user.checkIsAdmin();
    }
}
