
import { IContract } from '@/entity/contract/contract';
import { ICreateContract } from '@/entity/contract/create-contract';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { invoiceApi } from '@/wapi/invoice-api';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BTable } from 'bootstrap-vue';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { IEmployeeAppRoleManager, appTokenMgr } from '@t/employee-app-role';
import frenchLocale from 'date-fns/locale/fr';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IStudio } from '@/entity/project/studio';
import { IProject } from '@/entity/project/project';
import { IContractProject } from '@/entity/contract/contract-project';
import { IInvoiceProject } from '@/entity/invoice/invoice-project';
import { moduleApiGraph } from '@t/module-api-graph';
import { IReferential } from '@/entity/shared/referential';
import { referentialApi } from '@/wapi/referential-api';
import { action, mutation } from 'vuex-class-component';
import { format, isAfter, isBefore, isEqual } from 'date-fns';

@Component({
    components: {
        InputElement
    }
})
export default class InvoicesList extends Vue {
    private filteredInvoices: NU<IInvoiceProject[]> = [];
    private filterData: string = '';
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private invoicesCurrentPage: number = 1;
    private promiseExport: boolean = false;

    private startDate: Date = this.getBaseDate();
    private endDate: Date | null = null;

    private columnPlaceholder: string = 'Nouvelle colonne';
    private selectedFieldToAdd: NU<IBootstrapTableColumn> = null;

    get listOfFilters() {
        return vxm.lists.filters('provider_invoices-list-state');
    }

    @Watch('selectedFieldToAdd')
    public addColumn(column: string, _oldVal: string): void {
        return vxm.lists.addColumn({ listKey: 'provider_invoices-list-state', column });
    }

    private getBaseDate() {
        const current = new Date();

        const d = new Date();
        d.setMonth(9);
        d.setDate(1);
        d.setFullYear(current.getFullYear());

        if (isAfter(d, current)) {
            d.setFullYear(d.getFullYear() - 1);
        }

        return d;
    }
    get fields(): Array<IBootstrapTableColumn | string> {
        return vxm.lists.listFields('provider_invoices-list-state');
    }

    get additionalFieldChoice(): Array<IBootstrapTableColumn> {
        return vxm.lists.additionnalFieldChoice('provider_invoices-list-state');
    }

    public deleteColumn(column: string): void {
        return vxm.lists.removeColumn({ listKey: 'provider_invoices-list-state', column });
    }

    get invoices(): NU<IInvoiceProject[]> {
        const list = vxm.project.allInvoicesList;
        this.filteredInvoices = list?.map((_) => {
            return <IInvoiceProject> {
                id: _.id ?? '',
                studio: _.studio ?? '',
                trigram: _.trigram ?? '',
                supplier: _.supplier ?? '',
                reference: _.reference ?? '',
                purchaseOrderReference: _.purchaseOrderReference ?? '',
                date: _.date ?? '',
                totalExcTax: _.totalExcTax ?? '',
                total: _.total ?? '',
                deadline: _.deadline ?? '',
                receptionDate: _.receptionDate ?? '',
                agency: _.agency ?? '',
                matter: `${_.trigram}_${_.designation}` ?? ''
            };
        });
        return this.filteredInvoices;
    }

    get filteredDataByColumn(): IInvoiceProject[] {
        const keys = Object.keys(this.listOfFilters);
        const filtersApplied = keys.filter((x) => this.listOfFilters[x].value.length > 0);

        if (filtersApplied.length > 0) {
            this.filteredInvoices = this.invoices!.filter((p) =>
                filtersApplied.every((f) => this.filterListByColumn(f, p))
            );
            return this.invoices!
                .filter((iterInvoice) => filtersApplied
                    .every((f) => this.filterListByColumn(f, iterInvoice))
                    && iterInvoice.date
                    && isAfter(new Date(iterInvoice.date), this.startDate) && (!this.endDate || isBefore(new Date(iterInvoice.date), this.endDate!)
                ));
        }

        this.filteredInvoices = this.invoices!.filter((p) =>
            filtersApplied.every((f) => this.filterListByColumn(f, p))
        );
        return this.invoices!.filter(iterInvoice =>  iterInvoice.date
        && isAfter(new Date(iterInvoice.date), this.startDate) && (!this.endDate || isBefore(new Date(iterInvoice.date), this.endDate!)));
    }

    get totalInvoices(): number {
        if (this.filteredInvoices && this.filteredInvoices.length > 0) {
            return this.filteredInvoices.length;
        }
        return 0;
    }

    get isReadOnly(): boolean {
        return !appTokenMgr.isAdmin() || (vxm.project.projectData?.isSale ?? false);
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    get addedAdditionalFields() {
        return vxm.lists.addedAdditionalFields('provider_invoices-list-state');
    }

    private isBusy: boolean = true;

    public filterListByColumn(key: string, x: IInvoiceProject): boolean {
        if (key === 'date') {
            return x.date
                ? new Date(x.date).toLocaleDateString('fr-FR').includes(this.listOfFilters.date.value!)
                : false;
            return false;
        } else if (key === 'trigram') {
            return x.trigram!.toLowerCase().includes(this.listOfFilters.trigram.value.toLowerCase());
        } else if (key === 'studio') {
            return x.studio!.toLowerCase().includes(this.listOfFilters.studio.value.toLowerCase());
        } else if (key === 'supplier') {
            return x.supplier!.toLowerCase().includes(this.listOfFilters.supplier.value.toLowerCase());
        } else if (key === 'reference') {
            return x.reference!.toLowerCase().includes(this.listOfFilters.reference.value.toLowerCase());
        } else if (key === 'purchaseOrderReference') {
            return x
                .purchaseOrderReference!.toLowerCase()
                .includes(this.listOfFilters.purchaseOrderReference.value.toLowerCase());
        } else if (key === 'totalExcTax') {
            return x.totalExcTax!.toString().toLowerCase().includes(this.listOfFilters.totalExcTax.value.toLowerCase());
        } else if (key === 'total') {
            return x.total!.toString().toLowerCase().includes(this.listOfFilters.total.value.toLowerCase());
        } else if (key === 'deadline') {
            return x.deadline
                ? new Date(x.deadline).toLocaleDateString('fr-FR').includes(this.listOfFilters.deadline.value!)
                : false;
        } else if (key === 'receptionDate') {
            return x.receptionDate
                ? new Date(x.receptionDate)
                      .toLocaleDateString('fr-FR')
                      .includes(this.listOfFilters.receptionDate.value!)
                : false;
        } else if (key === 'matter') {
            return x.matter!.toLowerCase().includes(this.listOfFilters.matter.value.toLowerCase());
        } else if (key === 'agency') {
            return x.agency!.toLowerCase().includes(this.listOfFilters.agency.value.toLowerCase());
        } else {
            return false;
        }
    }

    async exportInvoices(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listAdds = this.addedAdditionalFields.map((x) => x.key);
        const listIds = this.filteredDataByColumn.map(x => x.id);
        await this.generateReport(
            invoiceApi.exportInvoicesByDate(
                listIds!,
                this.startDate ? this.startDate!.toISOString() : null,
                this.endDate ? this.endDate!.toISOString() : null,
                listAdds as any,
                sortKeySelected as string,
                sortKeyDescSelected as any
            ),
            `Liste_des_invoices_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Projets');
        vxm.app.changeTitleExt('Liste des factures fournisseur');
        vxm.lists.init('provider_invoices-list-state');
        this.getInvoicesListByDate();
        await vxm.referential.fetchInsurancePercentages();
        this.isBusy = false;
    }

    private async fetchDataByDate(): Promise<void> {
        if (
            this.startDate != undefined &&
            this.endDate != undefined &&
            this.startDate != null &&
            this.endDate != null
        ) {
            this.getInvoicesListByDate();
        }
    }

    private async getInvoicesListByDate(): Promise<void> {
        await vxm.project.fetchListInvoices({
            startDate: this.startDate?.toISOString(),
            endDate: this.endDate?.toISOString()
        });
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        let a, b;
        if (key === 'description') {
            a = itemA[key];
            b = itemB[key];
            if (a === null) a = '';
            if (b === null) b = '';
            return this.strcmp(a.replaceAll('_', '-').toLowerCase(), b.replaceAll('_', '-').toLowerCase());
        }
    }
}
